export function listToTree (list) {
  var map = {}
  var node
  var tree = []
  var i
  for (i = 0; i < list.length; i++) {
    map[list[i].id] = list[i]
    list[i].children = []
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.pid !== '0') {
      map[node.pid].children.push(node)
    } else {
      tree.push(node)
    }
  }
  return [tree, map]
}
export function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}