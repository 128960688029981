<template>
  <div class="container">

    <div class="content">
      <div class="form-wrapper">
        <el-form :inline="true" :model="form" class="demo-form-inline" @submit.native.prevent>
          <el-form-item class="item" label="项目类型：">
            <el-radio-group v-model="form.type" @change="changeConditions">
              <el-radio size="mini" label="" name="type" border>全部</el-radio>
              <el-radio size="mini" label="1" name="type" border>数据采集</el-radio>
              <el-radio size="mini" class="pc-radio" label="2" name="type" border>RPA脚本开发</el-radio>
              <el-radio size="mini" class="mobile-radio" label="2" name="type" border>RPA</el-radio>
              <el-radio size="mini" label="3" name="type" border>研究报告</el-radio>
              <el-radio size="mini" label="4" name="type" border>数据分析</el-radio>
              <el-radio size="mini" label="5" name="type" border>NLP</el-radio>
              <el-radio size="mini" label="6" name="type" border>AI</el-radio>
            </el-radio-group>
          </el-form-item>
          <br>
          <el-form-item class="item" label="任务时长：">
            <el-radio-group v-model="form.isLong" @change="changeConditions">
              <el-radio size="mini" label="" name="time" border>全部</el-radio>
              <el-radio size="mini" label="1" name="time" border>长期</el-radio>
              <el-radio size="mini" label="0" name="time" border>短期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="item status" label="任务状态：">
            <el-radio-group v-model="form.progress" @change="changeConditions">
              <el-radio size="mini" value="" label="" name="mode" border>全部</el-radio>
<!--              <el-radio size="mini" value="1" label="1" name="mode" border>招募中</el-radio>-->
              <el-radio size="mini" value="3" label="3" name="mode" border>进行中</el-radio>
              <el-radio size="mini" value="5" label="5" name="mode" border>已完成</el-radio>
            </el-radio-group>
          </el-form-item>
          <br>
          <el-form-item class="item search">
            <el-input size="mini" v-model="form.keyword" placeholder="请输入您要查询的内容" @change="changeConditions"></el-input>
            <i class="el-icon-search"></i>
          </el-form-item>
        </el-form>
      </div>
      <div v-loading="loading">
        <div v-show="!dataList || dataList.length === 0" style="width: 100%; height: 200px; line-height: 200px; font-size: 20px; text-align: center; color: gray; background-color: #FFFFFF">
          暂无数据
        </div>
        <ul class="project-list" style="min-height: 200px" v-show="dataList.length > 0">
          <li v-for="data in dataList" class="project-item">
            <el-row>
              <el-col :span="20">
                <div class="project-content">
                  <div class="project-title">
                    <span>
                      <span class="name" @click="openTaskDetail(data.id)">{{ data.title }}</span>
                      <span v-if="data.progress === 1" class="state recruit">招募中</span>
                      <span v-if="data.progress === 2" class="state recruit">已招募</span>
                      <span v-if="data.progress === 3 || data.progress === 4" class="state progress">进行中</span>
                      <span v-if="data.progress === 5" class="state isEnd">已完成</span>
                    </span>
                    <span class="publish-state">
                      <span class="publish-date">发布时间：{{ data.createDate }}</span>
                      <span>已有{{ data.recruitCount ? data.recruitCount : 0 }}人投递</span>
                    </span>
                  </div>
                  <div>
                    <span class="price" v-if="data.revenueAmount !== data.paymentAmount">{{ data.revenueAmount }} ~ {{ data.paymentAmount }}元</span>
                    <span class="price" v-if="data.revenueAmount === data.paymentAmount">{{ data.paymentAmount }}元</span>
                    <span class="exprience">
                      <span>周期：{{ data.cycle.replaceAll('年', '/').replaceAll('月', '/').replaceAll('日', '') }}</span>
  <!--                    | <span>经验：3~5年</span>-->
                    </span>
                  </div>
                  <div class="demand">
                    需求概述：{{ data.overview }}
                  </div>
                  <div class="tags">
                    <span v-if="data.isInvoice === 1" class="tag" style="background-color: #7157F6; color: #FFF">需要发票</span>
                    <span v-if="data.isInvoice === 0" class="tag" style="color: #414141">不需要发票</span>
                  </div>
                </div>
              </el-col>

              <el-col :span="4">
                <div class="company">
                  <div class="company-logo">
                    <img v-if="!data.headUrl" src="@/assets/images/company.png" >
                    <img v-if="data.headUrl" :src="data.headUrl" >
                  </div>
                  <div class="company-name">{{ data.userName ? data.userName : data.companyName }}</div>
                  <div class="renzheng">
                    <span v-if="data.personAuth === 1 || (data.companyAuth === 1 && data.role === 'company')">已认证</span>
                    <img v-if="data.companyAuth === 1 && data.role === 'company'" src="@/assets/images/vips.png" >
                  </div>
                </div>
              </el-col>
            </el-row>
          </li>
        </ul>
        <ul class="mobile-list" v-loading="loading" style="min-height: 200px" v-show="dataList.length > 0">
          <li v-for="data in dataList" class="project-item">
                <!-- <div class="company">
                  <div class="company-logo">
                    <img v-if="!data.headUrl" src="@/assets/images/company.png" >
                    <img v-if="data.headUrl" :src="data.headUrl" >
                  </div>
                  <div class="companyMsg">
                    <div class="company-name">{{ data.userName ? data.userName : data.companyName }}</div>
                    <div class="renzheng">
                      <span v-if="data.personAuth === 1 || (data.companyAuth === 1 && data.role === 'company')">已认证</span>
                      <img v-if="data.companyAuth === 1 && data.role === 'company'" src="@/assets/images/vips.png" >
                    </div>
                  </div>
                </div> -->
                <div class="project-content">
                  <div class="project-title">
                    <span>
                      <span class="name" @click="openTaskDetail(data.id)">{{ data.title }}</span>
                      <span v-if="data.progress === 1" class="state recruit">招募中</span>
                      <span v-if="data.progress === 2" class="state recruit">已招募</span>
                      <span v-if="data.progress === 3 || data.progress === 4" class="state progress">进行中</span>
                      <span v-if="data.progress === 5" class="state isEnd">已完成</span>
                    </span>
                    <span class="publish-state">
                      <span class="publish-date">发布时间：{{ data.createDate }}</span>
                      <span>已有{{ data.recruitCount ? data.recruitCount : 0 }}人投递</span>
                    </span>
                  </div>
                  <div>
                    <span class="price" v-if="data.revenueAmount !== data.paymentAmount">{{ data.revenueAmount }} ~ {{ data.paymentAmount }}元</span>
                    <span class="price" v-if="data.revenueAmount === data.paymentAmount">{{ data.paymentAmount }}元</span>
                    <span class="exprience">
                      <span>周期：{{ data.cycle.replaceAll('年', '/').replaceAll('月', '/').replaceAll('日', '') }}</span>
  <!--                    | <span>经验：3~5年</span>-->
                    </span>
                  </div>

                </div>
                <div class="demand">
                  需求概述：{{ data.overview }}
                </div>
                <div class="tags">
                  <span v-if="data.isInvoice === 1" class="tag" style="background-color: #7157F6; color: #FFF">需要发票</span>
                  <span v-if="data.isInvoice === 0" class="tag" style="color: #414141">不需要发票</span>
                </div>
          </li>
        </ul>
      </div>
      <div style="background-color: #FFFFFF; margin-top: 15px;padding: 20px 0px">
        <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      </div>
      <div class="banner">
        <img src="@/assets/images/bg.png" alt="">
        <div class="desc-text">
          <div class="title">
            <img src="../../assets/images/line.png" >
            <span>赚钱小手册</span>
            <img src="../../assets/images/line.png" >
          </div>
          <div class="text">
            <span>项目选52</span>
            <span>轻松赚大钱</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchTask} from "@/api/workBench";
import {apiBaseURL} from "@/utils/request";
export default {
  data () {
    return {
      loading: false,
      form: {
        type: '',
        progress: '',
        isLong: '',
        keyword: '',
        orderName: 0,
        orderType: 0,
        currentPage: 1
      },
      dataList: [],
      total: 0
    }
  },
  methods: {
    async fetchTask () {
      this.loading = true
      const res = await fetchTask(this.form)
      if (res.code === 0) {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].headUrl = res.data[i].headUrl ? apiBaseURL + res.data[i].headUrl : apiBaseURL + '/avator/company.png'
        }
        this.dataList = res.data
        this.total = res.count
      } else {
        this.$message.error('数据获取失败')
      }
      this.loading = false
    },
    openTaskDetail (id) {
      this.$router.push('/workBench/detail/' + id)
    },
    changeOrder (name, type) {
      this.form.orderName = name
      this.form.orderType = type
      // 初始化分页信息
      this.form.currentPage = 1
      this.fetchTask()
    },
    handleCurrentChange (val) {
      this.form.currentPage = val
      this.fetchTask()
    },
    changeConditions () {
      this.form.currentPage = 1
      this.fetchTask()
    }
  },
  mounted () {
    this.fetchTask()
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-pager li.active,/deep/.el-pager li:hover,
/deep/.el-pagination button:hover{
  color: #a068ff;
}
/deep/.el-pagination .btn-prev,
/deep/.el-pagination .btn-next,
/deep/.el-pager li{
  background-color: transparent;
  color: #6d6d6d;
}
/deep/.el-pagination{
  text-align: right;
}
/deep/.el-form-item__content,/deep/.el-form--inline .el-form-item__content{
	@media screen and (max-width: 530px) {
	  line-height: unset;
	  vertical-align: bottom;
	}
}
/deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
	@media screen and (max-width: 530px) {
	  font-size: 16px;
	  padding-right: 0;
    vertical-align: top;
	}
}

.content{
  width: 80%;
  margin: 20px auto;
  @media screen and (max-width: 530px) {
    width: 100%;
    margin-top: 80px;
  }
  @media screen and (min-width: 945px) and (max-width: 1240px) {
    width: 90%;
  }
  @media screen and (min-width: 850px) and (max-width: 945px){
    width: 100%;
  }
  @media screen and (max-width: 849px){
    width: 100%;
  }
  .form-wrapper{
    background-color: #fff;
    border-radius: 3px;
    padding:20px 30px;
    height: 150px;
	@media screen and (max-width: 530px) {
	  padding: 15px;
    height: auto;
	}
	.pc-radio{
	  @media screen and (max-width: 530px) {
	    display:none;
	  }
	}
	.mobile-radio{
	  display:none;
	  @media screen and (max-width: 530px) {
	    display:inline-block;
	  }
	}
    /deep/.el-radio__input {
      display: none;
    }
    .item:first-child /deep/.el-form-item__content{
      @media screen and (max-width: 530px) {
        width: calc(100% - 85px);
      }
    }
    .search{
       display: block;
      /deep/.el-form-item__content{
        width: 100%;
        max-width: 420px;
      }
    }

    /* .left {
      float: left;
    }
    .right {
      text-align: right;
    } */
    .el-radio.is-bordered, .el-input__inner{
      border-radius: 40px;
    }
    .el-form-item{
      margin: 0;
      &.status{
        float: right;
        margin-left: 30px;
        @media screen and (max-width: 530px) {
          margin: 0;
          float: none;
        }
      }
      &.search{
        width: 420px;
        float: right;
        @media screen and (max-width: 530px) {
          margin-top: 10px;
          width: 100%;
          float: none;
        }
      }
      &.search .el-form-item__content{
        width: 100%;
        position: relative;
        .el-icon-search{
          color: #aaa;
          font-size: 16px;
          position: absolute;
          top: 14px;
          right: 15px;
          @media screen and (max-width: 530px) {
            top: 7px;
          }
        }
      }
    }
    /* .el-form-item:nth-child(odd){
      width:70%;
    }
    .el-form-item:nth-child(even){
      width:30%;
    } */
  }
  .sort-wrapper{
    background-color: #fff;
    border-radius: 3px;
    font-size: 16px;
    padding:20px 30px;
    margin-top: 15px;
    .filter-item{
      display: inline-block;
      margin: 0 20px;
	  @media screen and (max-width: 530px) {
	    margin: 0;
	    margin-right: 15px;
	  }
      span{
        margin-right: 10px;
      }
      .icons{
        display: inline-block;
        vertical-align: bottom;
        .iconfont{
          display:block;
          width:20px;
          height: 10px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

  }
  .project-list{
    padding: 0;
    margin: 0;
    @media screen and (max-width: 530px) {
      display: none;
    }
    .project-item{
      background-color: #fff;
      border-radius: 3px;
      font-size: 16px;
      padding: 30px 30px 20px;
      margin-top: 15px;
      .project-content{
        padding-right: 20px;
        div{
          margin-bottom: 20px;
        }
        .project-title{
          .name{
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
          }
          .state{
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 3px;
            margin-left: 15px;
            &.recruit{//招募
              border: 1px solid #6293f7;
              color: #6293f7;
            }
            &.progress{//进行
              border: 1px solid #7B62F7;
              color: #7B62F7;
            }
            &.isEnd{//已完成
              border: 1px solid #f76262;
              color: #f76262;
            }
          }
          .publish-state{
            float: right;
            font-size: 14px;
            margin-top: 5px;
            color: #8d8d8d;
            .publish-date{
              margin-right: 30px;
            }
          }
        }
        .price{
          color: #7B62F7;
          font-size: 18px;
        }
        .exprience{
          font-size: 14px;
          margin-left: 30px;
          color: #8d8d8d;
          span{
            margin: 0 10px;
          }
        }
        .demand{
          overflow:hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .tags .tag{
          background-color: #eaeaec;
          font-size: 14px;
          padding: 4px 12px;
          border-radius: 20px;
          margin-right: 10px;
        }
      }
      .company{
        border-left: 1px solid #dcdcdc;
        text-align: center;
        div{
          margin: 0 auto 12px;
        }
        .company-logo{
          width: 100px;
          border-radius: 50%;
          img{
            width: 100%;
            border-radius: 50%;
          }
        }
        .renzheng{
          span{
            font-size: 12px;
            padding: 2px 5px;
            border: 1px solid #7B62F7;
            color: #7B62F7;
            border-radius: 3px;
          }
          img{
            width: 16px;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .mobile-list{
    padding: 0;
    margin: 0;
    display: none;
    @media screen and (max-width: 530px) {
      display: block;
    }
    .project-item{
      background-color: #fff;
      border-radius: 3px;
      font-size: 16px;
      padding: 30px 30px 20px;
      margin-top: 15px;
      .project-content{
        padding-right: 20px;
        div{
          margin-bottom: 20px;
        }
        .project-title{
          .name{
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
          }
          .state{
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 3px;
            margin-left: 15px;
            &.recruit{//招募
              border: 1px solid #6293f7;
              color: #6293f7;
            }
            &.progress{//进行
              border: 1px solid #7B62F7;
              color: #7B62F7;
            }
            &.isEnd{//已完成
              border: 1px solid #f76262;
              color: #f76262;
            }
          }
          .publish-state{
            float: right;
            font-size: 14px;
            margin-top: 5px;
            color: #8d8d8d;
            @media screen and (max-width: 720px) {
              display: none;
            }
            .publish-date{
              margin-right: 30px;
            }
          }
        }
        .price{
          color: #7B62F7;
          font-size: 18px;
        }
        .exprience{
          font-size: 14px;
          margin-left: 30px;
          color: #8d8d8d;
          @media screen and (max-width: 720px) {
            display: block;
            margin: 10px 0;
            font-size: 16px;
          }
          /* span{
            margin: 0 10px;
          } */
        }

      }
      .demand{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.68;
      }
      .tags .tag{
        display: inline-block;
        background-color: #eaeaec;
        font-size: 14px;
        padding: 4px 12px;
        border-radius: 20px;
        margin-right: 10px;
        margin-top: 10px;
      }
      .company{

        div{
          margin: 0 auto 12px;
        }
        .company-logo{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .renzheng{
          span{
            font-size: 12px;
            padding: 2px 5px;
            border: 1px solid #7B62F7;
            color: #7B62F7;
            border-radius: 3px;
          }
          img{
            width: 16px;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .banner{
    margin: 15px 0;
    position: relative;
    @media screen and (max-width: 530px) {
      display: none;
    }
    img{
      width: 100%;
      height: 220px;
    }
    .desc-text{
      position: absolute;
      margin-top: -50px;
      top: 50%;
      left: 150px;
      text-align: center;
      color: #fff;
      .title{
        font-size: 16px;
        letter-spacing: 3px;
        margin: 10px 0 40px;
        img{
          width: 40px;
          height: auto;
          margin: 0 10px;
          vertical-align: middle;
          &:last-child{
            transform: rotate(-180deg);
          }
        }

      }
      .text span{
        font-size: 28px;
        font-weight: 700;
        margin: 0 10px;
        letter-spacing: 1px;
      }
    }
  }
}

</style>
