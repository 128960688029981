<template>
  <div class="container">
    <!-- <div class="swiper">
      <img src="../../assets/images/bg.png" >
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu">招募人才</div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="form-wrapper">
        <el-form :inline="true" :model="form" class="demo-form-inline" @submit.native.prevent>
          <el-form-item class="item" label="项目类型：">
            <el-radio-group v-model="form.type" @change="changeConditions">
              <el-radio size="mini" label="" name="type" border>全部</el-radio>
              <el-radio size="mini" label="1" name="type" border>数据采集</el-radio>
              <el-radio size="mini" label="2" name="type" border>RPA脚本开发</el-radio>
              <el-radio size="mini" label="3" name="type" border>研究报告</el-radio>
              <el-radio size="mini" label="4" name="type" border>数据分析</el-radio>
              <el-radio size="mini" label="5" name="type" border>NLP</el-radio>
              <el-radio size="mini" label="6" name="type" border>AI</el-radio>
            </el-radio-group>
          </el-form-item>
         <br>
          <el-form-item class="item" label="任务时长：">
            <el-radio-group v-model="form.isLong" @change="changeConditions">
              <el-radio size="mini" label="" name="time" border>全部</el-radio>
              <el-radio size="mini" label="1" name="time" border>长期</el-radio>
              <el-radio size="mini" label="0" name="time" border>短期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="item status" label="任务状态：">
            <el-radio-group v-model="form.progress" @change="changeConditions">
              <el-radio size="mini" value="" label="" name="mode" border>全部</el-radio>
              <el-radio size="mini" value="1" label="1" name="mode" border>招募中</el-radio>
              <el-radio size="mini" value="3" label="3" name="mode" border>进行中</el-radio>
              <el-radio size="mini" value="5" label="5" name="mode" border>已完成</el-radio>
            </el-radio-group>
          </el-form-item>
          <br>
          <el-form-item class="item search">
            <el-input size="mini" v-model="form.keyword" placeholder="请输入您要查询的内容" @change="changeConditions"></el-input>
            <i class="el-icon-search"></i>
          </el-form-item>
        </el-form>
      </div>

      <ul class="project-list" v-loading="loading" style="min-height: 200px">
        <li v-show="!dataList || dataList.length === 0" style="width: 100%; height: 200px; line-height: 200px; font-size: 20px; text-align: center; color: gray; background-color: #FFFFFF">
          暂无数据
        </li>
        <li v-show="dataList.length > 0" v-for="data in dataList" class="project-item">
          <div class="project-content">
            <div class="project-title">
              <span>
                <span class="name" style="cursor: pointer" @click="openTaskDetail(data)">{{ data.title }}</span>
                <span v-if="data.progress === 0 && data.state !== 2" class="state wait">待审核</span>
                <span v-else-if="data.progress === 1" class="state recruit">招募中</span>
                <span v-else-if="data.progress === 5" class="state isEnd">已完成</span>
                <span v-else-if="data.state === 2" class="state isEnd">已拒绝</span>
                <span v-else class="state progress">进行中</span>
              </span>
              <span class="publish-state">
                <span class="publish-date">发布时间：{{ data.createDate }}</span>
                <span>已有{{ data.recruitCount ? data.recruitCount : 0 }}人投递</span>
              </span>
            </div>
            <div>
              <span class="price" v-if="data.revenueAmount !== data.paymentAmount">{{ data.revenueAmount }} ~ {{ data.paymentAmount }}元</span>
              <span class="price" v-if="data.revenueAmount === data.paymentAmount">{{ data.paymentAmount }}元</span>
              <span class="exprience">
                <span>周期：{{ data.cycle }}</span>
<!--                    | <span>经验：3~5年</span>-->
              </span>
            </div>
            <div class="demand">
              需求概述：{{ data.overview }}
            </div>
            <div class="tags">
              <span v-if="data.isInvoice === 1" class="tag" style="background-color: #7157F6; color: #FFF">需要发票</span>
              <span v-if="data.isInvoice === 0" class="tag" style="color: #414141">不需要发票</span>
            </div>
          </div>

        </li>
      </ul>
      <div style="background-color: #FFFFFF; margin-top: 15px;padding: 20px 0px">
        <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchMyTask} from "@/api/task/task";
import {apiBaseURL} from "@/utils/request";
export default {
  data () {
    return {
      loading: false,
      form: {
        type: '',
        progress: '',
        isLong: '',
        keyword: '',
        orderName: 0,
        orderType: 0,
        currentPage: 1
      },
      dataList: [],
      total: 0
    }
  },
  methods: {
    async fetchTask () {
      this.loading = true
      const res = await fetchMyTask(this.form)
      if (res.code === 0) {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].headUrl = res.data[i].headUrl ? apiBaseURL + res.data[i].headUrl : apiBaseURL + '/avator/company.png'
        }
        this.dataList = res.data
        this.total = res.count
      } else {
        this.$message.error('数据获取失败')
      }
      this.loading = false
    },
    openTaskDetail (data) {
      if (data.progress === 0 && data.state === 0) {
        this.$message.warning('该任务暂未审核，请耐心等待哦！')
        return
      }
      if (data.progress === 0 && data.state === 2) {
        this.$message.error('该任务未通过审核！')
        return
      }
      this.$router.push('/detail/' + data.id)
    },
    changeOrder (name, type) {
      this.form.orderName = name
      this.form.orderType = type
      // 初始化分页信息
      this.form.currentPage = 1
      this.fetchTask()
    },
    handleCurrentChange (val) {
      this.form.currentPage = val
      this.fetchTask()
    },
    changeConditions () {
      this.form.currentPage = 1
      this.fetchTask()
    }
  },
  mounted () {
    this.fetchTask()
  }
}
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    text-align: right;
  }
  /deep/.el-form-item__content,/deep/.el-form--inline .el-form-item__content{
  	@media screen and (max-width: 530px) {
  	  line-height: unset;
  	  vertical-align: bottom;
  	}
  }
  /deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
  	@media screen and (max-width: 530px) {
  	  font-size: 16px;
  	  padding-right: 5px;
      vertical-align: top;
  	}
  }

  .content{
    width: 80%;
    margin: 20px auto;
   @media screen and (max-width: 530px) {
     width: 100%;
     margin-top: 80px;
   }
   @media screen and (min-width: 945px) and (max-width: 1240px) {
     width: 90%;
   }
   @media screen and (min-width: 850px) and (max-width: 945px){
     width: 100%;
   }
   @media screen and (max-width: 849px){
     width: 100%;
   }
   .form-wrapper{
     background-color: #fff;
     border-radius: 3px;
     padding:20px 30px;
     height: 150px;
     @media screen and (max-width: 530px) {
       padding: 15px;
       height: auto;
     }
     /deep/.el-radio__input {
       display: none;
     }
    .item:first-child /deep/.el-form-item__content{
      @media screen and (max-width: 530px) {
        width: calc(100% - 85px);
      }
    }
     .search{
        display: block;
       /deep/.el-form-item__content{
         width: 100%;
         max-width: 420px;
       }
     }
      /* .left {
        float: left;
      }
      .right {
        text-align: right;
      } */
      .el-radio.is-bordered, .el-input__inner{
        border-radius: 40px;
      }
      .el-form-item{
        margin: 0;
        &.status{
          margin-left: 30px;
          float: right;
          @media screen and (max-width: 530px) {
            margin: 0;
            float: none;
          }
        }
        &.search{
          width: 420px;
          float: right;
          @media screen and (max-width: 530px) {
            margin-top: 10px;
            float: none;
            width: 100%;
          }
        }
        &.search .el-form-item__content{
          width: 100%;
          position: relative;
          .el-icon-search{
            color: #aaa;
            font-size: 16px;
            position: absolute;
            top: 14px;
            right: 15px;
            @media screen and (max-width: 530px) {
              top: 7px;
            }
          }
        }
      }
      /* .el-form-item:nth-child(odd){
        width:70%;
      }
      .el-form-item:nth-child(even){
        width:30%;
      } */
    }

    .project-list{
      padding: 0;
      margin: 15px 0;
      .project-item{
        background-color: #fff;
        border-radius: 3px;
        font-size: 16px;
        padding: 30px 30px 20px;
        margin-top: 15px;
        .project-content{
          // padding-right: 20px;
          div{
            margin-bottom: 20px;
          }
          .project-title{
            .name{
              font-size: 20px;
              font-weight: 700;
              @media screen and (max-width: 530px) {
                display: inline-block;
                width: calc(100% - 80px);
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                vertical-align: super;
              }
            }
            .state{
              font-size: 12px;
              padding: 2px 5px;
              border-radius: 3px;
              margin-left: 15px;
              &.wait{//待审核
                border: 1px solid gray;
                color: gray;
              }
              &.recruit{//招募
                border: 1px solid #6293f7;
                color: #6293f7;
              }
              &.progress{//进行
                border: 1px solid #7B62F7;
                color: #7B62F7;
              }
              &.isEnd{//已完成
                border: 1px solid #f76262;
                color: #f76262;
              }
            }
            .publish-state{
              float: right;
              font-size: 14px;
              margin-top: 5px;
              color: #8d8d8d;
              @media screen and (max-width: 720px) {
                display: none;
              }
              .publish-date{
                margin-right: 100px;
              }
            }
          }
          .price{
            color: #7B62F7;
            font-size: 18px;
          }
          .exprience{
            font-size: 14px;
            margin-left: 30px;
            color: #8d8d8d;
            @media screen and (max-width: 720px) {
              display: block;
              margin: 10px 0;
              font-size: 16px;
            }
          }
          .demand{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.68;
            @media screen and (max-width: 530px) {
              padding-bottom: 15px;
              border-bottom: 1px solid #dcdcdc;
            }
          }
          .tags .tag{
            background-color: #eaeaec;
            font-size: 14px;
            padding: 4px 12px;
            border-radius: 20px;
            margin-right: 10px;
          }
        }

      }
    }
  }

</style>
