<template>
  <div class="container">
    <div class="title">企业信息</div>
    <el-button type="text" class="edit" @click="isEdit = false"><i class="el-icon-edit-outline"></i>编辑</el-button>
    <div class="form-wrapper">
      <div class="avater">
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false">
          <img v-if="form.headUrl" :src="form.headUrl" class="avatar-img">
          <img v-else src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" class="avatar-img">
        </el-upload>
        <div class="tip" v-show="!isEdit"><span class="required">*</span>图片大小180 * 180像素，支持JPG、PNG等格式，图片需小于2MB</div>
      </div>
      <el-form ref="form" :model="form" label-width="85px">
        <el-form-item label="企业名称:">
          <el-input v-model="form.name" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="联系人:">
          <el-input v-model="form.name" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="联系方式:">
          <el-input v-model="form.name" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:">
          <el-input v-model="form.name" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        form: {},
        isEdit: true
      }
    },
    mounted() {

    },
    methods: {
      onSubmit(){

      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    position: relative;
    @media screen and (max-width: 530px) {
      min-height: calc(100vh - 120px);
    }
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .el-input.el-input--mini{
      width: 220px;
    }
    
    .edit{
      color: #795FF6;
      position: absolute;
      right: 80px;
      top: 20px;
      &:hover{
        opacity: .78;
      }
      .el-icon-edit-outline{
        font-size: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .avater{
      width: 150px;
      position: absolute;
      left: 450px;
      text-align: center;
      @media screen and (max-width: 530px) {
        width: 100%;
        position: unset;
      }
      img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 auto 10px;
      }
      .tip{
        font-size: 12px;
        color: #bfbfbf;
        @media screen and (max-width: 530px) {
          width: calc(100% - 110px);
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
        .required{
          font-size: 14px;
          color: #F56C6C;
          margin-right: 4px;
        }
      }
    }
  }
</style>
