<template>
  <div class="container">
    <div class="title">兑换记录</div>
    <el-table :data="recordList" style="width: 100%" v-loading="loading">
      <el-table-column prop="price" label="积分变化" width="80" align="center">
        <template slot-scope="{row}">
          <span>-{{row.price*row.quantity}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" width="200" label="商品" align="center"></el-table-column>
      <el-table-column prop="name" label="收货人" width="100"></el-table-column>
      <el-table-column prop="quantity" label="数量" width="60" align="center"></el-table-column>
      <el-table-column prop="singleNumber" label="快递单号"  align="center"></el-table-column>
      <el-table-column label="状态" width="120" align="center">
        <template slot-scope="{row}">
          <span>{{row.state==0?'处理中':row.state==1?"已发货":'已完成'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="current" prev-text="上一页" next-text="下一页"></el-pagination>
  </div>
</template>

<script>
  import { getExchangeRecords } from '@/api/integral/integral'
  export default {
    data () {
      return {
        loading: false,
        recordList:[],
        current: 1,
        pagesize:10,
        total: 0
      }
    },
    mounted() {
      this.getRecord()
    },
    methods: {
      async getRecord () {
        this.loading = true
        const res = await getExchangeRecords({currentPage:this.current,pageSize:this.pagesize})
        if (res.code === 0) {
          this.recordList = res.data
          this.total = res.count
        }else{
          this.$message.error(res.msg)
        }
        this.loading = false
      },
      handleCurrentChange (val) {
        this.current = val
        this.getRecord()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    margin-top: 20px;
    text-align: center;
  }
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
  .operate-btn{
    color:#795FF6;
    background-color: #f9f8ff;
    border-radius: 3px;
    padding: 5px 10px;
  }
</style>
