import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// import './utils/mobilefont.js'
import 'lib-flexible'
import axios from 'axios'

Vue.prototype.$http = axios
// style
import 'normalize.css'
import '@/styles/index.scss'
import '@/styles/iconfont.css'

import 'font-awesome/css/font-awesome.min.css'

import Distpicker from 'v-distpicker'
Vue.component('v-distpicker', Distpicker)
// echarts 图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
require('echarts-wordcloud')

import visibility from 'vue-visibility-change';
Vue.use(visibility);


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
