<template>
  <div class="container">

    <div class="content">
      <ul class="anli-list" v-loading="loading" style="min-height: 200px">
      	<li v-show="dataList.length > 0" class="anli-item" v-for="item in dataList" @click="openDetail(item.id)">
          <div class="personnel">
            <div class="person-img">
              <img style="cursor: pointer" :src="item.clientLogo">
            </div>
            <div class="anli-content">
              <div class="anli-title">
                <div class="name" style="cursor: pointer">{{ item.title }}</div>
                <div class="price" style="cursor: pointer" >{{ item.price }}元</div>
              </div>
              <div class="times">
                <div>周期：{{item.projectCycle}}</div>
                <div class="date">交付时间：{{item.deliveryDate}}</div>
              </div>
              <div class="nums">
                <div class="clickNum">
                  <i class="iconfont icon-dianzan"></i>
                  <span class="num">{{item.like}}</span>
                </div>
                <div class="lookUp">
                  <i class="iconfont icon-chakan"></i>
                  <span class="num">{{item.read}}</span>
                </div>
              </div>
             </div>

          </div>

        </li>
      </ul>
      <div class="list-wrap" v-if="dataList.length > 0">
        <div class="anli mobile" v-for="item in dataList" @click="openDetail(item.id)">
          <div class="personnel">
            <div class="person-img">
              <img style="cursor: pointer" :src="item.clientLogo">
            </div>
            <div class="anli-content">
              <div class="anli-title">
                <div class="name" style="cursor: pointer">{{ item.title }}</div>
              </div>
              <div class="times">
                <div>周期：{{item.projectCycle}}</div>
                <div class="date">交付时间：{{item.deliveryDate}}</div>
              </div>
              <div>金额：<span class="price">{{ item.price }}元</span></div>
              <div class="nums">
                <div class="clickNum">
                  <i class="iconfont icon-dianzan"></i>
                  <span class="num">{{item.like}}</span>
                </div>
                <div class="lookUp">
                  <i class="iconfont icon-chakan"></i>
                  <span class="num">{{item.read}}</span>
                </div>
              </div>
             </div>

          </div>

        </div>
      </div>
      <div class="nodata" v-else style="width: 100%; height: 200px; line-height: 200px; font-size: 18px; text-align: center; color: gray; background-color: #FFFFFF">暂无数据</div>
      <div style="background-color: #FFFFFF; margin-top: 5px;padding: 20px 0px">
        <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getCase} from "@/api/anli";
import {apiBaseURL} from "@/utils/request";
  export default{
    data(){
      return {
        form:{
          currentPage: 1,
          pageSize:10
        },
        dataList: [],
        loading: false,
        total: 0
      }
    },
    methods: {
      async initList () {
        this.loading = true
        this.dataList = []
        const res = await getCase(this.form)
        if (res.code === 0) {
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].clientLogo = res.data[i].clientLogo ? apiBaseURL + res.data[i].clientLogo : apiBaseURL + '/avator/company.png'
          }
          this.dataList = res.data
          this.total = res.count
        } else {
          this.$message.error('数据获取异常')
        }
        this.loading = false
      },
      changeConditions () {
        this.form.currentPage = 1
        this.initList()
      },
      changeOrder (name, type) {
        this.form.orderName = name
        this.form.orderType = type
        // 初始化分页信息
        this.form.currentPage = 1
        this.initList()
      },
      handleCurrentChange (val) {
        this.form.currentPage = val
        this.initList()
      },
      openDetail (id) {
        this.$router.push('/anli/detail/' + id)
      }
    },
    mounted() {
      this.initList()
    }
  }
</script>

<style lang="scss" scoped>
/deep/.el-pager li.active,/deep/.el-pager li:hover,
/deep/.el-pagination button:hover{
  color: #a068ff;
}
/deep/.el-pagination .btn-prev,
/deep/.el-pagination .btn-next,
/deep/.el-pager li{
  background-color: transparent;
  color: #6d6d6d;
}
/deep/.el-pagination{
  text-align: right;
}
/deep/.el-form-item__content,/deep/.el-form--inline .el-form-item__content{
	@media screen and (max-width: 530px) {
	  line-height: unset;
	  vertical-align: bottom;
	}
}
/deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
	@media screen and (max-width: 530px) {
	  font-size: 16px;
	  padding-right: 0;
    vertical-align: top;
	}
}

  .content{
    width: 80%;
    margin: 20px auto;
    @media screen and (max-width: 530px) {
      width: 100%;
      margin-top: 80px;
    }
    @media screen and (min-width: 945px) and (max-width: 1240px) {
      width: 90%;
    }
    @media screen and (min-width: 850px) and (max-width: 945px){
      width: 100%;
    }
    @media screen and (max-width: 849px){
      width: 100%;
    }

    .anli-list{
      padding: 0;
      margin: 0;
      @media screen and (max-width: 530px) {
        display: none;
      }
      .anli-item{
        background-color: #fff;
        font-size: 16px;
        margin-top: 15px;
        width: calc(35% - 10px);
        display: inline-block;
        margin: 10px;
        cursor: pointer;
        /* @media screen and (max-width: 760px) {
          width: 100%;
          display: block;
          margin: 10px 0;
        } */
        &:nth-child(odd){
          margin-left: 0;
        }
        &:nth-child(even){
          margin-right: 0;
        }
        .personnel{
          .person-img{
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
            img{
              width: 100%;
            }
          }
        }
        .anli-content{
          padding: 15px;
          .anli-title{
            margin-bottom: 10px;
            .name{
              font-size: 20px;
              font-weight: 700;
              display: inline-block;
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .price{
              font-size: 20px;
              font-weight: 700;
              color: #795FF6;
              display: inline-block;
              float: right;
            }
          }
          .times{
            div{
              display: inline-block;
              &.date{
                float: right;
              }
            }
          }

          .nums{
            border-top: 1px solid #cdcdcd;
            padding: 10px 0;
            margin-top: 10px;
            div{
              display: inline-block;
              .iconfont{
                margin-right: 5px;
              }
              &.lookUp{
                float: right;
              }
            }
          }
        }

      }
    }
    .nodata{
      width: 100%; 
      height: 200px; 
      line-height: 200px; 
      font-size: 18px; 
      text-align: center; 
      color: gray; 
      background-color: #FFFFFF;
      display: none;
      @media screen and (max-width: 530px) {
        display: block;
      }
    }
    .anli.mobile{
      display: none;
      background-color: #fff;
      font-size: 16px;
      margin-top: 15px;
      @media screen and (max-width: 530px) {
        display: block;
      }

        .personnel{
          .person-img{
            width: 120px;
            height: 100%;
            margin-right: 10px;
            display: inline-block;
            img{
              width: 100%;
            }
          }
        }
        .anli-content{
          padding: 15px;
          width: calc(100% - 130px);
          box-sizing: border-box;
          display: inline-block;
          .anli-title{
            .name{
              font-size: 20px;
              font-weight: 700;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }
          .times{
            margin: 10px 0;
            div{
              display: inline-block;
              &.date{
                float: right;
              }
            }
          }
          .price{
            font-size: 20px;
            font-weight: 700;
            color: #795FF6;
          }
          .nums{
            margin-top: 10px;
            div{
              display: inline-block;
              .iconfont{
                margin-right: 5px;
              }
              &.lookUp{
                float: right;
              }
            }
          }

      }
    }

  }

</style>
