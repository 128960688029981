
import { getToken, setToken } from '@/utils/auth'
import { Message } from 'element-ui'

const state = {
  token: getToken(),
  name: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  }
}

const actions = {
  // user login
  async login ({ commit }, { userInfo, callback, error }) {
    try {
      
    } catch (err) {
      console.log(err)
    }
  },
  async UserInfo ({ commit }) {
    try {
      
    } catch (err) {
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
