<template>
  <div class="container" v-loading="loading">
    <div class="wrapper">
      <div class="title">消息通知</div>
      <div class="btns">
        <el-button type="text" class="isRead" @click="viewAllRead()">全部已读</el-button>
        <!-- <el-button type="text" v-if="!operate" class="batchManage" @click="operate = true">批量管理</el-button>
        <el-button type="text" v-if="operate" class="batchDelete">删除</el-button>
        <el-button type="text" v-if="operate" class="batchCancle">取消</el-button> -->
      </div>
      <div class="tabBar">
        <div class="item" :class="current == 1?'actived':''" @click="getList(1)">全部</div>
        <div class="item" :class="current == 2?'actived':''" @click="getList(2)">未读</div>
      </div>
      <ul class="noticeList" v-if="dataList.length > 0">
        <li class="notice" v-for="data in dataList">
          <!-- <i class="iconfont icon-guanbi1"></i> -->
          <div>
            <el-badge v-show="data.state!=1" is-dot></el-badge>
            <div class="msg">{{data.title}}</div>
          </div>
          <div>
            <span class="date">{{data.createDate}}</span>
            <el-button type="text" class="viewer" @click="$router.push('/perCenter/notification/'+data.id)">查看详情&gt;&gt;</el-button>
          </div>
        </li>

      </ul>
      <div class="noMore" v-else>
        暂无消息
      </div>
      <el-pagination  @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="form.total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
    </div>
  </div>
</template>

<script>
import {selectMsg ,allRead} from "@/api/notification/notification";
  export default {
    data () {
      return {
        loading: false,
        operate: false,
        isread: 0,
        current: 1,
        dataList:[],
        currentPage: 1,
        state:'',
        form:{
          total: 0
        }
      }
    },
    mounted() {
    this.viewSelectMsg()
    },
    methods: {
      getList(value){
        this.current=value
        if(value === 1){
          this.state =''
          this.viewSelectMsg()
        }else {
          this.state =0
          this.viewSelectMsg()
        }
      },
      async viewSelectMsg(){
        this.loading = true
        const res = await selectMsg({ 'currentPage': this.currentPage,'state':this.state})
        if (res.code === 0) {
          this.dataList=res.data
          this.form.total=res.count
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      },
      async viewAllRead(){
        const res = await allRead()
        if (res.code === 0) {
          this.viewSelectMsg()
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      },
      handleCurrentChange (val) {
        this.currentPage = val
        this.fetchTask()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    text-align: center;
  }
  .container{
    .wrapper{
      background-color: #fff;
      border-radius: 3px;
      padding: 30px;
      margin-bottom: 20px;
      position: relative;
      @media screen and (max-width: 530px) {
        min-height: calc(100vh - 120px);
      }
      .title{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      .btns{
        position: absolute;
        right: 40px;
        top: 20px;
        /deep/.el-button--text{
          color: #795FF6;
          margin-left: 40px;
          &:hover{
            opacity: .78;
          }
        }
      }
      .tabBar{
        background-color: #f9f9f9;
        font-size: 14px;
        color: #8d8d8d;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        margin: 10px 0;
        .item{
          display: inline-block;
          margin-right: 40px;
          cursor: pointer;
          &.actived{
            color: #3d3d3d;
          }
        }
      }
      .noticeList{
        padding: 0;
        margin-bottom: 30px;
      }
      .notice{
        padding:10px 0;
        font-size: 14px;
        color: #3d3d3d;
        border-bottom: 1px solid #EDEDED;
        cursor: pointer;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        &:last-child{
          border: none;
        }
        &:hover{
          background-color: #f9f9f9;
          .iconfont{
            display: block;
          }
        }
        &>div{
          margin: 10px 0;
        }
        .iconfont{
          float: right;
          color: #8d8d8d;
          font-size: 16px;
          cursor: pointer;
          margin: 10px;
          display: none;
        }

        /deep/.el-badge{
          float: left;
          margin-top: 5px;
        }
        .msg{
          width: 80%;
          margin-left: 12px;
          display: inline-block;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .date{
          margin: 0 15px;
          color: #8d8d8d;
        }
        .viewer{
          color: #a068ff;
          margin-left: 50px;

        }
      }
      .noMore{
        text-align: center;
        height: 80px;
        line-height: 80px;
        margin-top: 20px;
        color: #8d8d8d;
      }
    }
  }
</style>
