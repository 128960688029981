<template>
  <div class="container">
    <!-- <div class="swiper">
      <img src="../../assets/images/bg.png" >
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu">招募人才</div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="left-menu">
        <el-menu :default-active="index" class="el-menu-vertical-demo">
          <router-link to="/workOrder">
            <el-menu-item index="1">
              <i class="iconfont icon-liebiaorongqi"></i>
              <span>工单列表</span>
            </el-menu-item>
          </router-link>
          <router-link to="/workOrder/add">
            <el-menu-item index="2">
              <i class="iconfont icon-tianjia1"></i>
              <span>发起工单</span>
            </el-menu-item>
          </router-link>
        </el-menu>
      </div>
      <div class="wrapper">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        index: '1'
      }
    },
    mounted() {
      if(this.$route.path.indexOf('add') == -1){
        this.index = '1'
      }else{
        this.index = '2'
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  
  .content{
    width: 80%;
    margin: 15px auto;
    /deep/.el-radio__input {
    	display: none;
    }
    .left-menu{
      background-color: #fff;
      border-radius: 3px;
      padding: 15px 0;
      width: 240px;
      height: 320px;
      display: inline-block;
      text-align: center;
      margin-right: 20px;
      .el-menu{
        border: none;
        .el-menu-item{
          height: 42px;
          line-height: 42px;
          // font-size: 16px;
          &:hover{
            cursor: pointer;
            background-color: rgba(121, 95, 246, .05);
          }
          &.is-active{
            background-color: #f9f8ff;
            border-left: 2px solid #795FF6;
            color: #795FF6;
          }
          .iconfont{
            margin-right: 20px;
          }
        }
      }
    }
    .wrapper{
      display: inline-block;
      width: calc(100% - 260px);
      vertical-align: top;
    }
  }
</style>
