<template>
  <div class="container">
    <div class="swiper">
      <img src="../../../assets/images/bg.png" >

    </div>
    <div class="content" v-loading="loading">
      <div class="personnnel-wrapper">
        <div class="personnnel-item">

              <div class="personnel">
                <div class="person-img">
                  <img :src="data.headUrl" >
                </div>
                <div style="margin-top: 10px">
                  <span class="renzheng" v-if="data.personalAuthentication === 1">已认证</span>
                </div>
              </div>

              <div class="personnnel-content">
                <div class="personnnel-title">
                  <span class="name">{{ data.nickname }}</span>
                  <span class="job">{{ data.position }}</span>
<!--                  <img src="@/assets/images/vips.png" >-->
                </div>
                <div class="exprience">
                  <span >{{ data.demand }}</span>
                  <span class="space">|</span>
                  <span>
                    <span >{{ data.workExperience }}年经验</span>
                    <span class="space">|</span>
                    <span >工作周期: {{ data.isFullTime === 1 ? '长期' : '短期' }}</span>
                    <span class="space">|</span>
                    <span >工作方式: {{ data.workWay === 0 ? '远程' : '驻场' }}</span>
                  </span>
                </div>
                <div class="exprience">
                  <span >薪资：<span class="price">{{ data.dailySalary }} 元/天</span></span>
<!--                  <span >好评度：-->
<!--                    <el-rate v-model="value" disabled text-color="#ffd161" ></el-rate>-->
<!--                    <span>（123）</span>-->
<!--                  </span>-->
                </div>

              </div>
            <div class="tags">
              <span class="tag" v-for="tag in tagArr">{{ tag }}</span>
            </div>
        </div>
        <div class="tab" v-if="!isMobile">
          <div class="tab-item" :class="current == 1?'actived':''" @click="current = 1">
            <span>个人主页</span>
            <div></div>
          </div>
          <div class="tab-item" :class="current == 2?'actived':''" @click="current = 2">
            <span>项目作品</span>
            <div></div>
          </div>
          <div class="tab-item" :class="current == 3?'actived':''" @click="current = 3">
            <span>用户评价</span>
            <div></div>
          </div>
        </div>
        <homepage v-if="current == 1" :user="data" :isMobile="isMobile" :current-tab="current" @show-tab-change="showChange"></homepage>
        <project-works v-else-if="current == 2" :isMobile="isMobile" :user="data"></project-works>
        <evaluate v-else-if="current == 3" :isMobile="isMobile" :user="data"></evaluate>
      </div>
<!--      <div class="personnnel-list">-->
<!--        <div class="title">相关推荐</div>-->
<!--        <el-button type="text" class="change" @click=""><i class="iconfont icon-Home-change"></i>换一批</el-button>-->
<!--        <div class="recommend">-->
<!--          <div class="person-img">-->
<!--            <img src="@/assets/images/company.png" >-->
<!--          </div>-->
<!--          <div class="personnnel-content">-->
<!--            <div class="personnnel-title">-->
<!--              <span class="name">某某</span>-->
<!--              <span class="job">前端高级工程师</span>-->
<!--              <img src="@/assets/images/vips.png" >-->
<!--            </div>-->
<!--            <div class="exprience">-->
<!--              <span ><span class="price">1500</span>元/天</span>-->
<!--              <span >数据挖掘</span>-->
<!--            </div>-->
<!--            <div class="tags">-->
<!--              <span class="tag">需要发票</span>-->
<!--              <span class="tag">需要发票</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  // var userId
  import evaluate from "./evaluate";
  import homepage from "./homepage";
  import projectWorks from "./projectWorks";
  import {getUserInfo} from "@/api/personnel";
  import {apiBaseURL} from "@/utils/request";
  import { _isMobile } from '@/utils/index';
export default {
  components: {
    projectWorks,homepage,evaluate
  },
  data () {
    return {
      data: {},
      loading: true,
      value: 3.7,
      current: 1,
      userId: '',
      isMobile: false,
      tagArr: []
    }
  },
  methods: {
    async getUserInfo () {
      this.loading = true
      const res = await getUserInfo({ id: this.userId })
      if (res.code === 0) {
        this.data = res.data
        this.data.headUrl = this.data.headUrl ? apiBaseURL + this.data.headUrl : apiBaseURL + '/avator/company.png'
        this.data.demand = res.data.demandList.join(' ')
        this.tagArr = this.data.skill.split('、')
      } else {
        this.$message.error('人才信息获取失败！')
      }
      this.loading = false
    },
    showChange (val) {
      console.log('parent: ' + val)
      this.current = val
    }
  },
  mounted () {
    if (_isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.userId = this.$route.params.id
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-rate {
  display: inline;
  vertical-align: text-bottom;
}
/deep/.el-rate__icon{
  margin-right: 1px;
}
.swiper{
  position: relative;
  @media screen and (max-width: 530px) {
    display: none;
  }
  img{
    width: 100%;
    height: 320px;
  }
}
.content{
  width: 80%;
  margin: 10px auto;
  @media screen and (max-width: 530px) {
    width: 100%;
    margin-top: 80px;
  }
  /* .personnnel-list{
    width: 250px;
    margin-left: 10px;
    margin-top: 10px;
    padding: 20px 15px;
    background-color: #fff;
    display: inline-block;
    vertical-align: top;
    position: relative;
    .title{
      font-size: 16px;
      font-weight: 700;
    }
    .change{
      position: absolute;
      top: 7px;
      right: 20px;
      color: #7B62F7;
      .iconfont{
        font-size: 20px;
        vertical-align: middle;
      }
    }
    .recommend{
      padding: 10px 0;
      margin: 10px 0;
      border-bottom: 1px solid #EDEDED;
      &:last-child{
        border: none;
      }
      .person-img{
        width: 50px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: top;
        img{
          width: 100%;
          border-radius: 50%;
        }
      }
      .personnnel-content{
        display: inline-block;
        div{
          margin-bottom: 10px;
        }
        .personnnel-title{
          .name,.job{
            font-size: 14px;
            font-weight: 700;
            margin-right: 5px;
          }
          img{
            width: 16px;
          }
        }
        .exprience{
          font-size: 12px;
          color: #8d8d8d;
          &>span{
            margin: 0 10px;
          }
          span:first-child{
            margin-left: 0;
          }
        }
        .price{
          font-size: 18px;
          font-weight: 700;
          color: #333;
        }

        .tags{
          margin-top: 15px;
          margin-left: -120px;
          .tag{
            background-color: #eaeaec;
            font-size: 12px;
            padding: 4px 12px;
            border-radius: 20px;
            margin-right: 10px;
          }
        }
      }
    }

  } */
  .personnnel-wrapper{
    width: calc(100% - 0px);
    display: inline-block;
  }
  .personnnel-item{
    background-color: #fff;
    font-size: 16px;
    padding: 30px 30px 20px;
    margin-top: 10px;
    .personnel{
      text-align: center;
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
      .person-img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        @media screen and (max-width: 530px) {
          width: 60px;
          height: 60px;
        }
        img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .renzheng{
        font-size: 12px;
        padding: 2px 5px;
        border: 1px solid #7B62F7;
        color: #7B62F7;
        border-radius: 3px;
      }
    }
    .personnnel-content{
      display: inline-block;
      @media screen and (max-width: 530px) {
        width: calc(100% - 80px);
      }
      div{
        margin-bottom: 10px;
      }
      .personnnel-title{
        .name,.job{
          font-size: 20px;
          font-weight: 700;
          margin-right: 10px;
        }
        img{
          width: 16px;
          margin-left: 10px;
        }
      }
      .exprience{
        font-size: 14px;
        color: #8d8d8d;
        line-height: 1.68;
        &:last-child{
          margin: 0;
        }
        &>span{
          margin: 0 10px;
          @media screen and (max-width: 530px) {
            display: block;
            margin: 10px 0 0;
          }
          .space{
            margin: 0 5px;

          }
          &.space{
            @media screen and (max-width: 530px) {
              display: none;
            }
          }
        }
        span:first-child{
          margin-left: 0;
        }
      }
      .price{
        font-size: 18px;
        font-weight: 700;
        color: #333;
      }


    }
    .tags{
      margin-left: 115px;
      @media screen and (max-width: 530px) {
        margin: 0;
      }
      .tag{
        background-color: #7B62F7;
        color: #FFFFFF;
        font-size: 14px;
        padding: 4px 12px;
        border-radius: 20px;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
  .tab{
    background-color: #fff;
    margin: 10px 0;
    padding: 20px 30px 8px;
    .tab-item{
      cursor: pointer;
      display: inline-block;
      color: #8d8d8d;
      font-size: 16px;
      font-weight: 700;
      margin-right: 50px;
      &.actived{
        color: #3d3d3d;
        div{
          width: 30px;
          height: 2px;
          border: 1px solid #795FF6;
          margin: 12px auto 0;
        }
      }
    }
  }
}
</style>
