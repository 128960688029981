<template>
  <div class="container" v-loading="loading">
    <div class="title">推广邀请</div>
    <div class="img-wrapper">
      <img src="../../../assets/images/invitation.png" alt="">
      <div class="desc">
        <div class="img-title">
          <span>邀请<span style="color: #ffea00;">有礼</span></span><span>荐者有份</span>
        </div>
        <div>邀请好友，得积分奖励</div>
        <div style="background-image: linear-gradient(to left, #fa584c, #ebcf78);width:120px;text-align: center;color:#fff;height:18px;line-height: 18px;border-radius: 18px;">积分兑换大额奖励</div>
      </div>
    </div>
    <div class="types">
      <div class="sub-title">方式一：链接推广</div>
      <div class="link">
        <span>我的推广链接</span>
        <el-link  :href="form.href" target="_blank">{{form.href}}</el-link>
      </div>
    </div>
    <div class="types">
      <div class="sub-title">方式二：扫描二维码推广</div>
      <div class="code-img">
        <!-- <img src="../../../assets/images/wechat_code.png" > -->
        <div class="qrcodeimg">
          <div ref="qrcode"></div>
        </div>
        <div class="desc">扫码推广得积分</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInviteUrl } from "@/api/invitation/invitation";
import {apiBaseURL} from "@/utils/request";
import QRCode from "qrcodejs2";
  export default {
    data () {
      return {
        loading: false,
        form: {
          href: ''
        }
      }
    },
    mounted() {
    this.viewGetInviteUrl()
    },
    methods: {
      async viewGetInviteUrl () {
        this.loading = true
        const res = await getInviteUrl()
        this.form.href = 'https://52shuju.com/#' +'/register/'+ res.msg
        this.qrcode()
        this.loading = false
      },
      qrcode() {
        // 在属性ref="qrcode"的div里边添加二维码
        const qrcode = new QRCode(this.$refs.qrcode, {
          width: 120,
          height: 120, // 高度
          colorLight: "#ffffff",
          text: this.form.href // 二维码内容（接口返回的数字或者什么，如：需要扫码跳转的链接）
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .img-wrapper{
      width: 100%;
      height: 180px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      .desc{
        position: absolute;
        bottom: 30px;
        left: 80px;
        color: #fff;
        .img-title{
          font-size: 22px;
          font-weight: 700;
          font-style: italic;
          margin: 10px 0 25px;
          &>span{
            margin-right: 15px;
          }
        }
        div{
          margin: 15px 0;
        }
      }
    }
    .types{
      margin-top: 30px;
      .sub-title{
        font-size: 16px;
        font-weight: 700;
        margin: 15px 0;
      }
      .link{
        span{
          color: #8d8d8d;
          font-size: 14px;
          margin-right: 30px;
        }
        .el-link.el-link--default{
          color: #795FF6;
          vertical-align: baseline;
        }
        .el-link.is-underline:hover:after {
          border-bottom: 1px solid #795FF6;
        }
      }
      .code-img{
        text-align: center;
        .qrcodeimg{
          width: 120px;
          height: 120px;
          margin: 30px auto 15px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .desc{
          color: #8d8d8d;
          font-size: 14px;
        }
      }
    }
  }

</style>
