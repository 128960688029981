import request from '@/utils/request'
export function fetchTask (data) {
  return request({
    url: '/task/fetch',
    method: 'POST',
    data
  })
}
export function fetchTaskInfo (data) {
  return request({
    url: '/task/fetchInfo',
    method: 'POST',
    data
  })
}
export function fetchEnrolled (data) {
  return request({
    url: '/recruit/fetchEnrolled',
    method: 'POST',
    data
  })
}
export function confirmUser (data) {
  return request({
    url: '/recruit/confirmUser',
    method: 'POST',
    data
  })
}
export function payNow (data) {
  return request({
    url: '/order/saveOrder',
    method: 'POST',
    data
  })
}
export function confirmMilestoneComplet (data) {
  return request({
    url: '/milestone/confirmCompletion',
    method: 'POST',
    data
  })
}
export function saveRecruit (data) {
  return request({
    url: '/task/apply',
    method: 'POST',
    data
  })
}
// 评价
export function comment (data) {
  return request({
    url: '/comment/commit',
    method: 'POST',
    data
  })
}
// 开发者评价
export function commentFetch (data) {
  return request({
    url: '/comment/fetch',
    method: 'POST',
    data
  })
}
// 创建企业账号
export function addApplyCompany (data) {
  return request({
    url: '/apply/addApplyCompany',
    method: 'POST',
    data
  })
}
// 获取程序员id
export function getDeveloperId (data) {
  return request({
    url: '/task/getDeveloperId',
    method: 'POST',
    data
  })
}
// 获取所有已注册公司账号
export function getAllAssistCompany (data) {
  return request({
    url: '/personalUser/getAllAssistCompany',
    method: 'POST',
    data
  })
}
export function commitTask (data) {
  return request({
    url: '/task/commit',
    method: 'POST',
    data
  })
}
export function fetchMyTask (data) {
  return request({
    url: '/task/fetchMySent',
    method: 'POST',
    data
  })
}
export function deliver (data) {
  return request({
    url: '/milestone/deliver',
    method: 'POST',
    data
  })
}
export function deliverFile (data) {
  return request({
    url: '/milestone/deliverFile',
    method: 'POST',
    data
  })
}
export function companyPay (data) {
  return request({
    url: '/task/companyPay',
    method: 'POST',
    data
  })
}
