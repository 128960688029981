import request from '@/utils/request'

// 案例列表
export function getCase (data) {
  return request({
    url: '/case/getCase',
    method: 'POST',
    data
  })
}
// 添加
export function saveCase (data) {
  return request({
    url: '/case/saveCase',
    method: 'POST',
    data
  })
}
// 修改
export function updateCase (data) {
  return request({
    url: '/case/updateCase',
    method: 'POST',
    data
  })
}

// 删除
export function deleteCase (data) {
  return request({
    url: '/case/deleteCase',
    method: 'POST',
    data
  })
}
// 根据id获取
export function getCaseByid (data) {
  return request({
    url: '/case/getCaseByid',
    method: 'POST',
    data
  })
}
