<template>
  <div class="container">
    <div class="content">
      <div class="header">
        <span class="title">选择收货地址</span>
        <span class="tip"><span class="required">*</span>提示：偏远地区暂不支持发货，请联系客服解决</span>
      </div>
      <div class="addressList">
        <div class="address-item" :class="index==currIndex?'actived':''" v-for="(item,index) in address" @click="currIndex=index;currAddress=item;">
          <div class="name">{{item.name}}</div>
          <div class="contactMsg">
            <span class="address">{{item.address}}</span>
            <span class="phoneNumber">{{item.phone}}</span>
          </div>
          <div class="btn-group">
            <el-button type="text" class="answer" @click="deleteAddress(item.id)">删除</el-button>
            <span class="default" v-if="index == 0">默认地址</span>
          </div>
        </div>
      </div>
      <el-button type="text" class="addAddress" @click="showForm = true">添加收货地址</el-button>
      <el-form :model="form" ref="ruleForm" label-width="100px" class="addForm" v-if="showForm">
        <el-form-item label="收货人" prop="name" required>
          <el-input size="medium" v-model="form.name" placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone" required>
          <el-input size="medium" v-model="form.phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="省份地区" required>
          <v-distpicker @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></v-distpicker>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailed" required>
          <el-input size="medium" v-model="form.detailed" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-checkbox v-model="checked">是否设为默认收货地址</el-checkbox> -->
          <div class="btn-group">
            <el-button size="small" @click="showForm = false">取消</el-button>
            <el-button size="small" type="primary" @click="addAddress">保存收货地址</el-button>
          </div>
        </el-form-item>
      </el-form>


      <div class="goods">
        <div class="title">商品清单</div>
        <el-table :data="data" style="width: 100%">
          <el-table-column label="商品信息" header-align="center">
            <template scope="{ row }">
              <div class="img-wrapper">
                <img :src="row.img" >
              </div>
              <div class="goodMsg">
                <div class="name" style="font-size: 18px;font-weight: 700;">{{row.productName}}</div>
                <div style="margin: 20px 0 10px;color: #6d6d6d;">
                  <span>样式：{{row.describe}}</span>
                  <span style="margin-left: 30px;">规格：1</span>
                </div>
                <div style="color: #6d6d6d;">
                  物流配送
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品单价" width="220" align="center">
            <template scope="{ row }">
              <span>{{row.price}}积分</span>
            </template>
          </el-table-column>
          <el-table-column label="商品数量" align="center" width="220">
            <template scope="{ row }">
              <el-input-number size="small" v-model="num" :min="1" label="描述文字"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <div class="submit-btn">
          <div style="margin-right: 25px;">
            <span style="font-size: 18px;">实际支付</span>
            <span style="color: #A068FF;margin-left: 12px;">
              <span style="font-size: 22px;">{{goodMsg.price*num}}</span>积分
            </span>
          </div>
          <el-button type="primary" size="small" @click="exchangeProduct">提交订单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getAddressList,addAddress,deteleAddress,exchangeProduct} from '@/api/integral/integral'
  export default{
    data(){
      return {
        form:{},
        checked: '',
        data: [],
        num: '1',
        currIndex: 0,
        currAddress:{},
        address:[],
        showForm: false,
        goodMsg:{}
      }
    },
    mounted() {
      this.goodMsg = JSON.parse(this.$route.query.goods)
      this.data.push(this.goodMsg)
      this.getList()
    },
    methods:{
      async getList(){
        const res = await getAddressList()
        if(res.code == 0){
          this.address = res.data
          this.currAddress = res.data[0]
        }else{
          this.$message.error(res.msg)
        }
      },
      async addAddress(){
        this.form.address = this.form.province+this.form.city+this.form.area+this.form.detailed
        const res = await addAddress(this.form)
        if(res.code == 0){
          this.$message.success('地址添加成功')
          this.showForm = false
          this.form = {}
        }else{
          this.$message.error(res.msg)
        }
        this.getList()
      },
      deleteAddress(id){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await deteleAddress({id:id})
          if (res.code == 0){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }else{
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      },
      async exchangeProduct(){
        if(this.$route.query.jifen < this.goodMsg.price*this.num){
          this.$message.error('您的积分不足以兑换相应数量的该商品，请重新选择！')
          return
        }
        var product = this.goodMsg
        product.deliveryInformationId = this.currAddress.id
        product.name = this.currAddress.name
        product.phone = this.currAddress.phone
        product.address = this.currAddress.address
        product.quantity = this.num
        const res = await exchangeProduct(product)
        if(res.code == 0){
          this.$message.success('兑换成功')
        }else{
          this.$message.error(res.msg)
        }
      },
      onChangeProvince (a) {
        this.form.province = a.value;
        this.form.cityArea = '';
        this.form.area = '';
      },
      onChangeCity (a) {
        this.form.city = a.value;
        this.form.area = '';

      },
      onChangeArea (a) {
        this.form.area = a.value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.distpicker-address-wrapper select{
    padding: 5px 15px;
    height: 36px;
    font-size: 14px;
    border-radius: 4px;
  }
  .content{
    background-color: #fff;
    border-radius: 3px;
    margin: 90px auto 20px;
    width: 80%;
    padding: 40px 50px;
    @media screen and (max-width: 530px) {
      padding:30px;
    }
    @media screen and (max-width: 530px) {
      width: 100%;
    }
    .title{
      font-size: 24px;
      font-weight: 700;
    }
    .tip{
      font-size: 14px;
      line-height: 1.6;
      color: #8d8d8d;
      margin-left: 25px;
      @media screen and (max-width: 530px) {
        display: block;
        margin: 15px 0;
      }
      .required{
        color: #F56C6C;
        margin-right: 8px;
      }
    }
    .el-checkbox{
      margin-right: 20px;
    }
    .addressList{
      margin: 15px 0;
      .address-item{
        border: 1px solid #adadad;
        padding: 15px;
        width: 220px;
        display: inline-block;
        line-height: 1.6;
        margin: 15px 15px 0 0;
        cursor: pointer;
        @media screen and (max-width: 530px) {
          display: block;
          width: 100%;
          margin: 15px 0;
        }
        &.actived{
          border-color: #A068FF;
        }
        .name{
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .contactMsg{
          border-top: 1px solid #DAD8D8;
          padding: 10px 0;
          font-size: 14px;
          color: #3d3d3d;
          .phoneNumber{
            margin-left: 15px;
          }
        }
        .btn-group{
          .default{
            float: right;
          }
          .el-button--text{
            color: #a068ff;
            padding: 0;
          }
        }
      }
    }
    .addAddress{
      color: #795FF6;
      font-size: 16px;
    }
    .addForm{
      margin: 20px 0;
      .el-input{
        width: 50%;
        @media screen and (max-width: 530px) {
          width: 100%;
        }
      }
    }
    .goods{
      margin: 30px 0;
    }
    .submit-btn{
      text-align: right;
      margin-top:50px;
    }
    .img-wrapper,.goodMsg{
      display: inline-block;
      vertical-align: middle;
      margin: 10px;
      img{
        width: 100px;
      }
    }
  }
</style>
