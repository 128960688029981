<template>
  <div class="login-wrap" v-loading="loading">
    <div class="content">
      <div class="login-box">
        <div class="logo"><img src="../../assets/images/logo.png" ></div>
        <!-- <div class="login-type">
         <div @click="loginType=1">手机号登录<hr v-if="loginType == 1"/></div>
         <div @click="loginType=0">账号密码登录<hr  v-if="loginType == 0"/></div>
        </div> -->
        <div v-if="loginType == 0" class="login-form">
          <el-input :autofocus="true" v-model="username" placeholder="请输入账号"/>
          <el-input v-model="password" placeholder="用户密码" show-password />
          <!-- <div class="code">
            <el-input v-model="form.verificationCode" placeholder="请输入验证码"/>
            <el-button type="text" class="getcode" :disabled="getcode" @click="getCaptcha">{{btnTitle}}</el-button>
          </div> -->
          <div>
            <el-checkbox v-model="remembered" @change="isRemember">记住密码</el-checkbox>
            <el-button type="text" style="color: #6a6a6a;float: right;padding:0" @click="$router.push('/forgetPass')">忘记密码</el-button>
          </div>
          <el-button class="login" type="primary" style="background-color: #795FF6;border-color: #795FF6;" @click="login">登&nbsp;&nbsp;录</el-button>
        </div>
        <div v-else class="login-code">
          <el-input v-model="mobile" placeholder="请输入手机号"/>
          <div class="code">
            <el-input v-model="verificationCode" placeholder="请输入手机验证码"/>
            <el-button type="text" class="getcode" :disabled="getcode" @click="getCaptcha">{{btnTitle}}</el-button>
          </div>
          <el-checkbox v-model="checked">我同意遵守 <el-button type="text" style="color: #795FF6;padding:0;">《52用户服务协议》</el-button></el-checkbox>
          <el-button class="login" type="primary" @click="codeLogin">登&nbsp;&nbsp;录</el-button>
        </div>
        <div style="text-align: right;color: #795FF6;margin-top: 20px; font-size: 13px"><router-link to="/register">还没有账号？立即注册</router-link></div>
      </div>

    </div>
  </div>
</template>

<script>
import {login,getVerificationCode} from "@/api/personnel";
import {setToken} from "@/utils/auth";
export default {
  data () {
    return ({
      username: '',
      password: '',
      mobile: '',
      captcha: '',
      verificationCode: '',
      getcode: false,
      btnTitle:'获取验证码',
      remembered: false,
      checked: false,
      loginType: 0,
      loading: false
    })
  },
  mounted () {
    var user = JSON.parse(localStorage.getItem('puser'))
    if (user) {
      this.username = user.username
      this.password = user.password
      this.remembered = true
    }
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    async login () {
      if(this.username == ''){
        this.$message.error('请输入账号')
        return
      }
      if(this.password == ''){
        this.$message.error('请输入密码')
        return
      }
      this.loading = true
      const res = await login({ username: this.username, password: this.password })
      if (res.code === 0) {
        setToken(res.data.token)
        this.$router.push('/')
      } else {
        this.$message.error(res.msg)
      }
      this.loading = false
    },
    codeLogin(){
      if(this.mobile == ''){
        this.$message.error('请输入手机号')
        return
      }
      if(this.verificationCode == ''){
        this.$message.error('请输入验证码')
        return
      }
      if(!this.checked){
        this.$message.error('未同意《52用户服务协议》')
        return
      }
    },
    isRemember(val){
      if(val){
        localStorage.setItem('puser',JSON.stringify({username: this.username, password: this.password}))
      }else{
        localStorage.removeItem('puser')
      }
    },
    async getCaptcha(){
      if(this.mobile == ''){
        this.$message.error('请输入手机号')
        return
      }
      this.getcode = true
      var num = 59
      this.btnTitle= '重新获取('+num+')'
      var index = setInterval(()=>{
        num--
        this.btnTitle= '重新获取('+num+')'
        if(num == 1){
          clearInterval(index)
          this.getcode = false
          this.btnTitle = '获取验证码'
        }
      },1000)
      var res = await getVerificationCode('mobile='+this.form.mobile)
      if(res.code !== 0){
        this.$message.error(res.msg)
      }
    },
    keyDown (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/login-bg.png") no-repeat;
  background-size: cover;
  .content {
    width: 500px;
    // height: 400px;
    padding-bottom: 60px;
    background: #fff;
    border-radius: 5px;
    padding: 30px 50px;
    position: relative;
    top: 50%;
    left: 50%;
    @media screen and (max-width: 530px) {
      width: 80%;
    }
    transform: translate(-50%, -50%);
    .logo {
      width: 50%;
      margin:10px auto 25px;
      @media screen and (max-width: 530px) {
        width: 80%;
      }
      img{
        width: 100%;
      }
    }
    .login-box{
      width: 100%;
      margin: 0 auto;
      padding-bottom:0px;
      .login-type{
        border-bottom: 1px solid #DFF0D8;
        margin-bottom: 25px;
        div{
          display: inline-block;
          width: 50%;
          height: 42px;
          line-height: 42px;
          text-align: center;
          font-size: 18px;
          font-weight: 700;
          position: relative;
          cursor: pointer;
          hr{
            width: 30px;
            border-color: #795ff6;
            position: absolute;
            bottom: -9px;
            left: 50%;
            margin-left: -15px;
          }
        }
      }

      .code{
        position: relative;
        .getcode{
          position: absolute;
          right: 0;
          border-left: 1px solid #dcdcdc;
          width: 100px;
        }
        .el-button--text{
          color: #5d5d5d;
        }
        .el-button--text.is-disabled{
          color: #C0C4CC;
        }
      }
      .el-input {
        margin-bottom: 15px;
        .el-input__inner {
          height: 50px;
          line-height: 50px;
        }
      }
      .login {
        width: 100%;
        height: 40px;
        margin-top: 20px;
        display: block;
      }
    }
  }
}
</style>
