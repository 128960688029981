<template>
  <div class="box">
    <div style="background-color: #FFF;padding: 20px" v-loading="loading">
      <div class="title">{{ item.title }}</div>
      <div style="overflow: auto">
        <div style="width: 100%;height: 40px">
          <el-tag v-if="item.typeTag" :color="item.tagColor" style="color: #FFF" size="small">{{ item.typeTag }}</el-tag>
          <span style="font-size: 14px;color: #474f59"><i class="el-icon-user-solid" style="margin-right: 5px;margin-left: 20px"></i>{{ item.nickname }}</span>
          <span style="color: #a3a6ad;margin-left: 20px;font-size: 14px">{{ item.createDate }}</span>
        </div>
        <div class="c-content" v-html="item.body" style="font-size: 16px !important;">

        </div>
        <div class="c-right">

        </div>
      </div>
      <div style="width: 1200px;height: 10px;background-color: #F7F6FB;margin-left: -20px"></div>
      <div class="comments-box" style="padding-bottom: 20px;padding-top: 20px">
        <div style="font-size: 20px;font-weight: 500;color: #192544;margin-bottom: 20px">评论区（{{ item.commentNum }}）</div>
        <div v-for="item in item.comments" style="padding: 20px 0;border-bottom: 1px solid #EDEDED">
          <div style="height: 40px;line-height: 40px">
            <img :src="item.avator" style="width: 40px; height: 40px; border-radius: 40px;margin-right: 10px">
            <span style="position: relative; top: -13px;font-size: 14px;font-weight: 400;color: #474f59;margin-right: 10px">{{ item.userName }}</span>
            <span style="position: relative; top: -13px;font-size: 14px;font-weight: 400;color: #b4b8bf;">{{ item.commentDate }}</span>
          </div>
          <div style="color: #474f59;font-size: 14px;margin-top: 10px;padding-left: 50px" v-html="item.commentText">
          </div>
          <div style="color: #a3a6ad;font-size: 14px;margin-top: 20px;padding-left: 50px">
            <i v-if="!item.commentStatus" class="el-icon-chat-dot-round" style="margin-right: 5px"></i><span @click="changeCommentStatus(item,true)" v-if="!item.commentStatus" style="cursor:pointer">回复</span>
<!--            <i v-if="item.commentStatus" class="el-icon-circle-close" style="margin-right: 5px;color: #F56C6C"></i><span v-if="item.commentStatus" @click="changeCommentStatus(item,false)" style=";margin-right: 10px;color: #F56C6C">取消</span>-->
<!--            <i v-if="item.commentStatus" class="el-icon-circle-check" style="margin-right: 5px;color: #7B62F6"></i><span v-if="item.commentStatus" style="color: #7B62F6">发布</span>-->
            <el-button v-if="item.commentStatus" plain type="danger" icon="el-icon-circle-close" size="mini" @click="changeCommentStatus(item,false)">取消</el-button>
            <el-button v-if="item.commentStatus" type="primary" icon="el-icon-circle-check" size="mini" @click="reply(item.replyTxt, item.id, item.id)">回复</el-button>
          </div>
          <div style="margin-top: 20px;padding-left: 50px">
            <el-input
              v-if="item.commentStatus"
              style="width: calc(100% - 50px)"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="item.replyTxt">
            </el-input>
          </div>
          <div v-if="item.childComments.length > 0" style="margin-left: 50px; width: calc(100% - 50px); background-color: #F8F9FA; margin-top: 10px;padding-left: 20px;padding-bottom: 20px">
            <div v-for="cItem in item.childComments" style="padding: 20px 0;border-bottom: 1px solid #EDEDED">
              <div style="height: 40px;line-height: 40px">
                <img :src="cItem.avator" style="width: 40px; height: 40px; border-radius: 40px;margin-right: 10px">
                <span style="position: relative; top: -13px;font-size: 14px;font-weight: 400;color: #474f59;margin-right: 10px">{{ cItem.userName }} 回复 {{ cItem.replyUserName }}</span>
                <span style="position: relative; top: -13px;font-size: 14px;font-weight: 400;color: #b4b8bf;">{{ cItem.commentDate }}</span>
              </div>
              <div style="color: #474f59;font-size: 14px;margin-top: 10px;padding-left: 50px">
                {{ cItem.commentText }}
              </div>
              <div style="color: #a3a6ad;font-size: 14px;margin-top: 20px;padding-left: 50px">
                <i v-if="!cItem.commentStatus" class="el-icon-chat-dot-round" style="margin-right: 5px"></i><span @click="changeCommentStatus(cItem,true)" v-if="!cItem.commentStatus" style="cursor:pointer">回复</span>
                <el-button v-if="cItem.commentStatus" plain type="danger" icon="el-icon-circle-close" size="mini" @click="changeCommentStatus(cItem,false)">取消</el-button>
                <el-button v-if="cItem.commentStatus" type="primary" icon="el-icon-circle-check" size="mini" @click="reply(cItem.replyTxt, item.id, cItem.id)">回复</el-button>
              </div>
              <div style="margin-top: 20px;padding-left: 50px">
                <el-input
                  v-if="cItem.commentStatus"
                  style="width: calc(100% - 50px)"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="cItem.replyTxt">
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" style="margin: 20px 0;padding-bottom: 20px" layout="total,prev, pager, next" :total="total" :current-page.sync="currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      <div style="width: 1200px;height: 10px;background-color: #F7F6FB;margin-left: -20px"></div>
      <div v-loading="commentLoading">
        <div style="overflow: auto;margin-top: 20px">
          <el-button style="float: right" type="primary" size="small" @click="comment">评论</el-button>
        </div>
        <div id="toolbar-container" style="z-index: 100 !important;"></div>
        <div id="editor-container" style="z-index: 100 !important;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth"
import E from 'wangeditor'
import {fetchTopicDetail, sendComment, sendReply} from "@/api/community/community";
import {apiBaseURL} from "@/utils/request";
var editor = {}
export default {
  data () {
    return {
      loading: false,
      commentLoading: false,
      textarea: '',
      currentPage: 1,
      total: 10,
      item: {
        id: '',
        title: '',
        desc: '',
        publishDate: '',
        commentCount: 0,
        images: [],
        typeTag: '',
        tagColor: '',
        good: true,
        commentStatus: false,
        comments: []
      }
    }
  },
  mounted () {
    this.fetchTopicDetail()
    editor = new E('#toolbar-container', '#editor-container')
    editor.config.zIndex = 100
    editor.config.placeholder = '请输入话题内容'
    editor.config.menus = [
      'bold',
      'italic',
      'underline',
      'foreColor',
      'link',
      'image'
    ]
    editor.config.showLinkImg = false
    editor.config.uploadImgServer = apiBaseURL + '/community/upload-img'
    editor.config.uploadImgMaxLength = 1
    editor.config.uploadFileName = 'file'
    editor.config.uploadImgMaxSize = 10 * 1024 * 1024
    editor.config.uploadImgTimeout = 20 * 1000
    editor.config.uploadImgHeaders = {
      token: getToken()
    }
    var _this = this
    editor.config.uploadImgHooks = {
      before: function (xhr) {
        _this.postLoading = true
      },
      success: function (xhr) {
        _this.postLoading = false
      },
      // 图片上传并返回了结果，但图片插入时出错了
      fail: function (xhr, editor, resData) {
        _this.postLoading = false
      },
      // 上传图片出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.postLoading = false
      },
      // 上传图片超时
      timeout: function (xhr) {
        _this.postLoading = false
      }
    }
    editor.config.pasteFilterStyle = false
    editor.config.pasteIgnoreImg = true
    editor.create()
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchTopicDetail()
    },
    async fetchTopicDetail () {
      this.loading = true
      const res = await fetchTopicDetail({ id: this.$route.params.id, currentPage: this.currentPage })
      if (res.code === 0 && res.data) {
        this.total = res.count
        if (res.data.comments.length > 0) {
          for (var i = 0; i < res.data.comments.length; i++) {
            var item = res.data.comments[i]
            item.avator = item.avator ? apiBaseURL + item.avator : apiBaseURL + '/avator/company.png'
            if (item.childComments.length > 0) {
              for (var j = 0; j < item.childComments.length; j++) {
                var citem = item.childComments[j]
                citem.avator = citem.avator ? apiBaseURL + citem.avator : apiBaseURL + '/avator/company.png'
              }
            }
          }
        }
        this.item = res.data
        this.loading = false
      }
    },
    async comment () {
      if (getToken()) {
        if (!editor.txt.html()) {
          this.$message({
            message: '评论内容不可为空',
            type: 'warning',
            customClass: 'message-override',
            offset: 80
          })
          return
        }
        this.commentLoading = true
        const res = await sendComment({ sendContent: editor.txt.html(), topicId: this.$route.params.id })
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
            offset: 80
          })
          editor.txt.clear()
          this.fetchTopicDetail()
          this.commentLoading = false
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
            offset: 80
          })
          this.commentLoading = false
        }
      } else {
        this.$router.push('/login')
      }
    },
    async reply (replyTxt, parentId, parentId2) {
      if (replyTxt.length > 150) {
        this.$message({
          message: '回复内容长度不可超过150个字符',
          type: 'warning',
          customClass: 'message-override',
          offset: 80
        })
        return
      }
      var data = { sendContent: replyTxt, parentId: parentId, topicId: this.$route.params.id, parentId2: parentId2 }
      console.log(data)
      if (getToken()) {
        if (!replyTxt) {
          this.$message({
            message: '回复内容不可为空',
            type: 'warning',
            customClass: 'message-override',
            offset: 80
          })
          return
        }
        this.replyLoading = true
        const res = await sendReply(data)
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
            offset: 80
          })
          this.fetchTopicDetail()
          this.replyLoading = false
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
            offset: 80
          })
          this.replyLoading = false
        }
      } else {
        this.$router.push('/login')
      }
    },
    changeCommentStatus (item, boolean) {
      console.log(item)
      item.replyTxt = ''
      item.commentStatus = boolean
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-pager li.active,/deep/.el-pager li:hover,
/deep/.el-pagination button:hover{
  color: #a068ff;
}
/deep/.el-pagination .btn-prev,
/deep/.el-pagination .btn-next,
/deep/.el-pager li{
  background-color: transparent;
  color: #6d6d6d;
}
/deep/.el-pagination{
  text-align: right;
}
/deep/.el-tag{
  border-color: transparent;
}
.box {
  width: 1000px;
  margin: 0 auto;
  padding-top: 100px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.c-content {
  padding: 0px 0;
  width: 100%;
  float: left;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.c-right {
  width: calc(25% - 30px);
  height: 400px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0 lightgray;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  display: none;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
#toolbar-container {
  margin-top: 20px;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
#editor-container {
  border: 1px solid lightgray;
  height: 300px;
  max-height: 300px;
}
</style>
<style scoped>
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}
.title {
  font-size: 32px;
  font-weight: 500;
  color: #192544;
  line-height: 45px;
  margin-bottom: 10px;
  word-break: break-all;
}
.c-content p {
  font-size: 24px !important;
  line-height: 1.5;
  margin: 10px 0 !important;
}
.c-content img {
  width: 100% !important;
}
.message-override {
}
</style>
