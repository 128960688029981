<template>
  <div class="container">
    <!-- <div class="swiper">
      <img src="../../assets/images/bg.png" >
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu">招募人才</div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="sideBar">
        <div class="personal">
          <div class="programmer" v-if="role == 'person'" v-loading="userInfoLoading">
            <div class="person">
              <div class="img-wrapper"><img :src="userInfo.headUrl" ></div>
              <div class="person-msg">
                <div>
                  <span class="name">{{ userInfo.nickName }}</span>
                  <span class="renzheng" v-if="userInfo.isAuth === 1">已认证</span>
                </div>
                <div class="jifen">
                  <span>我的积分</span>
                  <i class="iconfont icon-jifen"></i>
                  <span>{{ userInfo.integral ? userInfo.integral : 0 }}</span>
                  <el-button type="text" class="sign" @click="signIn" v-if="userInfo.isSignIn == 0">签到获取</el-button>
                </div>
              </div>
            </div>
            <div class="balance">
              <div>
                <div class="title">账户剩余额度：</div>
                <div class="num">￥{{ userInfo.balance ? userInfo.balance : 0 }}</div>
              </div>
              <el-button class="withdraw" size="medium" type="primary" @click="$router.push('/flowRecord/withdrawal')">我要提现</el-button>
            </div>
          </div>
          <div class="company" v-else v-loading="userInfoLoading">
            <img :src="userInfo.headUrl" >
            <div class="companyMsg">
              <div class="name">{{ userInfo.nickName }}</div>
              <span class="renzheng" v-if="isAuth == 1">已认证</span>
              <div class="jifen">
                <span>我的积分</span>
                <i class="iconfont icon-jifen"></i>
                <span>{{ userInfo.integral ? userInfo.integral : 0 }}</span>
                <el-button type="text" class="sign" @click="signIn" v-if="userInfo.isSignIn == 0">签到获取</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="left-menu">
          <el-menu :default-active="index" class="el-menu-vertical-demo">
            <router-link to="/flowRecord">
              <el-menu-item index="1">
                <i class="iconfont icon-zijinguanli"></i>
                <span>资金记录</span>
              </el-menu-item>
            </router-link>
            <router-link to="/flowRecord/integral">
              <el-menu-item index="2">
                <i class="iconfont icon-jifen1"></i>
                <span>积分记录</span>
              </el-menu-item>
            </router-link>
          </el-menu>
        </div>
      </div>
      <div class="wrapper">
        <router-view />
      </div>
    </div>
    <transition name="fade">
      <div class="qiandao-wrapper" v-if="showItem" @click="showItem = false;">
        <div class="qiandao-img">
          <div class="success">今日签到成功</div>
          <div class="jifen"><span class="num">+20</span>积分</div>
          <div class="tip">明日签到领20积分</div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import { getUserInfo, signIn } from "@/api/flowRecord/flowRecord";
import { apiBaseURL } from "@/utils/request";
  export default {
    data () {
      return {
        userInfoLoading: false,
        index: '1',
        role: 'person',
        showItem: false,
        userInfo: {}
      }
    },
    mounted() {
      if(this.$route.path.indexOf('integral') == -1){
        this.index = '1'
      }else{
        this.index = '2'
      }
      this.getUserInfo()
    },
    methods: {
      async getUserInfo () {
        this.userInfoLoading = true
        const res = await getUserInfo()
        if (res.code === 0) {
          this.userInfo = res.data
          this.userInfo.headUrl = this.userInfo.headUrl ? apiBaseURL + this.userInfo.headUrl : apiBaseURL + '/avator/company.png'
          this.role = res.data.role
        }
        this.userInfoLoading = false
      },
      async signIn () {
        this.userInfoLoading = true
        const res = await signIn()
        if (res.code === 0) {
          this.showItem = true
          this.getUserInfo()
        } else {
          this.$message.error(res.msg)
          this.userInfoLoading = false
        }
      }
    },
    watch:{
      showItem: {
        handler (newValue, oldValue) {
          if(newValue == true){
            setTimeout(()=>{
              this.showItem = false
            },2000)
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .content{
    width: 80%;
    margin: 15px auto;
    @media screen and (max-width: 530px) {
      margin-top: 80px;
      width: 100%;
    }
    /deep/.el-radio__input{
    	display: none;
    }
    .sideBar{
      display: inline-block;
      margin-right: 20px;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        min-height: auto;
      }
      .el-button--text{
        color: #a5b4ff;
        font-size: 12px;
        margin-left: 18px;
      }
      .programmer{
        .person{
          margin-left: 8px;
          @media screen and (max-width: 530px) {
            margin: 0;
          }
        }
        .img-wrapper,.person-msg{
          //display: inline-block;
          text-align: center;
          @media screen and (max-width: 530px) {
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            text-align: left;
          }
        }
        img{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          @media screen and (max-width: 530px) {
            width: 80px;
            height: 80px;
          }
        }
        .name{
          font-size: 16px;
          font-weight: 700;
          margin: 10px 0;
          margin-right: 15px;
          @media screen and (max-width: 530px) {
            font-size: 20px;
          }
        }
        .balance{
          text-align: center;
          margin-top: 15px;
          @media screen and (max-width: 530px) {
            text-align: left;
          }
          .title{
            font-size: 14px;
            color: #acacac;
          }
          .num{
            font-size: 26px;
            font-weight: 700;
            margin: 15px 0;
          }
          .withdraw{
            @media screen and (max-width: 530px) {
              float: right;
              margin-top: -50px;
            }
          }
        }
      }
      .company{
        text-align: center;
        @media screen and (max-width: 530px) {
          text-align: left;
        }
        img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
          @media screen and (max-width: 530px) {
            margin-right: 15px;
          }
        }
        .companyMsg{
          @media screen and (max-width: 530px) {
            display: inline-block;
            vertical-align: top;
            margin-top: 12px;
          }
        }
        .name{
          font-size: 16px;
          font-weight: 700;
          margin: 10px 0;
          @media screen and (max-width: 530px) {
            font-size: 20px;
          }
        }
      }
      .jifen .icon-jifen{
        color: #ffb500;
        font-size: 24px;
        vertical-align: middle;
      }

      .renzheng{
        font-size: 12px;
        padding: 2px 5px;
        border: 1px solid #7B62F7;
        color: #7B62F7;
        border-radius: 3px;
      }
    }
    .personal{
      background-color: #fff;
      border-radius: 3px;
      padding: 15px 0;
      width: 260px;
      // height: 230px;
      margin-bottom: 20px;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        padding: 20px;
      }
      .jifen{

        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
    .left-menu{
      background-color: #fff;
      border-radius: 3px;
      padding: 15px 0;
      width: 260px;
      height: 200px;
      text-align: center;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
      }
      .el-menu{
        border: none;
        .el-menu-item{
          height: 42px;
          line-height: 42px;
          // font-size: 16px;
          @media screen and (max-width: 530px) {
            display: inline-block;
            color: #8d8d8d;
            font-size: 18px;
          }
          &:hover{
            cursor: pointer;
            background-color: rgba(121, 95, 246, .05);
            @media screen and (max-width: 530px) {
              background-color: transparent;
              color: #4d4d4d;
            }
          }
          &.is-active{
            background-color: #f9f8ff;
            border-left: 2px solid #795FF6;
            color: #795FF6;
            @media screen and (max-width: 530px) {
              border: none;
              color: #3d3d3d;
              background-color: #fff;
            }
          }
          .iconfont{
            margin-right: 20px;
            @media screen and (max-width: 530px) {
              display: none;
            }
          }
        }
      }
    }
    .wrapper{
      display: inline-block;
      width: calc(100% - 280px);
      vertical-align: top;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .qiandao-wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
    top: 0;
    background-color: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .qiandao-img{
      width:300px;
      height: 260px;
      margin: 0 auto;
      background-image: url(../../assets/images/qiandao.png);
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      position: relative;
      letter-spacing: 1px;
      .success{
        font-size: 18px;
        font-weight: 700;
        margin-top: 80px;
        text-shadow: 3px 3px 3px rgba(113,103,247,.5);
      }
      .jifen{
        font-size: 14px;
        font-weight: 700;
        margin-top: 30px;
        text-shadow: 3px 3px 3px rgba(113,103,247,.5);
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        .num{
          font-size: 22px;
        }
      }
      .tip{
        position: absolute;
        bottom: 25px;
        left: 50%;
        margin-left: -48px;
        color: #adadad;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }
</style>
