<template>
  <div class="work-detail-wrapper">
    <div class="work-content">
      <el-row>
        <el-col :span="18" class="images">
          <div class="title">{{ item.title }}</div>
          <div class="work-imgs" v-for="image in item.imageList">
            <img :src="image" >
          </div>
        </el-col>
        <el-col :span="6">
          <div class="works">
            <i class="iconfont icon-guanbi" @click="close"></i>
            <div class="work-desc">
              <div class="title">作品描述</div>
              <div class="description">
                {{ item.content }}
              </div>
            </div>

            <div class="skill" v-if="tagArr.length > 0">
              <div class="title">技能标签</div>
              <div class="tags">
                <div class="tag" v-for="(item,index) in tagArr">{{item}}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {apiBaseURL} from "@/utils/request";

  export default{
    props: {
      item: Object
    },
    data(){
      return {
        form:{
          tagName: ''
        },
        tagArr:['php','javascript']
      }
    },
    mounted() {
      window.addEventListener('keydown',this.keyDown)
      this.tagArr = this.item.skills.split('、')
      for (var i = 0; i < this.item.imageList.length; i++) {
        this.item.imageList[i] = apiBaseURL + this.item.imageList[i]
      }
    },
    destroyed(){
      window.removeEventListener('keydown',this.keyDown,false)
    },
    methods:{
      keyDown(e){
        //如果是回车则执行登录方法
        if(e.keyCode == 13){
          this.tagArr.push(this.form.tagName)
          this.form.tagName = ''
        }
      },
      tagDelete(index) {
        this.tagArr.splice(index,1)
      },
      close(){
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss">
  .work-detail-wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .work-content{
      background-color: #fff;
      border-radius: 8px;
      width: 70%;
      height: 80vh;
      padding: 20px;
      .title{
        margin: 15px 0;
        font-size: 22px;
        font-weight: 700;
      }
      .el-col{
        padding-left: 20px;
      }
      .images{
        border-right: 1px solid #dcdcdc;
        height: calc(80vh - 20px);
        overflow: auto;
        .work-imgs{
          margin-top: 20px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
          img{
            width: calc(100% - 30px);
            border-radius: 5px;
          }
        }
      }
    }
    .works{
      overflow: scroll;
      height: calc(80vh - 30px);
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .iconfont{
        font-size: 26px;
        color: #DAD8D8;
        float: right;
        /* margin-top: -25px;
        margin-right: -5px; */
        cursor: pointer;
      }
      .work-desc{
        margin-top: 100px;
        .title{
          font-size: 18px;
        }
        .description{
          line-height: 1.8;
          font-size: 14px;
        }
      }
      .skill{
        margin-top: 30px;
        .title{
          font-size: 18px;
        }
        .tag{
          border: 1px solid #DCDFE6;
          color: #606266;
          line-height: 1;
          border-radius: 30px;
          padding: 3px 12px;
          margin-right: 10px;
          margin-bottom: 15px;
          display: inline-block;
        }
      }
    }
  }
</style>
