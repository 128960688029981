<template>
  <div>
    <ul class="steps">
      <li
          @click="changeTab(index)"
        v-for="(item,index) in SetData"
        :key="item+index"
        :class="{'active':showIndex===index}"
      >{{item}}</li>
    </ul>
  </div>
</template>
<script lang="ts">
var maxIndex = 0
export default{
  props:{
    Steps:{
      type: Number,
      default: 0
    },
    SetData:{
      type: Array,
      default: [],
    }
  },
  data () {
    return {
      showIndex: this.Steps
    }
  },
  mounted() {
    maxIndex = this.Steps
  },
  methods: {
    changeTab (index) {
      if (index > maxIndex) {
        this.$message.warning('尚未到达所选进度')
        return
      }
      this.showIndex = index
    }
  },
  watch: {
    Steps (newVal, oldVal) {
      this.showIndex = newVal
    },
    showIndex (newVal, oldVal) {
      this.$emit('show-change', newVal)
    }
  }
}
</script>

<style lang="scss">
.steps {
  position: relative;
  margin-bottom: 30px;

}
/*步骤描述*/
.steps li {
  list-style-type: none;
  font-size: 14px;
  text-align: center;
  position: relative;
  float: left;
  cursor: pointer;
  width: 20%;
}

/*步骤数字*/
.steps li:before {
  display: block;
  content: "\2022";
  width: 20px;
  height: 20px;
  background-color: #a068ff;
  line-height: 22px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0 auto 8px auto;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 530px) {
    line-height: 21px;
  }
}

/*连接线*/
.steps li ~ li:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #a068ff;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 1; /*放置在数字后面*/
}

/*将当前/完成步骤之前的数字及连接线变绿*/
.steps li.active:before,
.steps li.active:after {
  background-color: #a068ff;
}

/*将当前/完成步骤之后的数字及连接线变灰*/
.steps li.active ~ li:before,
.steps li.active ~ li:after {
  background-color: #f3f1ff;
  content: "";
}
</style>
