import request from '@/utils/request'

export function selectProductList (data) {
  return request({
    url: '/product/selectProductList',
    method: 'POST',
    data
  })
}
// 获取用户地址
export function getAddressList (data) {
  return request({
    url: '/product/getDeliveryInformation',
    method: 'POST',
    data
  })
}
// 添加收货地址
export function addAddress (data) {
  return request({
    url: '/product/saveDeliveryInformation',
    method: 'POST',
    data
  })
}
// 删除收货地址
export function deteleAddress (data) {
  return request({
    url: '/product/deleteDeliveryInformation',
    method: 'POST',
    data
  })
}
// 兑换
export function exchangeProduct (data) {
  return request({
    url: '/product/exchangeProductList',
    method: 'POST',
    data
  })
}
// 兑换记录
export function getExchangeRecords (data) {
  return request({
    url: '/product/getExchangeRecords',
    method: 'POST',
    data
  })
}
// 获取用户积分
export function getUserIntegral (data) {
  return request({
    url: '/product/getUserIntegral',
    method: 'POST',
    data
  })
}
