<template>
  <div class="container">
    <div class="title">提现</div>
    <div class="form-wrapper">
      <el-form ref="form" :model="form" label-width="90px">
<!--        <el-form-item label="可提现金额:">-->
<!--          <span class="num">2000.00</span>元-->
<!--        </el-form-item>-->
        <el-form-item label="支付宝账号:">
          <el-input v-model="form.alipayAccount" size="mini" disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="提现金额:">
          <el-input v-model="form.amount" size="mini"></el-input>
<!--          <el-button type="text" class="withdrawal">全部提现</el-button>-->
        </el-form-item>
        <el-form-item label="提示:">
          <div class="tips">单笔提现金额最低10元，最高50000元。</div>
          <div class="tips">到账时间是24小时以内，如提现账户不对不退余额。</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit">立即提现</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { applyWithdraw, getAliPayAccountByUserId } from "@/api/withdrawal/withdrawal";

  export default {
    data () {
      return {
        form: {}
      }
    },
    mounted() {
    this.viewGetAliPayAccountByUserId()
    },
    methods: {
      onSubmit(){
        if(this.form.alipayAccount === '' || this.form.alipayAccount === null){
          this.$message.error('请先完善个人信息中的支付宝账号')
        }else {
        this.viewApplyWithdraw()
        }
      },
      async viewApplyWithdraw(){
        const res = await applyWithdraw({'amount': this.form.amount })
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      },
      async viewGetAliPayAccountByUserId(){
        const res = await getAliPayAccountByUserId()
        if (res.code === 0) {
         this.form.alipayAccount = res.msg
          this.$forceUpdate()
        } else {
          this.$message.error('获取账号异常')
        }
      }


    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .form-wrapper{
      width: 450px;
      margin: 0 auto;
      @media screen and (max-width: 530px) {
        width: 100%;
      }
    }
    .el-input{
      width: 220px;
    }
    .num{
      font-size: 24px;
      font-weight: 700;
    }
    .withdrawal{
      color:#795FF6;
      margin-left: 20px;
    }
    .tips{
      color: #8d8d8d;
    }
  }
</style>
