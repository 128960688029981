<template>
  <div class="container" v-loading="loading">
    <div class="content">
      <div class="header">
        <span class="title">答题认证得积分</span>
        <!-- <span class="tip"><span class="required">*</span>提示：偏远地区暂不支持发货，请联系客服解决</span> -->
      </div>
      <el-form :model="form" ref="ruleForm" class="questionForm">
        <el-form-item v-for="(item,index) in dataList" :label="index+1">
          <div class="question-name">{{item.problem}}</div>
          <el-radio-group v-model="item.options">
            <el-radio label="a">A. {{item.a}}</el-radio>
            <el-radio label="b">B. {{item.b}}</el-radio>
            <el-radio label="c">C. {{item.c}}</el-radio>
            <el-radio label="d">D. {{item.d}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div class="btn-group">
        <el-button size="medium" style="width: 100px;margin: 20px 0;" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
    <div class="dialog" v-if="showDialog"  @click=" showDialog = false">
      <div class="content">
        <i class="iconfont icon-guanbi2fill" @click=" showDialog = false"></i>
        <div class="success" v-if="score>=60">
          <div class="score-wrapper">
            <div>您的得分是：</div>
            <div style="color: #795eff;"><span class="score">{{score}}</span><span>分</span></div>
          </div>
          <div class="state">恭喜您通过认证</div>
          <div style="font-size: 14px;">本次答题可获得积分 + 50</div>
<!--          <div class="btn">-->
<!--            <el-button type="primary" size="small">查看答案</el-button>-->
<!--          </div>-->
        </div>
        <div class="failed" v-else>
          <div class="score-wrapper">
            <div>您的得分是：</div>
            <div style="color: #795eff;"><span class="score">{{score}}</span><span>分</span></div>
          </div>
          <div class="state">很遗憾未能通过认证</div>
          <div style="font-size: 14px;">初次答题60分以上，可获得积分或余额奖励</div>
          <div class="btn">
            <el-button type="primary" size="small">重新答题</el-button>
<!--            <el-button type="primary" size="small">查看答案</el-button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getTopic,answerQuestions} from "@/api/answer";
  export default{
    data(){
      return {
        form:{
          type:[],
          project: '0'
        },
        ids: [],
        dataList: [],
        score: 0,
        showDialog: false,
        loading: false
      }
    },
    mounted() {
      this.ids = JSON.parse(this.$route.query.ids)
      this.getList()
    },
    methods:{
      async getList(){
        this.loading = true
        const res = await getTopic("typeList="+ this.ids)
        this.loading = false
        if(res.code == 0){
          this.dataList = res.data
        }else{
          this.$message.error(res.msg)
        }
      },
      async submit(){
        const res = await answerQuestions({list: this.dataList})
        if(res.code == 0){
          this.$message.success(res.msg)
          this.showDialog = true
          this.score = res.count
        }else if(res.code == 101){
          this.$message.error(res.msg)
          this.showDialog = true
          this.score = res.data
        }else{
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.distpicker-address-wrapper select{
    padding: 5px 15px;
    height: 36px;
    font-size: 14px;
    border-radius: 4px;
  }
  .content{
    background-color: #fff;
    border-radius: 3px;
    margin: 90px auto 20px;
    width: 80%;
    padding: 30px 60px;
    @media screen and (max-width: 530px) {
      width: 100%;
      // min-height: calc(100vh - 70px);
    }
    .title{
      font-size: 24px;
      font-weight: 700;
      margin: 20px 0 30px;
    }
   /* .tip{
      font-size: 16px;
      font-weight: 700;
      margin: 20px 25px;
    } */
    .questionForm{

    }
    .el-form-item{
      padding: 10px 0;
      border-bottom: 1px solid #EDEDED;
      &:last-child{
        border: none;
      }
      .question-name{
        font-size: 16px;
      }
      /deep/.el-form-item__label{
        background-color: #A068FF;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        padding: 0;
        font-weight: 500;
        margin-top: 12px;
      }
      /deep/.el-form-item__content{
        padding-left: 24px;
      }
    }
    .el-radio{
      display: block;
      margin: 8px 0;
    }
    /deep/.el-radio__input.is-checked .el-radio__inner {
        border-color: #A068FF;
        background: #fff;
    }
    .el-radio__inner:hover {
        border-color: #A068FF;
    }
    /deep/.el-radio__inner::after{
      background-color: #A068FF;
      width: 6px;
      height: 6px;
    }
    .btn-group{
      text-align: center;
    }
  }
  .dialog{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .content{
      width: 28%;
      min-width: 240px;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      @media screen and (max-width: 530px) {
        width: 80%;
      }
      div{
        margin: 15px 0;
      }
      .score-wrapper{
        font-size: 14px;
        margin: 30px auto 0;
        text-align: center;
        .score{
          font-size: 34px;
          font-weight: 700;
        }
      }
      .iconfont{
        color: #c7c7c7;
        font-size: 20px;
        float: right;
        cursor: pointer;
      }
      .state{
        font-size: 14px;
        margin: 20px 0;
      }
      .btn{
        text-align: center;
        margin: 40px 0;
        .el-button{
          width: 100px;
          margin: 0 15px;
        }
      }
    }
  }
</style>
