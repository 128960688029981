<template>
  <div class="work-detail" v-loading="loading">
    <div class="work-content">
      <div class="title">{{ workItem.title }}</div>
      <div class="works">
        <div class="skill" v-if="tagArr.length > 0">
          <div class="tags">
            <div class="tag" v-for="(item,index) in tagArr">{{item}}</div>
          </div>
        </div>
        <div class="work-desc">
          <div class="description">
            {{ workItem.content }}
          </div>
        </div>
      </div>
      <div class="work-imgs" v-for="image in workItem.imageList">
        <img :src="image" >
      </div>
    </div>
  </div>
</template>

<script>
  import {apiBaseURL} from "@/utils/request";
  import {fetchWorksById} from "@/api/personnel";
  export default{
    data(){
      return {
        id: '',
        loading: false,
        workItem: {},
        tagArr:['php','javascript']
      }
    },
    mounted() {
      console.log(this.$route)
      this.id = this.$route.params.id
      console.log(this.id)
      this.getData()
    },
    methods:{
      async getData(){
        this.loading = true
        const res = await fetchWorksById({ id: this.id })
        this.workItem = res.data
        this.tagArr = this.workItem.skills.split('、')
        for (var i = 0; i < this.workItem.imageList.length; i++) {
          this.workItem.imageList[i] = apiBaseURL + this.workItem.imageList[i]
        }
        this.loading = false
      }
    }
  }
</script>

<style lang="scss">
  .work-detail{
    background-color: #fff;
    width: 100%;
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    padding: 30px;
    .work-content{
      width: 100%;
      border-radius: 8px;
      .title{
        margin: 15px 0;
        font-size: 22px;
        font-weight: 700;
      }

        .work-imgs{
          margin-top: 15px;
          width: 100%;
          img{
            width: 100%;
          }
        }
    }
    .works{
      .work-desc{
        .description{
          line-height: 1.8;
          font-size: 14px;
        }
      }
      .skill{
        margin-top: 20px;
        .tag{
          border: 1px solid #DCDFE6;
          color: #606266;
          line-height: 1;
          border-radius: 30px;
          padding: 3px 12px;
          margin-right: 10px;
          margin-bottom: 15px;
          display: inline-block;
        }
      }
    }
  }
</style>
