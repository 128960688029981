<template>
  <div class="container">
    <div class="swiper">
      <img src="../../../assets/images/bg.png" >

    </div>
    <div class="content" v-loading="loading">
      <div class="project">

            <div class="company">
              <div class="company-logo">
                <img :src="info.headUrl" >
              </div>
              <div class="company-name">{{ info.userName ? info.userName : info.companyName }}</div>
              <div class="renzheng">
                <span v-if="info.personAuth === 1 || (info.companyAuth === 1 && info.role === 'company')">已认证</span>
                <img v-if="info.companyAuth === 1 && info.role === 'company'" src="@/assets/images/vips.png" >
              </div>
            </div>

            <div class="project-content">
              <div class="project-title">{{ info.title }}</div>
              <div class="types">
                <span>项目类型：{{ info.demandTypeTaskList }}</span>
                <span class="space">|</span>
                <span>预计周期：{{ info.cycle.replaceAll('年', '/').replaceAll('月', '/').replaceAll('日', '') }}</span>
                <!--                  <span class="space">|</span>-->
                <!--                  <span>经验要求：3~5年</span>-->
              </div>
              <div>
                <span>项目预算：</span>
                <span class="price" v-if="info.revenueAmount !== info.paymentAmount">{{ info.revenueAmount }} ~ {{ info.paymentAmount }}元</span>
                <span class="price" v-if="info.revenueAmount === info.paymentAmount">{{ info.paymentAmount }}元</span>
                <span class="jianli" v-if="info.pmName">项目监理：{{ info.pmName }}</span>
              </div>
              <div class="">
                <span class="publish-date">发布时间：{{ info.createDate }}</span>
<!--                <span style="float: right;margin-top: -8px;">已有<span style="font-size: 24px;margin: 0 3px;">{{ info.recruitCount ? info.recruitCount : 0 }}</span>人投递该任务</span>-->
              </div>
            </div>

      </div>
      <div class="demand">
        <div class="title">需求描述</div>
        <div class="desc">{{ info.details }}</div>
        <div class="fujian" v-if="info.appendixUrl">
          <span class="title">任务附件</span>
          <span class="files">
            <span class="filename">文档.{{ info.appendixUrl.split('.')[1] }}</span>
            <el-button type="text" class="download" @click="downloadDoc"><i class="iconfont icon-xiazai5"></i>点击下载</el-button>
          </span>
        </div>

      </div>
      <div class="licheng">
        <div :class="index === milIndex ? 'item active' : 'item'" :id="mil.id" v-for="(mil, index) in info.milestoneList" @click="changeMil(mil.id, index)">
          <div class="title">{{ mil.name }}</div>
          <div class="actived"></div>
        </div>
      </div>
      <div class="schedule" v-loading="loading">
        <Steps v-if="!loading" :Steps="registSpets" :SetData="SetData" @show-change="showChange" />
        <!-- 审核状态 -->
        <div class="status-content check" v-if="registSpets == 0">
          <div class="title"><img src="../../../assets/images/status-icon.png" >审核</div>
          <div class="status">
            <!--            <i class="icon el-icon-warning"></i>-->
            <i style="color: #A068FF" class="icon el-icon-success"></i>
            <span>审核状态：已审核</span>
          </div>
          <div class="checkMsg">
            <span>审核日期：{{ info.createDate }}</span>
          </div>
        </div>
        <!-- 招募状态 -->
        <div class="status-content enlist" v-else-if="registSpets == 1" v-loading="progressLoading">
          <div class="title"><img src="../../../assets/images/status-icon.png" >招募</div>
          <div class="status">
            <i class="icon el-icon-warning" v-if="realProgress === '1'"></i>
            <i style="color: #A068FF" class="icon el-icon-success" v-else></i>
            <span v-if="realProgress === '1'">招募状态：招募中</span>
            <span v-if="realProgress === '2'">招募状态：招募完成</span>
          </div>
          <div class="zhaomuDate">
            <span>招募时间：{{ info.createDate }}</span>
          </div>
          <div class="zhaomuList">
            <ul  class="personnnel-list">
             <li class="personnnel-item" v-for="user in enrolledArray">
               <el-row>
                 <el-col :span="3">
                   <div class="personnel">
                     <div class="person-img">
                       <img :src="user.headUrl">
                     </div>
                     <span class="renzheng" v-if="user.personalAuthentication === 1">已认证</span>
                   </div>
                 </el-col>
                 <el-col :span="16">
                   <div class="personnnel-content">
                     <div class="personnnel-title">
                       <span class="name">{{ user.nickname }}</span>
                       <span class="job">{{ user.position }}</span>
                     </div>
                     <div class="exprience">
                       <span >擅长领域：{{ user.demandList }}</span> |
                       <span >工作经验：{{ user.workExperience }}年</span>
                     </div>
                     <div class="demand">
                       个人简介：{{ user.describe }}
                     </div>
                   </div>
                 </el-col>

                 <el-col :span="5">
                   <div class="recruit">
                     <div class="price"><span>{{ user.dailySalary }}</span>元/天</div>
                     <div class="btn recruited" v-if="user.state==1">
                       已选择
                     </div>
                   </div>
                 </el-col>
               </el-row>
             </li>
           </ul>
            <ul class="mobile-list">
              <li class="personnnel-item" v-for="user in enrolledArray">
                <div class="personnel">
                  <div class="person-img">
                    <img :src="user.headUrl">
                  </div>
                  <div class="personnnel-content">
                    <div class="personnnel-title">
                      <span class="name">{{ user.nickname }}</span>
                      <span class="job">{{ user.position }}</span>
                      <span class="renzheng" v-if="user.personalAuthentication === 1">已认证</span>
                    </div>
                    <div class="exprience">
                      <span class="price"><span>{{ user.dailySalary }}</span>元/天</span>
                      <span style="display: inline-block;vertical-align: text-top;max-width: 120px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ user.demandList }}</span> |
                      <span >{{ user.workExperience }}年</span>
                    </div>
                   </div>

                </div>

                <div class="demand">
                  个人简介：{{ user.describe }}
                </div>
                <div class="recruit">
                  <div style="cursor: pointer" class="btn zhaomu" v-if="user.state==0" v-show="realProgress === '1'" @click="confirmUser(user.id)">
                    <i class="iconfont icon-zhaomu"></i>
                    选择
                  </div>
                  <div class="btn recruited" v-else>
                    已选择
                  </div>

                </div>

              </li>
            </ul>
          </div>

        </div>
        <!-- 支付状态 -->
        <div class="status-content pay" v-if="registSpets == 2">
          <div class="title"><img src="../../../assets/images/status-icon.png" >支付</div>
          <div class="status">
            <i class="icon el-icon-warning" v-if="info.milestoneList[milIndex].state === -1"></i>
            <i style="color: #A068FF" class="icon el-icon-success" v-if="info.milestoneList[milIndex].state === 2 || info.milestoneList[milIndex].state === 1"></i>
            <span v-show="info.milestoneList[milIndex].state === 2 || info.milestoneList[milIndex].state === 1">支付状态：已支付</span>
            <span v-show="info.milestoneList[milIndex].state === -1">支付状态：待支付</span>
          </div>
          <div class="checkMsg">
            <span>里程碑：{{ info.milestoneList[milIndex].name }}</span>
            <span>金额：{{ info.milestoneList[milIndex].money }}元</span>
            <div class="isEnd" v-if="info.milestoneList[milIndex].state === 2 || info.milestoneList[milIndex].state === 1">
              <img src="../../../assets/images/isEnd.png" >
              <div class="finish">发布者已完成支付</div>
            </div>
            <!-- 未支付 -->
            <div class="unpaid" v-if="info.milestoneList[milIndex].state === -1">
              <div style="margin-top: 20px">
                发布者尚未对里程碑完成付款，请等待发布者付款
              </div>
            </div>
          </div>
        </div>
        <!-- 进行状态 -->
        <div class="status-content conduct" v-if="registSpets == 3" v-loading="uploadLoading">
          <div class="title"><img src="../../../assets/images/status-icon.png" >进行</div>
          <div class="status">
            <i class="icon el-icon-warning" v-if="info.milestoneList[milIndex].isCommit === 0"></i>
            <i style="color: #A068FF" class="icon el-icon-success" v-else></i>
            <span v-show="info.milestoneList[milIndex].isCommit === 0">里程碑状态：进行中</span>
            <span v-show="info.milestoneList[milIndex].isCommit === 1 && info.milestoneList[milIndex].state === 2">里程碑状态：交付待确认</span>
            <span v-show="info.milestoneList[milIndex].isCommit === 1 && info.milestoneList[milIndex].state === 1">交付状态：已完成</span>
          </div>
          <div class="projectDate">截止日期：{{ info.milestoneList[milIndex].endDate.substr(0,10) }}</div>
          <div class="project-desc">
            <span class="sub-title" style="vertical-align: top;">阶段描述：</span>
            <el-input type="textarea" v-model="info.milestoneList[milIndex].description" :rows="5" readonly style="width: calc(100% - 80px);border-radius:2px;"></el-input>
          </div>
          <div class="deliver">
            <span class="sub-title">需求文档：</span>
            <div style="display: inline-block;">
              <span class="files">
                <span class="filename">需求文档.{{ info.milestoneList[milIndex].demandAppendixUrl.split('.')[1] }}</span>
              </span>
            </div>
            <el-button type="text" class="download" @click="downloadDeliveredDoc(info.milestoneList[milIndex].demandAppendixUrl)"><i class="iconfont icon-xiazai5"></i>点击下载</el-button>
          </div>
          <div class="deliver">
            <span class="sub-title">交付文档：</span>
            <div style="display: inline-block;">
              <span class="files" v-if="info.milestoneList[milIndex].deliverAppendixUrl">
                <span class="filename">交付文档.{{ info.milestoneList[milIndex].deliverAppendixUrl.split('.')[1] }}</span>
              </span>
              <span class="files" v-else>
                <span class="filename">{{ deliverAppendixFilename }}</span>
              </span>
            </div>
            <el-upload style="display: inline-block"
                       v-if="info.milestoneList[milIndex].isCommit === 0"
                       :action="uploadUrl"
                       :data="{type: 1}"
                       :with-credentials="true"
                       :headers="headerObj"
                       :show-file-list="false"
                       :on-success="uploadSuccess"
                       :on-progress="uploadProgress"
            >
              <el-button type="text" class="upload" v-if="info.milestoneList[milIndex].isCommit === 0"><i class="iconfont icon-shangchuan"></i>点击上传交付文档</el-button>
            </el-upload>
            <el-button type="text" class="download" v-if="info.milestoneList[milIndex].isCommit === 1" @click="downloadDeliveredDoc(info.milestoneList[milIndex].deliverAppendixUrl)"><i class="iconfont icon-xiazai5"></i>点击下载</el-button>
          </div>
          <el-button v-if="info.milestoneList[milIndex].isCommit === 0" type="primary" size="mini" style="display: block;margin: 20px auto;" @click="confirmMilestonedialogVisible = true">里程碑交付</el-button>
        </div>
        <!-- 完成状态 -->
        <div class="status-content accomplish" v-if="registSpets == 4">
          <div class="title"><img src="../../../assets/images/status-icon.png" >完成</div>
          <div class="status">
            <i class="icon el-icon-warning" v-if="info.milestoneList[milIndex].state === 0 || info.milestoneList[milIndex].state === -1"></i>
            <i style="color: #A068FF" class="icon el-icon-success" v-else></i>
            <span>里程碑状态：{{ info.milestoneList[milIndex].state === 1 ? '已完成' : '未完成' }}</span>
          </div>
          <!--          <div class="projectDate">完成日期：2022/01/26</div>-->
          <div class="isEnd" v-if="info.milestoneList[milIndex].state === 1">
            <img src="../../../assets/images/isEnd.png" >
            <div class="finish">里程碑已完成</div>
          </div>
        </div>
      </div>

      <div class="evaluate-wrapper" v-if="info.progress == 5">
        <div class="title">开发者评价</div>
        <div v-if="commentArr.length>0">
          <div class="evaluate-item" v-for="item in commentArr">
            <el-row>
              <el-col :span="3">
                <div class="company-img">
                  <img src="@/assets/images/company.png" >
                </div>
              </el-col>
              <el-col :span="21">
                <div class="company-evaluate">
                  <div class="company">
                    <span class="name">{{item.developName}}</span>
                    <img src="@/assets/images/vips.png" >
                  </div>
                  <div class="score">
                    <span >时间：{{item.commentDate}}</span>
                    <el-rate v-model="item.score/2" disabled text-color="#ffd161" allow-half></el-rate>
                  </div>
                  <div class="desc">{{item.comment}}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="noData" v-else>
          暂无评论
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="confirmMilestonedialogVisible"
      :width="isMobile?'80%':'40%'">
      <span>您确认将当前里程碑结果交付于发布者吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmMilestonedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCommit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Steps from "@/components/steps";
import {fetchTaskInfo,fetchEnrolled,saveRecruit,commentFetch,deliver,deliverFile} from "@/api/task/task";
import {apiBaseURL} from "@/utils/request";
import {getToken} from "@/utils/auth";
import { _isMobile } from '@/utils/index';
export default {
  components: {
    Steps
  },
  data () {
    return {
      loading: false,
      isMobile: false,
      commentVisible: false,
      accountVisible: false,
      form:{},
      info: {},
      companyList: [],
      commentArr: [],
      companyAccount: '',
      role: 'person',
      payType: '1',
      desc: '',
      success: true,
      status:0,
      active: 3,
      registSpets: 0,
      SetData: ["审核", "招募", "支付", "进行", "完成"],
      SetData2: [ "招募", "进行", "完成"],
      realProgress: '',
      enrolledArray: [],
      progressLoading: false,
      milId: '',
      milIndex: 0,
      confirmMilestonedialogVisible: false,
      isEnrolled: true,
      enrollDialogVisible: false,
      uploadUrl: apiBaseURL + '/file/upload',
      headerObj: { token: getToken() },
      deliverAppendixFilename: '暂未上传交付文档',
      commitDeliverForm: {
        demandAppendixUrl: '',
        isCommit: 1
      },
      uploadLoading: false
    }
  },
  methods: {
    async getDetail (id) {
      this.loading = true
      const res = await fetchTaskInfo({ id: id, work: 1 })
      if (res.code === 0) {
        if (res.data.length === 0) {
          this.$message.error('该任务不存在或未通过审核')
          return
        }
        this.info = res.data[0]
        this.info.demandTypeTaskList = this.info.demandTypeTaskList.join(' ')
        this.realProgress = res.data[0].progress + ''
        this.milId = res.data[0].milestoneList[0].id
        if (res.data[0].milestoneList[0].state === 2) {
          this.registSpets = 3
        } else if (res.data[0].milestoneList[0].state === 1) {
          this.registSpets = 4
        } else {
          this.registSpets = res.data[0].progress
        }
        this.info.headUrl = this.info.headUrl ? apiBaseURL + this.info.headUrl : apiBaseURL + '/avator/company.png'
        if (this.realProgress === '1' || this.realProgress === '2') {
          this.fetchEnrolled()
        }
      } else {
        this.$message.error(res.msg)
        return
      }
      if(this.info.progress == 5){
        this.getComment()
      }
      this.loading = false
    },
    // 获取开发者评论
    async getComment(){
      const res = await commentFetch('taskId='+ this.$route.params.id)
      if (res.code === 0) {
        console.log(res)
        this.commentArr = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    downloadDoc () {
      window.open(apiBaseURL + this.info.appendixUrl, '_blank')
    },
    async fetchEnrolled () {
      this.progressLoading = true
      const res = await fetchEnrolled({ id: this.$route.params.id })
      for (var i = 0; i < res.data.list.length; i++) {
        res.data.list[i].headUrl = res.data.list[i].headUrl ? apiBaseURL + res.data.list[i].headUrl : apiBaseURL + '/avator/company.png'
        res.data.list[i].demandList = res.data.list[i].demandList.join(' ')
      }
      this.enrolledArray = res.data.list
      this.success = res.data.isSelected
      this.progressLoading = false
      this.isEnrolled = res.data.isEnrolled
    },
    changeMil (id, index) {
      this.loading = true
      var _this = this
      setTimeout(function () {
        _this.milId = id
        _this.milIndex = index
        if (_this.info.milestoneList[index].state === 2) {
          _this.registSpets = 3
        } else if (_this.info.milestoneList[index].state === 1) {
          _this.registSpets = 4
        } else if (_this.info.milestoneList[index].state === -1) {
          _this.registSpets = 2
        }
        _this.loading = false
      }, 500)
    },
    async payNow () {
      console.log('支付里程碑ID：' + this.milId)
      let url = apiBaseURL + '/order/saveOrder?milestoneId=' + this.milId + '&token=' + getToken() + '&returnUrl=' + encodeURIComponent(window.location.href)
      window.open(url, '_blank')
    },
    downloadDeliveredDoc (file) {
      window.open(apiBaseURL + file, '_blank')
    },
    async confirmCommit () {
      if (!this.commitDeliverForm.demandAppendixUrl) {
        this.confirmMilestonedialogVisible = false
        this.$message.warning('未上传交付文档，里程碑无法交付')
        return
      }
      this.loading = true
      this.confirmMilestonedialogVisible = false
      const res = await deliver({ id: this.milId, demandAppendixUrl: this.commitDeliverForm.demandAppendixUrl })
      if (res.code === 0) {
        this.$message.success('里程碑成功提交交付申请！')
      } else {
        this.$message.error(res.msg)
      }
      this.getDetail(this.$route.params.id)
    },
    async enroll () {
      this.progressLoading = true
      this.enrollDialogVisible = false
      const res = await saveRecruit({ taskId: this.$route.params.id })
      if (res.code === 0) {
        this.$message.success('恭喜您，报名成功！')
        this.fetchEnrolled()
      } else {
        this.$message.error(res.msg)
      }
    },
    showChange (val) {
      this.registSpets = val
      if (val === 1) {
        if (parseInt(this.realProgress) >= 2) {
          this.realProgress = '2'
        } else if (parseInt(this.realProgress) > 1) {
          this.realProgress = '1'
        }
        this.fetchEnrolled()
      }
    },
    async uploadSuccess (response, file, fileList) {
      const res = await deliverFile({ id: this.milId, demandAppendixUrl: response.msg })
      if (res.code === 0) {
        this.$message.success('交付文档上传成功！')
        this.commitDeliverForm.demandAppendixUrl = response.msg
        this.deliverAppendixFilename = '交付文档.' + response.msg.split('.')[1]
      } else {
        this.$message.error('交付文档上传失败！')
      }
      this.uploadLoading = false
    },
    uploadProgress (event, file, fileList) {
      this.uploadLoading = true
    }
  },
  mounted () {
    if (_isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    let id = this.$route.params.id
    this.getDetail(id)
  }
}
</script>

<style lang="scss" scoped>
.swiper{
  position: relative;
  @media screen and (max-width: 530px) {
    display: none;
  }
  img{
    width: 100%;
    height: 320px;
  }
}
.content{
  width: 80%;
  margin: 10px auto;
  @media screen and (max-width: 530px) {
    width: 100%;
    min-height: calc(100vh - 70px);
    margin-top:80px;
  }
  .project{
    background-color: #fff;
    border-radius: 3px;
    font-size: 16px;
    padding: 30px 30px 20px;
    margin-top: 15px;
    .project-content{
      color: #5c5c5c;
      display: inline-block;
      line-height: 1.65;
      @media screen and (max-width: 530px) {
        width: calc(100% - 95px);
      }
      div{
        margin-bottom: 12px;
      }
      .project-title{
        font-size: 20px;
        font-weight: 700;
        color: #3d3d3d;
        line-height: 1.25;
      }
      .types{
        span{
          @media screen and (max-width: 530px) {
            display: block;
            margin-top: 10px;
          }
          &.space{
            color: #cdcdcd;
            margin: 0 20px;
            @media screen and (max-width: 530px) {
              display: none;
            }
          }
        }
      }
      .jianli{
        margin-left: 90px;
        @media screen and (max-width: 530px) {
          margin: 0;
          display: block;
        }
      }
      .publish-state{
        margin-top: 5px;
        color: #8d8d8d;
        .publish-date{
          margin-right: 100px;
        }
      }
      .price{
        color: #7B62F7;
        font-size: 20px;
        font-weight: 700;
      }
      .exprience{
        font-size: 14px;
        margin-left: 30px;
        color: #8d8d8d;
        span{
          margin: 0 10px;
        }
      }
      .demand{
          overflow:hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
      }
      .tags .tag{
        background-color: #eaeaec;
        font-size: 14px;
        padding: 4px 12px;
        border-radius: 20px;
        margin-right: 10px;
      }
    }
    .company{
      width: 120px;
      text-align: center;
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
      @media screen and (max-width: 530px) {
        width: 80px;
      }
      div{
        margin: 0 auto 12px;
      }
      .company-name{
        font-weight: 700;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .company-logo{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        @media screen and (max-width: 530px) {
          width: 60px;
          height: 60px;
        }
        img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .renzheng{
        span{
          font-size: 12px;
          padding: 2px 5px;
          border: 1px solid #7B62F7;
          color: #7B62F7;
          border-radius: 3px;
        }
        img{
          width: 16px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
  .demand{
    background-color: #fff;
    margin: 10px 0;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
    }
    .desc{
      color: #6d6d6d;
      font-size: 14px;
      margin: 15px 0;
      line-height: 1.6;
      @media screen and (max-width: 530px) {
        font-size: 16px;
      }
    }
    .fujian{
      color: #6d6d6d;
      font-size: 14px;
      @media screen and (max-width: 530px) {
        font-size: 16px;
      }
      .title{
        font-size: 14px;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
      .files{
        margin-left: 25px;
      }
      .download{
        color: #795FF6;
        margin-left: 8px;
        &:hover{
          opacity: .78;
        }
        .iconfont{
          font-size: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

  }
  .licheng{
    background-color: #fff;
    margin: 10px 0;
    padding: 30px;
    font-size: 14px;
    .item {
      float: left;
      margin-right: 20px;
      cursor: pointer;
    }
    .active {
      padding-bottom: 10px;
      border-bottom: 2px solid #A068FF;
    }
  }
  .schedule,.schedule2{
    background-color: #fff;
    margin: 10px 0;
    padding: 20px;
    min-height: 150px;

    .status-content{
      width: 90%;
      position: relative;
      margin: 100px auto 30px;
      padding: 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 8px rgba(160,104,255,.08),0 -8px 8px rgba(160,104,255,.08),
       8px 0 8px rgba(160,104,255,.08),-8px 0 8px rgba(160,104,255,.08);
       @media screen and (max-width: 530px) {
         width: 100%;
         background-color: #F1F4F5;
         box-shadow: none;
         border-radius: 3px;
         padding: 20px;
       }
       &:before{
         content: '\25B2';
         font-size: 18px;
         position: absolute;
         top: -16px;
         left: 32px;
         z-index: 2;
         color: #fff;
         text-shadow: -8px 0 8px rgba(160,104,255,.08), 8px 0 8px rgba(160,104,255,.08);
         @media screen and (max-width: 530px) {
           content: '';
         }
       }
       &.enlist:before{
         left: 26.5%;
       }
       &.pay:before{
         left: 48.5%;
       }
       &.conduct:before{
         left: 71%;
       }
       &.accomplish:before{
         left: 93.2%;
       }
      .title{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
        img{
          width: 8px;
          margin-right: 18px;
          vertical-align: bottom;
        }
      }
      /deep/.el-textarea {
        width: calc(100% - 80px);
        border-radius:2px;
        display: inline-block;
        @media screen and (max-width: 530px) {
          display: block;
          width: 100%;
          margin-top: 10px;
        }
      }
      /deep/.el-textarea__inner{
        @media screen and (max-width: 530px) {          background-color: #F1F4F5;
        }
      }
      .status{
        color: #8d8d8d;
        font-size: 16px;
        margin-bottom: 15px;
        .icon{
          color: #fc1919;
          margin-right: 8px;
        }
      }
      .checkMsg,.zhaomuDate,.projectDate{
        color: #acacac;
        font-size: 16px;
        margin-left: 24px;
        span{
          margin-right: 45px;
        }
      }
      .payment-type{
        margin: 30px 0;
        color: #8d8d8d;
        span{
          margin: 0;
          &.tips{
            font-size: 14px;
          }
          &.required{
            color: #F56C6C;
            margin: 0 8px;
          }
        }
      }
      .selectAccount{
        margin: 60px 40px;
        color: #3d3d3d;
        span{
          margin-right: 10px;
        }
      }
      .personnnel-list{
        padding: 0;
        margin: 0;
        @media screen and (max-width: 760px) {
          display: none;
        }
        .personnnel-item{
          background-color: #fff;
          border-radius: 3px;
          font-size: 16px;
          margin-top: 15px;
          .personnel{
            text-align: center;
            .person-img{
              width: 70px;
              border-radius: 50%;
              margin: 0 auto;
              img{
                width: 100%;
                border-radius: 50%;
              }
            }
            .renzheng{
              font-size: 12px;
              padding: 2px 5px;
              border: 1px solid #7B62F7;
              color: #7B62F7;
              border-radius: 3px;
            }
          }
          .personnnel-content{
            padding-right: 20px;
            div{
              margin-bottom: 15px;
            }
            .personnnel-title{
              .name,.job{
                font-size: 16px;
                font-weight: 700;
                margin-right: 10px;
              }
              img{
                width: 16px;
                margin-left: 10px;
              }
            }
            .exprience{
              font-size: 14px;
              color: #8d8d8d;
              span{
                margin: 0 10px;
              }
              span:first-child{
                margin-left: 0;
              }
            }
            .demand{
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                padding: 0;
                color: #6d6d6d;
                font-size: 14px;
            }
            .tags .tag{
              background-color: #eaeaec;
              font-size: 14px;
              padding: 4px 12px;
              border-radius: 20px;
              margin-right: 10px;
            }
          }
          .recruit{
            border-left: 1px solid #dcdcdc;
            text-align: center;
            padding: 20px 0;
            font-size: 14px;
            .price{
              margin-bottom: 30px;
              letter-spacing: 2px;
              span{
                font-size: 24px;
                font-weight: 700;
              }
            }
            .btn{
              background-color: #795FF6;
              color: #fff;
              width: 100px;
              height: 28px;
              line-height: 28px;
              border-radius: 28px;
              margin: 0 auto;
              &.recruited{
                background-color: #EFEFEF;
                color: #8d8d8d;
              }
              .iconfont{
                font-size: 20px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .mobile-list{
        display: none;
        @media screen and (max-width: 760px) {
          display: block;
        }
        .personnnel-item{
          border-radius: 3px;
          font-size: 16px;
          margin-top: 15px;
          display: inline-block;
          margin: 20px 0;
          padding-bottom:15px;
          border-bottom: 1px solid #cdcdcd;
          &:last-child{
            border: none;
          }
          .personnel{
            .person-img{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              display: inline-block;
              margin-right: 10px;
              vertical-align: top;
              img{
                width: 100%;
                border-radius: 50%;
              }
            }
          }
          .personnnel-content{
            display: inline-block;
            width: calc(100% - 70px);
            div{
              margin-bottom: 10px;
            }
            .personnnel-title{
              .name,.job{
                font-size: 20px;
                font-weight: 700;
                margin-right: 5px;
              }
              img{
                width: 16px;
              }
            }
            .exprience{
              font-size: 14px;
              color: #8d8d8d;
              /* span{
                margin: 0 10px;
              } */
              span:nth-child(2){
                margin-left: 10px;
              }
            }
            .renzheng{
              font-size: 12px;
              padding: 2px 5px;
              border: 1px solid #7B62F7;
              color: #7B62F7;
              border-radius: 3px;
              float: right;
              margin-top: 2px;
            }
          }
          .demand{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 10px 0 20px;
            padding:0;
            background-color: transparent;
          }
          .tags {
            border-top: 1px solid #dcdcdc;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .tag{
              background-color: #f7f6ff;
              font-size: 14px;
              padding: 4px 12px;
              border-radius: 20px;
              margin-right: 10px;
              display: inline-block;
            }
          }

          .price{
            margin-bottom: 30px;
            color: #795FF6;
            span{
              font-size: 22px;
              font-weight: 700;
            }
          }
          .recruit{
            float: right;
          }
          .btn{
            background-color: #795FF6;
            color: #fff;
            width: 100px;
            height: 28px;
            line-height: 28px;
            border-radius: 28px;
            margin: 0 auto;
            text-align: center;
            &.recruited{
              background-color: #dcdcdc;
              color: #6d6d6d;
            }
            .iconfont{
              font-size: 20px;
              vertical-align: middle;
            }
          }
        }
      }
      .project-desc,.deliver{
        font-size: 16px;
        color: #acacac;
        margin: 15px 24px;
        .files{
          color: #6d6d6d;
          font-size: 14px;
          margin-right: 25px;
        }
      }
      /deep/.el-radio__input.is-checked .el-radio__inner {
          border-color: #A068FF;
          background: #fff;
      }
      /deep/.el-radio__input.is-checked + .el-radio__label {
          color: #606266 !important;
      }
      .el-radio__inner:hover {
          border-color: #A068FF;
      }
      /deep/.el-radio__inner::after{
        background-color: #A068FF;
        width: 6px;
        height: 6px;
      }
      /deep/.el-radio{
        margin-right: 20px;
      }
      /deep/.el-button.is-plain.addAccount{
        margin-left: 20px;
      }
      /deep/.el-button.is-plain:hover, /deep/.el-button.is-plain:focus {
          background: #FFFFFF;
          border-color: #A068FF;
          color: #A068FF;
      }
      .download,.upload{
        color: #795FF6;
        margin-left: 8px;
        &:hover{
          opacity: .78;
        }
        .iconfont{
          font-size: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .delete{
        color: #8d8d8d;
        margin-left: 8px;
      }
      .isEnd,.error{
        margin: 50px 0;
        text-align: center;
        img{
          width: 120px;
          height: 120px;
          margin: 15px auto;
        }
        .finish{
          color: #acacac;
          font-size: 14px;
        }
        .evaluate{
          font-size: 14px;
          color: #6d6d6d;
          letter-spacing: 1px;
          margin: 15px 0;
          .assess{
            color: #a068ff;
          }
        }
      }
      .unpaid{
        .payment{
          background-color: #A068FF;
          width: 150px;
          margin: 0 auto;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 10px;
          cursor: pointer;
          text-align: center;
        }
      }
    }
  }
  .schedule2{
    background-color: #fff;
    margin: 10px 0;
    padding: 20px;
    min-height: 150px;
    .status-content{
      width: 90%;
      position: relative;
      margin: 100px auto 30px;
      padding: 30px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 8px rgba(160,104,255,.08),0 -8px 8px rgba(160,104,255,.08),
      8px 0 8px rgba(160,104,255,.08),-8px 0 8px rgba(160,104,255,.08);

      .title{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 22px;
        img{
          width: 8px;
          margin-right: 18px;
          vertical-align: bottom;
        }
      }
      .status{
        color: #8d8d8d;
        font-size: 16px;
        margin-bottom: 22px;
        .icon{
          color: #fc1919;
          margin-right: 8px;
        }
      }
      .checkMsg,.zhaomuDate,.projectDate{
        color: #acacac;
        font-size: 16px;
        margin-left: 24px;
        span{
          margin-right: 45px;
        }
      }
      .personnnel-list{
        padding: 0;
        margin: 0;
        .personnnel-item{
          background-color: #fff;
          border-radius: 3px;
          font-size: 16px;
          margin-top: 15px;
          .personnel{
            text-align: center;
            .person-img{
              width: 70px;
              border-radius: 50%;
              margin: 0 auto;
              img{
                width: 100%;
                border-radius: 50%;
              }
            }
            .renzheng{
              font-size: 12px;
              padding: 2px 5px;
              border: 1px solid #7B62F7;
              color: #7B62F7;
              border-radius: 3px;
            }
          }
          .personnnel-content{
            padding-right: 20px;
            div{
              margin-bottom: 15px;
            }
            .personnnel-title{
              .name,.job{
                font-size: 16px;
                font-weight: 700;
                margin-right: 10px;
              }
              img{
                width: 16px;
                margin-left: 10px;
              }
            }
            .exprience{
              font-size: 14px;
              color: #8d8d8d;
              span{
                margin: 0 10px;
              }
              span:first-child{
                margin-left: 0;
              }
            }
            .demand{
              overflow:hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              padding: 0;
              color: #6d6d6d;
              font-size: 14px;
            }
            .tags .tag{
              background-color: #eaeaec;
              font-size: 14px;
              padding: 4px 12px;
              border-radius: 20px;
              margin-right: 10px;
            }
          }
          .recruit{
            border-left: 1px solid #dcdcdc;
            text-align: center;
            padding: 20px 0;
            font-size: 14px;
            .price{
              margin-bottom: 30px;
              letter-spacing: 2px;
              span{
                font-size: 24px;
                font-weight: 700;
              }
            }
            .btn{
              background-color: #795FF6;
              color: #fff;
              width: 100px;
              height: 28px;
              line-height: 28px;
              border-radius: 28px;
              margin: 0 auto;
              &.recruited{
                background-color: #EFEFEF;
                color: #8d8d8d;
              }
              .iconfont{
                font-size: 20px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .project-desc,.deliver{
        font-size: 16px;
        color: #acacac;
        margin: 24px;
        .files{
          color: #6d6d6d;
          font-size: 14px;
          margin-right: 25px;
        }
      }
      .download,.upload{
        color: #795FF6;
        margin-left: 8px;
        &:hover{
          opacity: .78;
        }
        .iconfont{
          font-size: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .delete{
        color: #8d8d8d;
        margin-left: 8px;
      }
      .isEnd,.error{
        margin: 50px 0;
        text-align: center;
        img{
          width: 120px;
          height: 120px;
          margin: 15px auto;
        }
        .finish{
          color: #acacac;
          font-size: 14px;
        }
        .evaluate{
          font-size: 14px;
          color: #6d6d6d;
          letter-spacing: 1px;
          margin: 15px 0;
          .assess{
            color: #a068ff;
          }
        }
      }
    }
  }
  .evaluate-wrapper{
    width: 100%;
    background-color: #fff;
    margin: 10px 0;
    padding: 30px;
    min-height: 150px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .noData{
      text-align: center;
      height: 80px;
      line-height: 80px;
      margin-top: 20px;
      color: #8d8d8d;
      font-size: 14px;
    }
    .evaluate-item{
      background-color: #fff;
      border-radius: 3px;
      font-size: 14px;
      margin-top: 15px;
      padding: 20px 0 5px;
      border-bottom: 1px solid #EDEDED;
      &:last-child{
        border: none;
      }
      .company-img{
        width: 70px;
        border-radius: 50%;
        margin: 0 auto;
        img{
          width: 100%;
          border-radius: 50%;
        }
      }
      .company-evaluate{
        padding-right: 20px;
        &>div{
          margin-bottom: 15px;
        }
        .company{
          .name{
            font-size: 16px;
            font-weight: 700;
            margin-right: 10px;
          }
          img{
            width: 16px;
            margin-left: 10px;
          }
        }
        .score{
          font-size: 14px;
          color: #8d8d8d;
          span{
            margin-right: 25px;
          }
        }
        .desc{
          color: #4d4d4d;
          line-height: 1.6;
        }
      }
    }
  }
}
.accountDialog{
  .el-form{
    width: 70%;
    margin: 15px auto;
    @media screen and (max-width: 530px) {
      width: 100%;
    }
    .el-form-item {
        margin-bottom: 12px;
    }
    .tips{
      font-size: 14px;
      line-height: 1.6;
      color: #8d8d8d;
      .required{
        color: #F56C6C;
        margin-right: 8px;
      }
    }
  }
}
.el-rate{
  display: inline-block;
  vertical-align: middle;
}
.addAccountBtn{
  margin-bottom: 50px;
}
</style>
