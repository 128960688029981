<template>
  <div class="container">
    <div class="title">资金记录</div>
    <div class="form-wrapper">
      <el-form ref="form" :inline="true" :model="form" label-width="80px">
        <el-form-item label="流水编号:">
          <el-input style="width: 200px;" v-model="form.id" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="选择时间:">
          <el-date-picker size="mini" v-model="form.date" type="datetimerange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="工单类型:" class="radios">
          <el-radio-group v-model="form.fundingType">
            <el-radio size="mini" label="" border>全部</el-radio>
            <el-radio size="mini" label="0" border>支出</el-radio>
            <el-radio size="mini" label="1" border>收入</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button size="medium" type="primary" class="submit" @click="search">查询</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" v-loading="listLoading">
      <el-table-column prop="id" label="单号" width="180"></el-table-column>
      <el-table-column label="里程碑" align="center">
        <template slot-scope="scope">
          {{ scope.row.milestoneName ? scope.row.milestoneName : '提现申请' }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额" align="center" width="80"></el-table-column>
      <el-table-column label="收支类型" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.fundingType === '1'">收入</span>
          <span v-if="scope.row.fundingType === '0'">支出</span>
        </template>
      </el-table-column>
      <el-table-column label="付款方式" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentType === '1'">在线支付</span>
          <span v-if="scope.row.paymentType === '2'">余额</span>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="时间" width="180" align="center"></el-table-column>
      <el-table-column prop="description" label="描述" align="center"></el-table-column>
    </el-table>
    <div class="recordList" v-if="tableData.length>0" v-loading="listLoading">
      <div class="record" v-for="item in tableData">
        <div class="line">
          <el-tag type="success" size="medium" v-if="item.fundingType === '1'">收入</el-tag>
          <el-tag type="danger" size="medium" v-else-if="item.fundingType === '0'">支出</el-tag>
          <span class="record-title">{{item.milestoneName ? item.milestoneName : '提现申请'}}</span>
        </div>
        <div class="line">描述：{{item.description}}</div>
        <div class="line">金额：{{item.amount}}</div>
        <div class="line">
          <div>收支类型：{{item.fundingType === '0'?'支出':'收入'}}</div>
          <div>支付方式：{{item.paymentType === '0'?'对公转账':item.paymentType === '1'?'线上支付':'余额'}}</div>
        </div>
        <div class="line time">时间：{{item.createDate}}</div>
      </div>
    </div>
    <div v-else style="background-color: #fff;height: 60px;line-height: 60px;text-align: center;font-size: 16px;color: #8d8d8d;">暂无数据</div>
    <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="current" prev-text="上一页" next-text="下一页"></el-pagination>
  </div>
</template>

<script>
import { fetchList } from "@/api/flowRecord/flowRecord";
  export default {
    data () {
      return {
        listLoading: false,
        form: {
          fundingType: '',
          date: []
        },
        tableData:[],
        total: 0,
        current: 1
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList () {
        this.form.startDate = (this.form.date && this.form.date.length === 2) ? this.form.date[0] : ''
        this.form.endDate = (this.form.date && this.form.date.length === 2) ? this.form.date[1] : ''
        this.listLoading = true
        const res = await fetchList(this.form)
        if (res.code === 0) {
          this.tableData = res.data
          this.total = res.count
        } else {
          this.$message.error(res.msg)
        }
        this.listLoading = false
      },
      async search () {
        // 初始化分页信息
        this.current = 1
        this.getList()
      },
      handleCurrentChange (val) {
        this.current = val
        this.getList()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    margin-top: 20px;
    text-align: center;
  }
  .radios /deep/.el-form-item__content,/deep/.el-form--inline .radios .el-form-item__content{
  	@media screen and (max-width: 530px) {
  	  line-height: unset;
  	  vertical-align: bottom;
  	}
  }
  /deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
  	@media screen and (max-width: 530px) {
  	  font-size: 16px;
  	  padding-right: 10px;
      vertical-align: top;
  	}
  }
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    @media screen and (max-width: 530px) {
      background-color: transparent;
      padding: 0;
    }
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
      @media screen and (max-width: 530px) {
        display: none;
      }
    }
    .form-wrapper{
      background-color: #fff;
      width: 100%;
      @media screen and (max-width: 530px) {
        padding: 15px;
      }
      /deep/.el-date-editor--datetimerange.el-input,
      /deep/.el-date-editor--datetimerange.el-input__inner {
        @media screen and (max-width: 530px) {
          width: 260px;
        }
      }
      .submit{
        @media screen and (max-width: 530px) {
          float: right;
          margin-top: -60px;
        }
      }
    }
    .el-table{
      @media screen and (max-width: 530px) {
        display: none;
      }
    }
    .recordList{
      background-color: #fff;
      margin-top: 20px;
      display: none;
      @media screen and (max-width: 530px) {
        display: block;
        padding: 20px 15px;
      }
      .record{
        background-color: #fafcfd;
        font-size: 18px;
        padding: 10px 20px;
        margin-bottom: 15px;
        color: #4d4d4d;
        &:last-child{
          margin: 0;
        }
        .line{
          margin: 10px 0;
          line-height: 1.68;
          .el-tag{
            font-size: 16px;
          }
          .record-title{
            margin-left: 10px;
            font-weight: 700;
            font-size: 20px;
          }
          &:first-child{
            border-bottom: 1px solid #dcdcdc;
            padding-bottom: 15px;
          }
          &>div{
            display: inline-block;
            vertical-align: top;
            &:last-child{
              float: right;
            }
          }
        }
      }
    }
  }
  .operate-btn{
    color:#795FF6;
    background-color: #f9f8ff;
    border-radius: 3px;
    padding: 5px 10px;
  }
</style>
