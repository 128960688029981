import request from '@/utils/request'
export function fetchList (data) {
  return request({
    url: '/worder/fetchList',
    method: 'POST',
    data
  })
}
export function commit (data) {
  return request({
    url: '/worder/createWorkOrder',
    method: 'POST',
    data
  })
}

export function fetchWorksById (data) {
  return request({
    url: '/worder/fetchInfo',
    method: 'POST',
    data
  })
}

export function reply (data) {
  return request({
    url: '/worder/reply',
    method: 'POST',
    data
  })
}