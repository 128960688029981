import request from '@/utils/request'
export function selectMsg (data) {
  return request({
    url: '/msg/selectMsg',
    method: 'POST',
    data
  })
}
export function allRead () {
  return request({
    url: '/msg/allRead',
    method: 'POST'
  })
}
// 查看详情
export function getDetails (data) {
  return request({
    url: '/msg/selectMsgDetails',
    method: 'POST',
    data
  })
}
export function getNotificationBadgeFlag () {
  return request({
    url: '/msg/getNotificationBadgeFlag',
    method: 'POST'
  })
}
