<template>
  <div class="container">
    <div class="title">企业认证</div>
    <div class="form-wrapper">
      <el-form ref="form" :model="form" label-width="85px">
        <el-form-item label="企业名称:">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="营业执照:">
          <el-upload class="uploader" action="#" list-type="picture" :show-file-list="false"
           :on-success="handleAvatarSuccess">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog" v-if="showDialog"  @click=" showDialog = false">
      <div class="content">
        <i class="iconfont icon-guanbi2fill" @click=" showDialog = false"></i>
        <div class="img-wrapper"><img src="../../../assets/images/success.png" alt=""></div>
        <div class="state">提交成功</div>
        <div style="font-size: 14px;">请耐心等待平台审核通知</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        form: {},
        imageUrl: '',
        showDialog: false
      }
    },
    mounted() {

    },
    methods: {
      handleAvatarSuccess(res, file) {
        console.log(res, file)
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      onSubmit(){
        this.showDialog = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    @media screen and (max-width: 530px) {
      min-height: calc(100vh - 120px);
    }
    .dialog{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: rgba(0,0,0,.3);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .content{
        width: 22%;
        min-width: 240px;
        background-color: #fff;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        div{
          margin: 15px 0;
        }
        .img-wrapper{
          width: 80px;
          margin: 30px auto 0;
          img{
            width: 100%;
            margin-left: 6px;
          }
        }
        .iconfont{
          color: #c7c7c7;
          font-size: 20px;
          float: right;
          cursor: pointer;
        }
        .state{
          font-size: 16px;
          margin: 20px 0;
        }
      }
    }
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .el-input.el-input--mini{
      width: 220px;
    }
    .uploader /deep/.el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

</style>
