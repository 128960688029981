<template>
  <div class="container">
    <div class="evaluate" v-loading="commentLoading">
      <div class="title">项目评价</div>
      <div class="more">
        <!--        <span >好评度：-->
        <!--          <el-rate v-model="value" disabled text-color="#ffd161" ></el-rate>-->
        <!--          <span>（123）</span>-->
        <!--        </span>-->
      </div>
      <div class="list" v-if="commentList.length > 0">
        <div class="evaluate-item" v-for="item in commentList">
          <el-row>
            <el-col :span="3">
              <div class="company-img">
                <img style="cursor: pointer" @click="openUserInfo(item.isPersonal === 1 ? item.publishUserId : item.publishCompanyId)" :src="item.headUrl" >
              </div>
            </el-col>
            <el-col :span="21">
              <div class="company-evaluate">
                <div class="company">
                  <span style="cursor: pointer" class="name" @click="openTaskInfo(item.taskId)">{{ item.taskTitle }}</span>
                </div>
                <div class="company">
                  <span style="cursor: pointer" @click="openUserInfo(item.isPersonal === 1 ? item.publishUserId : item.publishCompanyId)">发布者：{{ item.isPersonal === 1 ? item.publishUserName : item.publishCompanyName }}</span>
                  <img v-if="item.isPersonal === 0 && item.companyCert === 1" src="@/assets/images/vips.png" >
                  <span v-if="item.isPersonal === 1 && item.personalCert === 1" style="font-size: 12px;padding: 2px 5px;border: 1px solid #7B62F7;color: #7B62F7;border-radius: 3px;margin-left: 10px">已认证</span>
                </div>
                <div class="score" v-if="item.comment">
                  <span >时间：{{ item.commentDateTime }}</span>
                  <el-rate v-model="item.commentScore / 2.0" disabled text-color="#ffd161" ></el-rate>
                </div>
                <div class="desc">{{ item.comment ? item.commentText : '发布者暂未对该用户作出评价' }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="mobile-item" v-for="item in commentList">
          <div class="company-evaluate">
            <div class="company-img">
              <img style="cursor: pointer" @click="openUserInfo(item.isPersonal === 1 ? item.publishUserId : item.publishCompanyId)" :src="item.headUrl" >
            </div>
            <div class="company">
              <span style="cursor: pointer" class="name" @click="openTaskInfo(item.taskId)">{{ item.taskTitle }}</span>

              <div style="cursor: pointer;margin-top: 15px;" @click="openUserInfo(item.isPersonal === 1 ? item.publishUserId : item.publishCompanyId)">发布者：{{ item.isPersonal === 1 ? item.publishUserName : item.publishCompanyName }}</div>
              <img v-if="item.isPersonal === 0 && item.companyCert === 1" src="@/assets/images/vips.png" >
              <span v-if="item.isPersonal === 1 && item.personalCert === 1" style="font-size: 12px;padding: 2px 5px;border: 1px solid #7B62F7;color: #7B62F7;border-radius: 3px;margin-left: 10px">已认证</span>
            </div>

            <div class="desc">{{ item.comment ? item.commentText : '发布者暂未对该用户作出评价' }}</div>
            <div class="score" v-if="item.comment">
              <span >时间：{{ item.commentDateTime }}</span>
              <el-rate class="rate" v-model="item.commentScore / 2.0" disabled text-color="#ffd161" ></el-rate>
            </div>
          </div>
        </div>
      </div>
      <div v-if="commentList.length === 0" style="width: 100%; text-align: center; color: gray; font-size: 14px">
        暂无评论
      </div>
    </div>
  </div>
</template>

<script>
import {apiBaseURL} from "@/utils/request";
import {getUserInfo,getAllComment} from "@/api/personnel";
export default {
  props: {
    user: {
      type: Object
    },
    isMobile: {
      type: Boolean
    }
  },
  data () {
    return {
      data:{
        type:['数据挖掘','数据采集']
      },
      value: 3.7,
      workList: [],
      loading: false,
      commentLoading: false,
      commentList: []
    }
  },
  methods: {
    async getAllComment () {
      this.commentLoading = true
      const res = await getAllComment({ userId: this.user.id })
      if (res.code === 0) {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].headUrl = res.data[i].headUrl ? apiBaseURL + res.data[i].headUrl : apiBaseURL + '/avator/company.png'
        }
        this.commentList = res.data.length > 5 ? res.data.slice(0,5) : res.data
      }
      this.commentLoading = false
    },
    openTaskInfo (id) {
      this.$router.push('/detail/' + id)
    },
    openUserInfo (id) {
      this.$router.push('/personnel/detail/' + id)
    }
  },
  mounted () {
    this.getAllComment()
  },
  watch: {
    $route (to, from) {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluate{
  background-color: #fff;
  margin: 10px 0;
  padding: 30px;
  position: relative;
  min-height: 150px;
  .title{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .more{
    position: absolute;
    right: 50px;
    top: 20px;
    color: #adadad;
    .moreWorks{
      margin-left: 50px;
      color: #adadad;
    }
  }
  .evaluate-item{
    background-color: #fff;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 15px;
    padding: 20px 0 5px;
    border-bottom: 1px solid #EDEDED;
    @media screen and (max-width: 530px) {
      display: none;
    }
    &:last-child{
      border: none;
    }
    .company-img{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .company-evaluate{
      padding-right: 20px;
      div{
        margin-bottom: 15px;
      }
      .company{
        .name{
          font-size: 16px;
          font-weight: 700;
          margin-right: 10px;
        }
        img{
          width: 16px;
          margin-left: 10px;
        }
      }
      .score{
        font-size: 14px;
        color: #8d8d8d;
        span{
          margin-right: 25px;
        }
      }
      .desc{
        color: #4d4d4d;
        line-height: 1.6;
      }
    }
  }
  .mobile-item{
    background-color: #fff;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 15px;
    padding: 20px 0 5px;
    border-bottom: 1px solid #EDEDED;
    display: none;
    @media screen and (max-width: 530px) {
      display: block;
    }
    &:last-child{
      border: none;
    }
    .company-img{
      width: 70px;
      height: 70px;
      margin-right: 15px;
      border-radius: 50%;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .company-evaluate{
      .company{
        display: inline-block;
        width: calc(100% - 85px);
        .name{
          font-size: 16px;
          font-weight: 700;
          margin-right: 10px;
        }
        img{
          width: 16px;
          margin-left: 10px;
        }
      }
      .score{
        font-size: 14px;
        color: #8d8d8d;
        span{
          margin-right: 25px;
        }
        .rate{
          float: right;
        }
      }
      .desc{
        color: #4d4d4d;
        line-height: 1.6;
        background-color: #F4F4F5;
        padding: 12px;
        margin: 18px 0;
      }
    }
  }
}
</style>
