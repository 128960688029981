<template>
  <div>
    <!--    <div class="header-wrap pc"  :style="$route.path !== '/'&&$route.path !== '/personnel'&&$route.path !== '/release'&&$route.path.indexOf('/detail')!==-1&&$route.path !== '/mission' || $route.path === '/community/post'?'background: rgba(121, 95, 246,1);color:#fff;transition: all 0.5s':lightBg?'background:#fff;color:#3d3d3d;transition: all 0.5s':$route.path == '/release'?'background:#795FF6;color:#fff;transition: all 0.5s':'background:transparent;color:#fff;transition: all 0.5s' ">-->
    <div class="header-wrap pc"
         :style="lightBg?'background:#fff;color:#3d3d3d;transition: all 0.5s':'background:transparent;color:#fff;transition: all 0.5s' ">
      <img class="logo" v-if="lightBg" src="@/assets/images/logo.png"
           style="height: 50px;margin: 8px 20px; margin-right: 2px" alt="">
      <img class="logo" v-else src="@/assets/images/logo-white.png"
           style="height: 50px;margin: 8px 20px; margin-right: 0px" alt="">
      <div class="menuList">
        <el-menu style="display: inline-block;" text-color="#fff" active-text-color="#fff" class="el-menu-demo"
                 mode="horizontal">
          <el-menu-item v-for="item in navList" :key="item.index" :index="item.url"
                        :style="lightBg?'color:#3d3d3d;':'color:#fff;' " @click="goTo(item.url)">
            <!-- <router-link v-if="item.url !=='/data'" :to="{path:item.url}">{{ item.name }}</router-link> -->
            <!-- <a v-else target="_blank" href="http://localhost:8089" >{{ item.name }}</a> -->
            {{ item.name }}
          </el-menu-item>
        </el-menu>
        <div class="btns" v-if="!isLogin">
          <span><router-link to="/login">登录</router-link></span> | <span><router-link
          to="/register">注册</router-link></span>
        </div>
        <div class="btns" v-if="isLogin">
          <div class="notice-btn">
            <i style="cursor: pointer" class="iconfont icon-xiaoxitongzhi"
               :style="lightBg?'color:#3d3d3d;':'color:#fff;' " @click="showNotice = true;viewSelectMsg()"></i>
            <div v-if="unReadNotificationFlag"
                 style="width: 8px;height: 8px;background-color: #F56C6C;position: relative;top: -50px;border-radius: 50%;left: 15px"></div>
            <div class="notice-dialog" v-if="showNotice">
              <div class="header">
                <span class="title">未读消息</span>
                <!--              <span class="count" v-if="total > 0">(39条)</span>-->
                <el-button type="text" class="isRead" @click="viewAllRead()">全部已读</el-button>
              </div>
              <ul class="noticeList" v-if="dataList.length > 0" v-loading="notificationBoxLoading">
                <li class="notice" v-for="item in dataList" @click="$router.push('/perCenter/notification/'+item.id)">
                  <el-badge is-dot></el-badge>
                  <div class="msg">{{ item.title }}</div>
                </li>
              </ul>
              <div class="noMore" v-else v-loading="notificationBoxLoading">
                暂无消息
              </div>
              <div class="getMore">
                <el-button type="text" class="more" @click="showNotice=false;$router.push('/perCenter/notification');">
                  查看更多&gt;&gt;
                </el-button>
              </div>
            </div>
          </div>
          <el-dropdown @command="handleCommand">
            <img @click="handleCommand('c')" :src="avator"
                 style="width: 50px; height: 50px; border-radius: 50px; margin-top: 5px; margin-right: 0px; cursor: pointer;border: 3px solid #fff">
            <el-dropdown-menu slot="dropdown" style="margin-top: -10px">
              <el-dropdown-item command="c">个人中心</el-dropdown-item>
              <el-dropdown-item command="a">修改密码</el-dropdown-item>
              <el-dropdown-item command="b">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

    </div>
    <div class="header-wrap mobile" style="background:#fff;color:#3d3d3d;transition: all 0.5s">
      <img class="logo" src="@/assets/images/logo.png" style="height: 50px;margin: 8px 20px; margin-right: 2px" alt="">

      <div class="mobileMenu">
        <div class="icons">
          <i class="iconfont icon-xiaoxitongzhi" v-if="isLogin" @click="$router.push('/perCenter/notification')"></i>
          <i class="iconfont icon-caidan" @click.stop="showNav = true"></i>
        </div>
        <div class="navList" v-if="showNav">
          <ul class="nav">
            <li class="navitem" v-for="item in navList" :key="item.index" :index="item.url" @click="goTo(item.url)">
              {{ item.name }}
            </li>
            <li class="button-group" v-if="!isLogin">
              <el-button type="primary" size="medium" @click="$router.push('/login')">登录</el-button>
              <el-button type="primary" size="medium" @click="$router.push('/register')">注册</el-button>
            </li>
            <li class="button-group" v-else>
              <el-button type="primary" size="medium" @click="signOut">退出登录</el-button>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="swiper"
         v-if="$route.path=='/'||$route.path=='/task'||$route.path=='/personnel'||$route.path=='/anli'||$route.path=='/workBench'">
      <img src="../../assets/images/bannerBg.png">
      <div class="wrapper" v-if="$route.path!=='/workBench'">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="isLogin?$router.push('/release'):$router.push('/login')">发布需求</div>
          <div class="btn zhaomu" @click="isLogin?$router.push('/personnel'):$router.push('/login')">招募人才</div>
        </div>
      </div>
    </div>
    <div class="swiper2"
         v-else-if="($route.meta.type !=='add'&&$route.meta.type !=='edit'&&$route.meta.type !=='detail'||$route.path.indexOf('workOrder')!== -1) && $route.path.indexOf('community') === -1">
      <img src="../../assets/images/bg.png">
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu" @click="$router.push('/personnel')">招募人才</div>
        </div>
      </div>
    </div>
    <div v-else-if="$route.path.indexOf('community') !== -1"></div>
    <div class="resign-dialog" v-if="showDialog" @click=" showDialog = false">
      <div class="content">
        <div class="title">选择企业or个人</div>
        <div class="box">
          <div class="type">
            <i class="iconfont icon-gerenziliao"></i>
            <div class="typeName">
              <div class="type-title">个人</div>
              <div class="tip">可以在52投递项目，赚取佣金</div>
            </div>
          </div>
          <div class="type">
            <i class="iconfont icon-qiye2"></i>
            <div class="typeName">
              <div class="type-title">企业</div>
              <div class="tip">可以在52发布项目，实现需求</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {_isMobile} from '@/utils/index';
import {getToken, removeToken} from "@/utils/auth";
import {getAvator} from "@/api/personnel";
import {apiBaseURL} from "@/utils/request";
import router from "@/router";
import {selectMsg, allRead, getNotificationBadgeFlag} from "@/api/notification/notification";

export default {
  data() {
    return {
      showNav: false,
      count: 0,
      currentPage: 1,
      size: 10,
      loading: false,
      tableData: [],
      navList: [],
      showMenu: true,
      lightBg: false,
      isLogin: false,
      avator: '',
      showNotice: false,
      showDialog: false,
      total: 0,
      dataList: [],
      unReadNotificationFlag: false
    }
  },
  mounted () {
    if (this.$route.path === '/release' ||
      this.$route.path === '/community/post' ||
      this.$route.path.indexOf('/community/detail/') !== -1 ||
      this.$route.path.indexOf('/order') !== -1 ||
      this.$route.path === '/perCenter/addWorks' ||
      this.$route.path.indexOf('perCenter/editWorks') !== -1 ||
      this.$route.path.indexOf('anli/detail') !== -1 ||
      this.$route.path.indexOf('questions') !== -1) {
      this.lightBg = true
    }
    const appDiv = document.getElementById('app')
    if (appDiv) {
      appDiv.addEventListener('scroll', this.handleScroll)
    }
    if (this.$route.path === '/release' || this.$route.path === '/community/post' || this.$route.path.indexOf('/community/detail/') !== -1) {
      this.lightBg = true
    }
    var that = this
    // 点击其他地方隐藏消息弹窗
    document.addEventListener('click', function (e) {
      if (that.showNav) that.showNav = false;
      if (e.target.parentElement.className.indexOf('notice') == -1) {
        that.showNotice = false;
      }
    })
    this.navList = JSON.parse(JSON.stringify(require('@/api/menu.json')));
    /* if (_isMobile()) {
      this.showMenu = false
    } else {
      this.showMenu = true
    } */
    if (getToken()) {
      this.isLogin = true
      this.getAvator()
      this.getNotificationBadgeFlag()
    }
    if (!this.isLogin) {
      this.navList.splice(3, 5)
    }
  },
  methods: {
    handleScroll (event) {
      const scrollt = event.target.scrollTop
      // console.log('Scroll position:', scrollt);

      if (this.$route.path === '/release' ||
        this.$route.path === '/community/post' ||
        this.$route.path.indexOf('/community/detail/') !== -1 ||
        this.$route.path.indexOf('/order') !== -1 ||
        this.$route.path === '/perCenter/addWorks' ||
        this.$route.path.indexOf('perCenter/editWorks') !== -1 ||
        this.$route.path.indexOf('anli/detail') !== -1 ||
        this.$route.path.indexOf('questions') !== -1) {
        this.lightBg = true
        return
      }

      if (scrollt > 120) { // 判断滚动后高度超过120px,就改变状态
        this.lightBg = true
      } else {
        this.lightBg = false
      }
    },
    goTo(url) {
      if (_isMobile()) {
        if (url.indexOf('workOrder') !== -1) {
          this.$router.push('/error')
        } else if (url.indexOf('data') !== -1) {
          window.open('https://52shuju.com', '_blank')
        } else {
          this.$router.push(url)
        }
      } else {
        if (url.indexOf('data') !== -1) {
          window.open('https://52shuju.com', '_blank')
        } else {
          this.$router.push(url)
        }
      }

    },
    async getAvator() {
      const res = await getAvator()
      this.avator = res ? apiBaseURL + res : apiBaseURL + '/avator/company.png'
    },
    async getNotificationBadgeFlag() {
      const res = await getNotificationBadgeFlag()
      this.unReadNotificationFlag = res.data
    },
    async viewSelectMsg() {
      this.notificationBoxLoading = true
      const res = await selectMsg({'currentPage': this.currentPage, 'state': 0})
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.count
      } else {
        this.$message({
          message: res.msg,
          type: 'error',
          offset: 80
        })
      }
      this.notificationBoxLoading = false
    },
    async viewAllRead() {
      const res = await allRead()
      if (res.code === 0) {
        this.viewSelectMsg()
        this.getNotificationBadgeFlag()
        this.$message({
          message: res.msg,
          type: 'success',
          offset: 80
        })
      } else {
        this.$message({
          message: res.msg,
          type: 'error',
          offset: 80
        })
      }
    },
    handleCommand(command) {
      if (command === 'a') {
        this.$router.push('/perCenter/resetPass')
      }
      if (command === 'b') {
        this.signOut()
      }
      if (command === 'c') {
        this.$router.push('/perCenter/perForm')
      }
    },
    signOut() {
      removeToken()
      this.$router.push('/login')
    }
  },
  computed: {
    // 监听数据
    getnewValue() {
      return this.$route.path
    }
  },
  watch: {
    //  监听改变事件
    getnewValue: {
      handler(newValue, oldValue) {
        this.showNav = false
        if (getToken()) {
          this.isLogin = true
          this.getAvator()
        }
      }
    },
    isLogin: {
      handler(newValue, oldValue) {
        if (newValue) this.navList = require('@/api/menu.json');
      }
    },
    $route(to, from) {
      var toPath = to.path
      document.documentElement.scrollTop = 0
      if (toPath === '/release' || toPath === '/community/post' || toPath.indexOf('/community/detail/') !== -1 || toPath.indexOf('/order') !== -1 || toPath === '/perCenter/addWorks' || toPath.indexOf('perCenter/editWorks') !== -1 || toPath.indexOf('anli/detail') !== -1 || toPath.indexOf('questions') !== -1) {
        this.lightBg = true
      } else {
      }
    }
  },
  beforeDestroy () {
    const appDiv = document.getElementById('app')
    if (appDiv) {
      appDiv.removeEventListener('scroll', this.handleScroll)
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper {
  position: relative;
  @media screen and (max-width: 530px) {
    display: none;
  }

  img {
    width: 100%;
    height: 420px;
  }

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -190px;
    margin-top: -100px;
    color: #fff;
    text-align: center;

    .title {
      font-size: 36px;
      letter-spacing: 2px;
      margin-bottom: 50px;
    }

    .desc {
      font-size: 16px;
      margin-bottom: 50px;
    }

    .btns-group .btn {
      display: inline-block;
      width: 140px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      text-align: center;
      font-size: 16px;
      margin: 0 20px;
      cursor: pointer;
    }

    .fabu {
      background: #fff;
      color: #4433a7;

    }

    .zhaomu {

      color: #fff;
      border: 1px solid #fff;
    }
  }
}

.swiper2 {
  position: relative;

  img {
    width: 100%;
    height: 320px;
  }

  .wrapper {
    position: absolute;
    bottom: 60px;
    left: 18%;

    color: #fff;

    .title {
      font-size: 32px;
      letter-spacing: 2px;
      margin-bottom: 24px;
    }

    .desc {
      font-size: 16px;
      margin-bottom: 24px;
    }

    .btns-group .btn {
      display: inline-block;
      width: 140px;
      height: 36px;
      line-height: 36px;
      border-radius: 36px;
      text-align: center;
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
    }

    .fabu {
      background: #fff;
      color: #4433a7;

    }

    .zhaomu {

      color: #fff;
      border: 1px solid #fff;
    }
  }
}

.resign-dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .content {
    width: 50%;
    // height: 400px;
    padding-bottom: 60px;
    background: #fff;
    border-radius: 3px;
    position: relative;

    .title {
      text-align: center;
      font-weight: bolder;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      height: 40px;
      line-height: 40px;
      background-color: #795FF6;
      color: #fff;
      margin-bottom: 40px;
    }

    .type {
      width: 280px;
      height: 100px;
      border: 1px solid #dcdcdc;
      margin: 20px auto;
      border-radius: 5px;
      padding: 20px;

      .iconfont {
        font-size: 42px;
        color: #E0E0E0;
        display: inline-block;
        margin-right: 10px;
        vertical-align: bottom;
      }

      .typeName {
        display: inline-block;

        .type-title {
          font-size: 22px;
          font-weight: 700;
        }

        .tip {
          font-size: 14px;
          margin-top: 12px;
          color: #8d8d8d;
        }
      }
    }
  }
}

.header-wrap {
  width: 100%;
  height: 66px;
  padding: 0 20px;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  z-index: 1999;
  top: 0;
  width: 100%;
  /* .logo {
    height: 45px;
    margin: 10px 0;
  } */
  &.pc {
    min-width: 1300px;
    @media screen and (max-width: 530px) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media screen and (max-width: 530px) {
      display: block;
    }
  }

  .menuList {
    width: calc(100% - 260px);
    display: inline-block;
    margin-left: 10px;
  }

  .btns {
    font-size: 14px;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    float: right;

    .notice-btn {
      line-height: 66px;
      display: inline-block;
      vertical-align: top;
      margin-right: 40px;
      position: relative;

      .icon-xiaoxitongzhi {
        color: #fff;
      }

      .notice-dialog {
        position: absolute;
        top: 50px;
        right: -10px;
        background-color: #fff;
        width: 300px;
        // height: 400px;
        padding: 20px 20px 5px;
        border-radius: 8px;
        box-shadow: 0 8px 8px rgba(160, 104, 255, .1), 0 -8px 8px rgba(160, 104, 255, .1),
        8px 0 8px rgba(160, 104, 255, .1), -8px 0 8px rgba(160, 104, 255, .1);

        .header {
          line-height: 1.6;

          .title {
            font-size: 18px;
            font-weight: 700;
            color: #3d3d3d;
          }

          .count {
            font-size: 14px;
            color: #9f9f9f;
            margin-left: 3px;
          }

          .isRead {
            float: right;
            color: #795FF6;
            margin-top: -2px;

            &:hover {
              opacity: .78;
            }
          }
        }

        .noMore {
          text-align: center;
          height: 40px;
          line-height: 40px;
          margin-top: 20px;
          color: #8d8d8d;
        }

        .getMore {
          text-align: center;
          line-height: 1;

          .more {
            color: #795FF6;
            margin-top: 5px;
            line-height: 1;

            &:hover {
              opacity: .78;
            }
          }
        }

        .noticeList {
          padding: 0;
        }

        .notice {
          padding: 10px 0;
          font-size: 14px;
          color: #3d3d3d;
          line-height: 1.6;
          border-bottom: 1px solid #EDEDED;
          cursor: pointer;

          .el-badge {
            float: left;
            margin-top: 5px;
          }

          .msg {
            margin-left: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }

  }

  .el-menu.el-menu--horizontal {
    border: none;
  }

  .el-menu {
    background-color: transparent;
  }

  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: transparent;
    border-bottom: 2px solid #fff !important;
  }

}

.mobileMenu {
  float: right;
  height: 66px;
  line-height: 66px;
  position: relative;

  .icons {
    .iconfont {
      font-size: 22px;

      &.icon-caidan {
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }

  .navList {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 67px);
    background-color: #fff;
    top: 66px;
    left: 0;
    padding: 0 20px;

    ul {
      margin: 0;
      padding: 0;
    }

    li.navitem {
      border-bottom: 1px solid #DFF0D8;
      font-size: 18px;
      color: #3d3d3d !important;

      &:last-child {
        border: none;
      }
    }

    .button-group {
      text-align: center;
      margin-top: 30px;

      .el-button--primary {
        width: 120px;
        font-size: 16px;
        margin: 15px;
      }
    }
  }
}
</style>
