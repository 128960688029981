<template>
  <div class="container">
    <div class="content">
      <div class="model1">
        <img class="bg" src="../../assets/main/xuqiuBg.png" >
        <div class="title">高效的需求发布 匹配合适人才</div>
        <div class="xuqiu">
          <div class="item">
            <div><img src="../../assets/main/shaixuan.png" ></div>
            <div class="model_title">严格筛选</div>
            <div class="desc">人才实行严格筛选，实名认证，充分背调</div>
          </div>
          <div class="item">
            <div><img src="../../assets/main/danbao.png" ></div>
            <div class="model_title">托管担保</div>
            <div class="desc">项目资金托管担保 省心无忧</div>
          </div>
          <div class="item">
            <div><img src="../../assets/main/yonggong.png" ></div>
            <div class="model_title">灵活用工</div>
            <div class="desc">灵活适应用人需求，可选全职兼职</div>
          </div>
        </div>
      </div>
      <div class="model2">
        <img class="bg" src="../../assets/main/liuchengBg.png" >
        <div class="fabu">
          <img class="backImg PC" src="../../assets/main/main_banner.png" >
          <img class="backImg mobile" src="../../assets/main/mobileBg.png" >
          <div class="title">发布需求 仅需三步</div>
          <div class="steps">
            <div class="item">
              <div>
                <span class="num">01</span>
                <span class="sub_title"><span>企业</span>或个人认证</span>
              </div>
              <div class="desc">
                我们采用严格的人才筛选机制，确保每一位加入我们团队的成员都经过认真审查和充分背景调查。我们实行实名认证制度，保证每一位成员的身份真实可靠，为团队的稳定和安全提供保障
              </div>
            </div>
            <div class="item">
              <div>
                <span class="num">02</span>
                <span class="sub_title"><span>填写</span>详细需求内容</span>
              </div>
              <div class="desc">
                在这一步，您需要填写详细的需求内容，包括项目的背景、目标、具体要求等。请提供尽可能详细和清晰的信息，以便我们更好地理解您的需求，并为您提供更准确的解决方案和服务
              </div>
            </div>
            <div class="item">
              <div>
                <span class="num">03</span>
                <span class="sub_title"><span>后台</span>审核修改</span>
              </div>
              <div class="desc">
                您提交的需求内容将由我们的后台团队进行审核和修改。我们会对您提供的信息进行认真审查，确保符合我们的要求和标准。如果有必要，我们会与您进行沟通，对需求进行进一步的调整和修改，以确保最终的方案能够满足您的期望和需求
              </div>
            </div>
          </div>
          <div class="toPublish" @click="$router.push('/release')">前往发布</div>
        </div>
        <div class="ruzhu">
          <img class="backImg PC" src="../../assets/main/mainBanner3.png" >
          <img class="backImg mobile" src="../../assets/main/mainBanner2.png" >
          <div class="title">人才入驻便捷 快速匹配合适项目</div>
          <div class="steps">
            <div class="item">
              <div>
                <img src="../../assets/main/xuqiu.png" alt="">
                <span class="sub_title">项目需求众多</span>
              </div>
              <div class="desc">
                涵盖了各种行业和领域。从软件开发到设计、文案撰写、数据分析，再到市场营销和咨询服务，您都可以在这里找到符合您需求的项目。无论您是需要外包项目，还是寻找灵活工作的机会，我们都能满足您的需求
              </div>
            </div>
            <div class="item">
              <div>
                <img src="../../assets/main/liucheng.png" alt="">
                <span class="sub_title">流程公开透明</span>
              </div>
              <div class="desc">
                我们的招聘流程完全公开透明，让每一位申请者都能清楚了解流程的每一个环节和要求。我们致力于建立一个公平公正的招聘环境，让每一位有能力的申请者都有机会展示自己的才华和价值
              </div>
            </div>
            <div class="item">
              <div>
                <img src="../../assets/main/shijian.png" alt="">
                <span class="sub_title">工作时间自由</span>
              </div>
              <div class="desc">
                我们重视员工的工作与生活平衡，提供灵活的工作时间安排。我们相信，只有在自由与舒适的工作环境中，员工才能充分发挥自己的潜力，创造出最优质的工作成果
              </div>
            </div>
          </div>
        </div>
        <div class="progress">
          <div class="title">接单流程</div>
          <div class="liucheng">
            <div class="items">
              <div>
                <img src="../../assets/main/renzheng.png" >
                <div class="num">1</div>
              </div>
              <div class="model_title">个人认证</div>
              <div class="desc">人才实行严格筛选，实名认证，充分背调</div>
            </div>
            <img class="arrowLeft" src="../../assets/main/arrow.png" >
            <div class="items">
              <div>
                <img src="../../assets/main/xinxi.png" >
                <div class="num">2</div>
              </div>
              <div class="model_title">完善信息</div>
              <div class="desc">完善您的个人资料和技能信息</div>
            </div>
            <img class="arrowLeft" src="../../assets/main/arrow.png" >
            <div class="items">
              <div>
                <img src="../../assets/main/toudi.png" >
                <div class="num">3</div>
              </div>
              <div class="model_title">投递项目</div>
              <div class="desc">浏览平台上的各种项目需求并投递</div>
            </div>
            <img class="arrowLeft" src="../../assets/main/arrow.png" >
            <div class="items">
              <div>
                <img src="../../assets/main/gongzuo.png" >
                <div class="num">4</div>
              </div>
              <div class="model_title">开始工作</div>
              <div class="desc">确认项目的具体要求和工作安排，开始工作</div>
            </div>
          </div>
          <div class="toProject" @click="$router.push('/task')">前往接单</div>
        </div>
      </div>
      <div class="anli">
        <div class="title">成功案例</div>
        <ul class="anli-list">
          <li v-show="dataList.length > 0" class="anli-item" v-for="item in dataList" @click="openDetail(item.id)">
            <div class="personnel">
              <div class="person-img">
                <img style="cursor: pointer" :src="item.clientLogo">
              </div>
              <div class="anli-content">
                <div class="anli-title">
                  <div class="name" style="cursor: pointer">{{ item.title }}</div>
                </div>
                <div class="times">
                  <div>周期：{{item.projectCycle}}</div>
                  <div class="date">交付时间：{{item.deliveryDate}}</div>
                </div>
               </div>

            </div>

          </li>
        </ul>
        <div class="getMore" @click="$router.push('/anli')">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCase} from "@/api/anli";
import {apiBaseURL} from "@/utils/request";
  export default{
    data(){
      return {
        form:{
          currentPage: 1,
          pageSize:2
        },
        dataList: [],
        loading: false,
        total: 0
      }
    },
    methods: {
      async initList () {
        this.dataList = []
        const res = await getCase(this.form)
        if (res.code === 0) {
          res.data.forEach((item,index)=>{
            if(index < 2){
              if(item.clientLogo){
                item.clientLogo = apiBaseURL + item.clientLogo
              }else{
                item.clientLogo = apiBaseURL + '/avator/company.png'
              }
            }
            this.dataList.push(item)
          })

          this.total = res.count
        } else {
          this.$message.error('数据获取异常')
        }
      },
      changeConditions () {
        this.form.currentPage = 1
        this.initList()
      },
      changeOrder (name, type) {
        this.form.orderName = name
        this.form.orderType = type
        // 初始化分页信息
        this.form.currentPage = 1
        this.initList()
      },
      handleCurrentChange (val) {
        this.form.currentPage = val
        this.initList()
      },
      openDetail (id) {
        this.$router.push('/anli/detail/' + id)
      }
    },
    mounted() {
      this.initList()
    }
  }
</script>

<style lang="scss" scoped>

  .content{
    // margin: 20px auto;
     @media screen and (max-width: 530px) {
      margin-top: 80px;
    }

    .title{
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin: 20px 0;
    }
    .model1{
      // background-image: url(../../assets/main/xuqiuBg.png);
      position: relative;
      .bg{
        width: 100%;
        height: 520px;
      }
      .title{
        position: absolute;
        top: 40px;
        left:50%;
        margin-left: -160px;
      }
      .xuqiu{
        position: absolute;
        top: 120px;
        left: 10%;
        width: 80%;
        margin: 20px auto;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 530px) {
          width: 100%;
          left: 0;
        }
        .item{
          display: inline-block;
          width: calc(30% - 10px);
          margin: 10px;
          vertical-align: top;
          img{
            width: 70px;
            height: 70px;
            vertical-align: middle;
            margin-right: 10px;
          }
          .model_title{
            font-size: 16px;
            font-weight: 700;
            margin: 20px 0;
          }
        }
      }
    }
    .model2{
      position: relative;
      // background-image: url(../../assets/main/liuchengBg.png);
      .bg{
        width: 100%;
        height: 1100px;
        @media screen and (max-width: 530px) {
          height:1630px;
        }
      }
      .fabu{
        width: 80%;
        color: #fff;
        font-size: 14px;
        position: absolute;
        top: -120px;
        left: 10%;
        .backImg{
          width: 100%;
          &.PC{
            height: 370px;
            @media screen and (max-width: 530px) {
              display: none;
            }
          }
          &.mobile{
            display: none;
            height: 660px;
            @media screen and (max-width: 530px) {
              display: block;
            }
          }
        }
        .title{
          position: absolute;
          top: 20px;
          left:50%;
          margin-left: -110px;
        }
        .steps{
          display: flex;
          position: absolute;
          top: 90px;
          padding: 0 20px;
          // background-image: url(../../assets/main/main_banner.png);
          @media screen and (max-width: 530px) {
            display: block;
            top: 80px;
            width: 98%;
          }
          .item{
            flex: 1;
            // display: inline-block;
            width: calc(30% - 10px);
            background-color: rgba(121,95,246,.6);
            margin: 10px;
            padding: 20px;
            border-radius: 8px;
            @media screen and (max-width: 530px) {
              width: 100%;
            }
            .num{
              font-size: 28px;
              font-weight: 700;
              margin-right: 10px;
              vertical-align: middle;
            }
            .sub_title{
              font-size: 16px;
              font-weight: 700;
              span{
                padding-bottom: 5px;
                border-bottom: 1px solid #fff;
              }
            }
            .desc{
              margin-top: 5px;
              margin-left: 38px;
              line-height: 1.42;
            }
          }
        }
        .toPublish{
          width: 120px;
          background-color: #fff;
          color: #241975;
          text-align: center;
          height: 28px;
          line-height: 28px;
          border-radius: 28px;
          position: absolute;
          bottom: 40px;
          left: 50%;
          margin-left: -60px;
          cursor: pointer;
        }
      }
      .ruzhu{
        font-size: 14px;
        // background-image: url(../../assets/main/mainBanner3.png);
        position: absolute;
        top: 430px;
        @media screen and (max-width: 530px) {
          top:850px;
        }
        .backImg{
          width: 100vw;
          height: 220px;
          &.PC{
            @media screen and (max-width: 530px) {
              display: none;
            }
          }
          &.mobile{
            display: none;
            @media screen and (max-width: 530px) {
              display: block;
            }
          }
        }
        .title{
          position: absolute;
          top: -140px;
          left:50%;
          margin-left: -172px;
          @media screen and (max-width: 530px) {
           top: -285px;
          }
        }
        .steps{
          display: flex;
          margin: 20px auto;
          width: 80%;
          position: absolute;
          top: -90px;
          left: 10%;
          @media screen and (max-width: 530px) {
            top: -220px;
            width: 100%;
            left: 0;
          }
          .item{
            flex: 1;
            // display: inline-block;
            width: calc(30% - 15px);
            background-color: #fff;
            margin: 15px;
            padding: 30px 15px;
            @media screen and (max-width: 530px) {
              margin: 0;
              margin: 5px;
            }
            img{
              width: 56px;
              height: 56px;
              vertical-align: middle;
              margin-right: 10px;
              @media screen and (max-width: 530px) {
               width: 42px;
               height: 42px;
               margin:5px auto;
               display: block;
              }
            }
            .sub_title{
              font-size: 16px;
              font-weight: 700;
              @media screen and (max-width: 530px) {
                display: block;
                margin-top: 10px;
              }
            }
            .desc{
              margin-top: 20px;
              line-height: 1.42;
              @media screen and (max-width: 530px) {
               margin-top: 10px;
              }
            }
          }
        }
      }
      .progress{
        width: 80%;
        margin: 20px auto;
        font-size: 14px;
        text-align: center;
        position: absolute;
        top: 680px;
        left: 10%;
        @media screen and (max-width: 530px) {
          top:1070px;
        }
        .title{
          margin-bottom: 35px;
        }
        .arrowLeft{
          width: 50px;
          vertical-align: top;
          margin-top: 40px;
          @media screen and (max-width: 530px) {
            display: none;
          }
        }
        .items{
          display: inline-block;
          width: calc(25% - 60px);
          margin: 10px;
          @media screen and (max-width: 530px) {
            width: calc(50% - 20px);
          }
          &>div:first-child{
            position: relative;
            margin-bottom: 25px;
          }
          img{
            width: 70px;
            height: 70px;
            margin: 0 auto;
          }
          .num{
            background-color: #fff;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 50%;
            position: absolute;
            bottom: -6px;
            left:50%;
            margin-left: -10px;
            z-index: 1;
            color: #241975;
          }
          .model_title{
            font-size: 16px;
            font-weight: 700;
            margin: 10px 0;
          }
        }
      }
      .toProject{
        width: 120px;
        background-color: #440df6;
        color: #fff;
        text-align: center;
        height: 28px;
        line-height: 28px;
        border-radius: 28px;
        margin: 30px auto;
        cursor: pointer;
      }
    }
    .anli{
      width: 80%;
      margin: 20px auto;
      .getMore{
        width: 120px;
        border: 1px solid #795ff6;
        color: #795ff6;
        text-align: center;
        height: 28px;
        line-height: 28px;
        border-radius: 28px;
        margin: 30px auto;
        cursor: pointer;
      }
    }
    .anli-list{
      padding: 0;
      margin: 0;
      .anli-item{
        background-color: #fff;
        font-size: 16px;
        margin-top: 15px;
        width: calc(50% - 10px);
        display: inline-block;
        margin: 10px;
        cursor: pointer;
        @media screen and (max-width: 760px) {
          width: 100%;
          display: block;
          margin: 10px 0;
        }
        &:nth-child(odd){
          margin-left: 0;
        }
        &:nth-child(even){
          margin-right: 0;
        }
        .personnel{
          .person-img{
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
            img{
              width: 100%;
            }
          }
        }
        .anli-content{
          padding: 15px;
          .anli-title{
            margin-bottom: 10px;
            .name{
              font-size: 20px;
              font-weight: 700;
              display: inline-block;
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            /* .price{
              font-size: 20px;
              font-weight: 700;
              color: #795FF6;
              display: inline-block;
              float: right;
            } */
          }
          .times{
            div{
              display: inline-block;
              &.date{
                float: right;
              }
            }
          }
        }

      }
    }


  }

</style>
