<template>
  <div class="container" v-loading="loading">
    <div class="work-wrapper">
      <div class="title">作品展示</div>
      <div class="list" v-if="workList.length>0">
        <div class="works" v-for="item in workList">
          <div class="img-wrapper">
            <img :src="item.imageList[0]" style="cursor: pointer" @click="viewFetchWorksById(item.id)">
          </div>
          <div class="work-content">
            <div class="work-title" style="cursor: pointer" @click="viewFetchWorksById(item.id)">{{ item.title }}</div>
            <div class="desc">{{ item.content }}</div>

            <div class="tags">
  <!--            <div class="viewers">-->
  <!--              <i class="iconfont icon-chakan"></i>-->
  <!--              <span>345</span>-->
  <!--            </div>-->
              <span>{{ item.skills.replaceAll('、', ' / ')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; text-align: center; color: gray; font-size: 14px;margin:50px 0" v-if="workList.length === 0">
        暂未上传作品
      </div>
    </div>
    <work-preview :item="workItem" v-if="showWork" @close="showWork = false"></work-preview>
  </div>
</template>

<script>
import WorkPreview from '@/components/workPreview'
import {apiBaseURL} from "@/utils/request";
import {getUserInfo, getAllComment, fetchWorksById} from "@/api/personnel";
export default {
  components:{ WorkPreview },
  props: {
    user: {
      type: Object
    },
    isMobile: {
      type: Boolean
    }
  },
  data () {
    return {
      data:{
        type:['数据挖掘','数据采集']
      },
      workList: [],
      loading: false,
      commentLoading: false,
      commentList: [],
      workItem: {},
      showWork: false
    }
  },
  methods: {
    async getUserInfo () {
      this.loading = true
      const res = await getUserInfo({ id: this.user.id })
      if (res.code === 0) {
        this.workList = res.data.worksList
        for (var i = 0; i < this.workList.length; i++) {
          for (var j = 0; j < this.workList[i].imageList.length; j++) {
            this.workList[i].imageList[j] = apiBaseURL + this.workList[i].imageList[j]
          }
        }
      }
      this.loading = false
    },
    async viewFetchWorksById (id) {
      if(this.isMobile){
        this.$router.push('/workDetail/' + id)
      }else{
        this.loading = true
        const res = await fetchWorksById({ id: id })
        this.workItem = res.data
        this.showWork = true
        this.loading = false
      }
    }
  },
  mounted () {
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>
.work-wrapper{
  background-color: #fff;
  border-radius: 3px;
  padding: 30px;
  margin-bottom: 20px;
  position: relative;
  .title{
    font-size: 18px;
    font-weight: 700;
  }

  .works{
    position: relative;
    margin: 10px 15px 0 0;
    padding: 20px 0;
    border-bottom: 1px solid #EDEDED;
    &:last-child{
      border: none;
    }
    .img-wrapper{
      width: 200px;
      height: 130px;
      margin-right: 20px;
      display: inline-block;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
      }
    }
    .work-content{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 220px);
      .work-title{
        font-size: 16px;
        font-weight: 700;
        margin: 12px 0;
      }
      .desc{
        font-size: 14px;
        line-height: 1.6;
        color: #4d4d4d;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .viewers{
        float: right;
        .iconfont{
          margin-right: 5px;
          font-size: 14px;
        }
      }
      .tags{
        font-size: 14px;
        color: #8d8d8d;
        margin: 15px 0;
      }
    }

  }
}
</style>
