<template>
  <div class="container">
    <!-- <div class="swiper">
      <img src="../../assets/images/bg.png" >
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu">招募人才</div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="left-menu">
        <el-menu :default-active="index" class="el-menu-vertical-demo" :mode="isMobile?'horizontal':'vertical'">
<!--          <router-link to="/perCenter">-->
<!--            <el-menu-item index="1">-->
<!--              <i class="iconfont icon-qiyexinxi" v-if="!isMobile"></i>-->
<!--              <span>企业信息</span>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
<!--          <router-link to="/perCenter/comCert">-->
<!--            <el-menu-item index="2">-->
<!--              <i class="iconfont icon-qiyerenzheng1" v-if="!isMobile"></i>-->
<!--              <span>企业认证</span>-->
<!--            </el-menu-item>-->
<!--          </router-link>-->
          <router-link to="/perCenter/perForm">
            <el-menu-item index="3">
              <i class="iconfont icon-gerenxinxi" v-if="!isMobile"></i>
              <span>个人信息</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/perCert">
            <el-menu-item index="4">
              <i class="iconfont icon-shimingrenzheng" v-if="!isMobile"></i>
              <span>个人认证</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/resetPass" v-if="!isMobile">
            <el-menu-item index="5">
              <i class="iconfont icon-xiugaimima"></i>
              <span>修改密码</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/invitation" v-if="!isMobile">
            <el-menu-item index="6">
              <i class="iconfont icon-kefujingli-"></i>
              <span>推广邀请</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/notification">
            <el-menu-item index="7">
              <i class="iconfont icon-xiaoxitongzhi" v-if="!isMobile"></i>
              <span>消息通知</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/integral" v-if="!isMobile">
            <el-menu-item index="8">
              <i class="iconfont icon-jifen1" style="font-size: 18px;"></i>
              <span>积分兑换</span>
            </el-menu-item>
          </router-link>
          <router-link to="/perCenter/exchange" v-if="!isMobile">
            <el-menu-item index="9">
              <i class="iconfont icon-jilu"></i>
              <span>兑换记录</span>
            </el-menu-item>
          </router-link>
        </el-menu>
      </div>
      <div class="wrapper">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import { _isMobile } from '@/utils/index';
  export default {
    data () {
      return {
        index: '3',
        isMobile: false
      }
    },
    mounted() {
      if (_isMobile()) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      if(this.$route.path.indexOf('invitation') !== -1){
        this.index = '6'
      }else if(this.$route.path.indexOf('comCert') !== -1){
        this.index = '2'
      }else if(this.$route.path.indexOf('perForm') !== -1){
        this.index = '3'
      }else if(this.$route.path.indexOf('perCert') !== -1){
        this.index = '4'
      }else if(this.$route.path.indexOf('resetPass') !== -1){
        this.index = '5'
      }else if(this.$route.path.indexOf('notification') !== -1){
        this.index = '7'
      }else if(this.$route.path.indexOf('integral') !== -1){
        this.index = '8'
      }else if(this.$route.path.indexOf('exchange') !== -1){
        this.index = '9'
      }else{
        this.index = '1'
      }
    },
    methods: {

    },
    computed: {
      // 监听数据
      getnewValue () {
        return this.$route.path
      }
    },
    watch: {
      //  监听改变事件
      getnewValue: {
        handler (newValue, oldValue) {
          if(newValue.indexOf('invitation') !== -1){
            this.index = '6'
          }else if(newValue.indexOf('comCert') !== -1){
            this.index = '2'
          }else if(newValue.indexOf('perForm') !== -1){
            this.index = '3'
          }else if(newValue.indexOf('perCert') !== -1){
            this.index = '4'
          }else if(newValue.indexOf('resetPass') !== -1){
            this.index = '5'
          }else if(newValue.indexOf('notification') !== -1){
            this.index = '7'
          }else if(newValue.indexOf('integral') !== -1){
            this.index = '8'
          }else if(newValue.indexOf('exchange') !== -1){
            this.index = '9'
          }else{
            this.index = '1'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content{
    width: 80%;
    margin: 15px auto;
    @media screen and (max-width: 530px) {
      margin-top: 80px;
      width: 100%;
    }
    .left-menu{
      background-color: #fff;
      border-radius: 3px;
      padding: 15px 0;
      width: 240px;
      min-height: 320px;
      display: inline-block;
      text-align: center;
      margin-right: 20px;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        min-height: auto;
        text-align: left;
      }
      .el-menu{
        border: none;
        .el-menu-item{
          height: 42px;
          line-height: 42px;
          // font-size: 16px;
          @media screen and (max-width: 530px) {
            display: inline-block;
            color: #8d8d8d;
            font-size: 18px;
          }
          &:hover{
            cursor: pointer;
            background-color: rgba(121, 95, 246, .05);
            @media screen and (max-width: 530px) {
              background-color: transparent;
              color: #4d4d4d;
            }
          }
          &.is-active{
            background-color: #f9f8ff;
            border-left: 2px solid #795FF6;
            color: #795FF6;
            @media screen and (max-width: 530px) {
              border: none;
              color: #3d3d3d;
              background-color: #fff;
            }
          }
          .iconfont{
            margin-right: 20px;
          }
        }
      }
    }
    .wrapper{
      display: inline-block;
      width: calc(100% - 260px);
      vertical-align: top;
      @media screen and (max-width: 530px) {
        display: block;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
</style>
