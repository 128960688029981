<template>
  <div class="container" v-loading="loading">
    <div class="title">发起工单</div>
    <div class="form-wrapper">
      <el-form ref="form" :model="form" label-width="85px">
        <el-form-item label="问题概述:" required>
          <el-input v-model="form.summary" size="mini"></el-input>
        </el-form-item>
<!--        <el-form-item label="工单类型:">-->
<!--          <el-radio-group v-model="form.resource">-->
<!--            <el-radio size="mini" label="全部" border></el-radio>-->
<!--            <el-radio size="mini" label="提现" border></el-radio>-->
<!--            <el-radio size="mini" label="任务" border></el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item label="问题描述:" required>
          <el-input type="textarea" v-model="form.description" :rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {commit} from "@/api/workorder/workorder";
  export default {
    data () {
      return {
        loading: false,
        form: {}
      }
    },
    mounted() {

    },
    methods: {
      async onSubmit(){
        if (!this.form.summary) {
          this.$message.warning('问题概述不可为空')
          return
        }
        if (!this.form.description) {
          this.$message.warning('问题描述不可为空')
          return
        }
        this.loading = true
        const res = await commit(this.form)
        if (res.code === 0) {
          this.form.summary=''
          this.form.description=''
          this.$message.success('提交成功')
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
  .operate-btn{
    color:#795FF6;
    background-color: #f9f8ff;
    border-radius: 3px;
    padding: 5px 10px;
  }
</style>
