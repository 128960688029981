import request from '@/utils/request'
export function applyWithdraw (data) {
  return request({
    url: '/personalUser/applyWithdraw',
    method: 'POST',
    data
  })
}
export function getAliPayAccountByUserId () {
  return request({
    url: '/personalUser/getAliPayAccountByUserId',
    method: 'POST'
  })
}
