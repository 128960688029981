<template>
  <div ref="wrapper" class="wraper" style="height: 100%;">
    <section class="top-wrap">
      <header-wrap ref="header"/>
      <section class="app-main" style="padding-bottom: 40px">
        <router-view />
      </section>
      <bottom-wrap v-if="!isMobile"></bottom-wrap>
    </section>
    <div class="dialog" v-if="showDialog"  @click=" showDialog = false">
      <div class="content">
        <i class="iconfont icon-guanbi2fill" @click=" showDialog = false"></i>
        <div class="img-wrapper"><img src="@/assets/images/personal-msg.png" alt=""></div>
        <div class="title">完善个人信息</div>
        <div style="font-size: 14px;">完善您的个人信息后才能接取任务</div>
        <div class="btn">
          <el-button type="primary" size="small" @click="$router.push('/perCenter/perForm')">立即前往</el-button>
        </div>
      </div>
    </div>
    <!-- <div class="backTop"  @click="backTop()">
      <i class="el-icon-arrow-up" style="font-weight: bold;"></i>
    </div>
    <div class="backBottom"  @click="toBottom()">
      <i class="el-icon-arrow-down" style="font-weight: bold;"></i>
    </div> -->
  </div>
</template>

<script>
import HeaderWrap from '@/layout/components/HeaderWrap'
import bottomWrap from '@/layout/components/bottomWrap'
import { _isMobile } from '@/utils/index';
import SockJS from  'sockjs-client';
import  Stomp from 'stompjs';

var topCount = 1;
var bottomCount = 1;
export default {
  components: {
    HeaderWrap,
    bottomWrap
  },
  data () {
    return {
      navPath: '/',
      num: 0,
      isConnect: false,
      custom: {},
      customType:{},
      dataList: [],
      showDialog: false,
      isMobile: false
    }
  },
  methods:{
    backTop () {
      topCount = 1
      this.toTopStep(50)
    },
    toTopStep (h) {
      document.documentElement.scrollTop -= h
      if (document.documentElement.scrollTop > 0) {
        if (document.documentElement.scrollHeight > 3000) {
          if (topCount <= 20) {
            var c = setTimeout(() => this.toTopStep(200), 16)
          } else {
            clearTimeout(c)
            document.documentElement.scrollTop = 0
          }
          topCount++
        } else {
          var c = setTimeout(() => this.toTopStep(50), 16)
        }
      } else {
        clearTimeout(c)
      }
    },
    toBottom () {
      bottomCount = 1
      this.toBottomStep(50)
    },
    toBottomStep (h) {
      var clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.documentElement.scrollHeight
      var height = scrollHeight - clientHeight
      document.documentElement.scrollTop += h
      if (document.documentElement.scrollTop < height) {
        if (document.documentElement.scrollHeight > 3000) {
          if (bottomCount <= 20) {
            var c = setTimeout(() => this.toBottomStep(200), 16)
          } else {
            clearTimeout(c)
            document.documentElement.scrollTop = height
          }
          bottomCount++
        } else {
          var c = setTimeout(() => this.toBottomStep(50), 16)
        }
      } else {
        clearTimeout(c)
      }
    }

  },
  async mounted () {
    this.navPath = this.$route.path
    if (_isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
}
</script>

<style lang="scss" scoped>
  html,body {
    height: 100%;
  }
  .dialog{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .content{
      width: 22%;
      min-width: 240px;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      div{
        margin: 15px 0;
      }
      .img-wrapper{
        width: 80px;
        margin: 30px auto 0;
        img{
          width: 100%;
          margin-left: 6px;
        }
      }
      .iconfont{
        color: #c7c7c7;
        font-size: 20px;
        float: right;
        cursor: pointer;
      }
      .title{
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0;
      }
      .btn{
        margin-top: 20px;
      }
    }
  }
  .backTop{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #999aaa;
    position: fixed;
    right: 10px;
    bottom: 100px;
    z-index: 99;
    cursor: pointer;
    font-size: 20px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0,0,0,.12);
  }
  .backBottom{
    z-index: 99;
    cursor: pointer;
    position: fixed;
    right: 10px;
    bottom: 48px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #999aaa;
    background-color: #fff;
    // border-radius: 50%;
    margin-bottom: 10px;
    box-shadow: 0 0 6px rgba(0,0,0,.12);
    font-size: 20px;
  }
</style>
<style>
  .stylecss .el-notification__title {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
