import request from '@/utils/request'
export function fetchAllTopicType () {
  return request({
    url: '/community/fetchAllTopicType',
    method: 'POST'
  })
}
export function postTopic (data) {
  return request({
    url: '/community/postTopic',
    method: 'POST',
    data
  })
}
export function fetchTopic (data) {
  return request({
    url: '/community/fetchTopic',
    method: 'POST',
    data
  })
}
export function fetchTopicDetail (data) {
  return request({
    url: '/community/fetchTopicDetail',
    method: 'POST',
    data
  })
}
export function sendComment (data) {
  return request({
    url: '/community/sendComment',
    method: 'POST',
    data
  })
}
export function sendReply (data) {
  return request({
    url: '/community/sendReply',
    method: 'POST',
    data
  })
}
export function getCountOfUnread () {
  return request({
    url: '/community/getCountOfUnread',
    method: 'POST'
  })
}
export function fetchMessage (data) {
  return request({
    url: '/community/fetchMessage',
    method: 'POST',
    data
  })
}
export function updateToReadById (data) {
  return request({
    url: '/community/updateToReadById',
    method: 'POST',
    data
  })
}
export function updateAllToRead () {
  return request({
    url: '/community/updateAllToRead',
    method: 'POST'
  })
}
export function deleteTopic (data) {
  return request({
    url: '/community/deleteTopic',
    method: 'POST',
    data
  })
}
