<template>
  <div class="container">
    <div class="content" v-loading="loading">
      <div class="anli-wrapper">
        <div class="anli-item">

          <div class="anli-content">
            <div class="anli-title">
              <span class="name">{{ data.title }}</span>
              <span class="price">案例金额：<span>{{ data.price }}</span></span>
            </div>
            <div class="times">
              <div>周期：{{data.projectCycle}}</div>
              <div class="date">交付时间：{{data.deliveryDate}}</div>
              <div class="dataNum">
                <div class="lookUp">
                  <i class="iconfont icon-chakan"></i>
                  <span class="num">{{data.read}}</span>
                </div>
                <div class="clickNum">
                  <i class="iconfont icon-dianzan"></i>
                  <span class="num">{{data.like}}</span>
                </div>
              </div>
            </div>
            <div class="anli_content" v-html="data.content"></div>
          </div>
          <div class="click-btn">
            <i class="iconfont icon-dianzan2"></i>
            <div class="number">{{data.like}}</div>
          </div>
          <div class="share">
            <div class="title">分享</div>
            <vshare style="width:-webkit-fit-content;margin: 20px auto;" :vshareConfig="vshareConfig"></vshare>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  var userId
  import {getCaseByid} from "@/api/anli";
  import {apiBaseURL} from "@/utils/request";
  import { _isMobile } from '@/utils/index';
  import vshare from 'vshare'
export default {
  components:{vshare},
  data () {
    return {
      data: {},
      loading: true,
      value: 3.7,
      isMobile: false,
      current: 1,
      vshareConfig:{
        shareList:['copy','sqq','qzone','tsina','weixin']
      }
    }
  },
  methods: {
    async getCaseByid () {
      this.loading = true
      const res = await getCaseByid({ id: userId })
      if (res.code === 0) {
        this.data = res.data
        this.data.clientLogo = this.data.clientLogo ? apiBaseURL + this.data.clientLogo : apiBaseURL + '/avator/company.png'
      } else {
        this.$message.error('案例信息获取失败！')
      }
      this.loading = false
    }
  },
  mounted () {
    if (_isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    userId = this.$route.params.id
    this.getCaseByid()
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-rate {
  display: inline;
  vertical-align: text-bottom;
}
/deep/.el-rate__icon{
  margin-right: 1px;
}
.swiper{
  position: relative;
  @media screen and (max-width: 530px) {
    display: none;
  }
  img{
    width: 100%;
    height: 320px;
  }
}
.content{
  width: 80%;
  margin: 10px auto;
  @media screen and (max-width: 530px) {
    width: 100%;
  }
  .anli-wrapper{
    width: calc(100% - 0px);
    display: inline-block;
    margin-top: 70px;

  }
  .anli-item{
    background-color: #fff;
    font-size: 16px;
    padding: 30px 30px 20px;
    margin-top: 10px;
    .anli-content{
      div{
        margin-bottom: 10px;
      }

      .anli-title{
        margin-bottom: 20px;
        .name{
          font-size: 22px;
          font-weight: 700;
          display: inline-block;
        }
        .price{
          font-size: 18px;
          font-weight: 700;
          display: inline-block;
          float: right;
          span{
            color: #795FF6;
          }
          @media screen and (max-width: 530px) {
            display: none;
          }
        }
      }
      .times{
        margin-top: 20px;
        color: #6d6d6d;
        border-bottom: 1px solid #cdcdcd;
        padding-bottom: 10px;
        div{
          display: inline-block;
          margin-right: 25px;
          .iconfont{
            margin-right: 5px;
            font-size: 18px;
          }
          &.dataNum{
            margin-left: 30px;
            @media screen and (max-width: 530px) {
              display: block;
              margin-left: 0;
            }
          }
        }
      }

    }
    .click-btn{
      background-color: #795ff6;
      width: 120px;
      height: 50px;
      border-radius: 50px;
      margin: 50px auto;
      color: #fff;
      text-align: center;
      .iconfont{
        display: inline-block;
        margin: 5px 0;
        font-size: 16px;
      }
    }
    .share{
      margin: 30px auto;
      border-top: 1px solid #dcdcdc;
      position: relative;
      @media screen and (max-width: 530px) {
        display: none;
      }
      .title{
        width: 80px;
        background-color: #fff;
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -40px;
        text-align: center;
        color: #acacac;
        font-size: 14px;
      }
      /deep/.bdshare-button-style0-24{
        a{
          padding-left: 35px;
          margin-right: 12px;
        }
        .bds_copy{
          background-image: url(../../../assets/images/copy.png);
          background-position: unset;
          background-size: contain;
        }
        .bds_sqq{
          background-image: url(../../../assets/images/qq.png);
          background-position: unset;
          background-size: contain;
        }
        .bds_qzone{
          background-image: url(../../../assets/images/qzone.png);
          background-position: unset;
          background-size: contain;
        }
        .bds_tsina{
          background-image: url(../../../assets/images/sina.png);
          background-position: unset;
          background-size: contain;
        }
        .bds_weixin{
          background-image: url(../../../assets/images/wechat.png);
          background-position: unset;
          background-size: contain;
        }
      }
    }
  }

}
</style>
