<template>
  <div class="container">
    <!-- <div class="swiper">
      <img src="../../assets/images/bannerBg.png" >
      <div class="wrapper">
        <div class="title">专业发布任务平台免费</div>
        <div class="desc">免费发布需求</div>
        <div class="btns-group">
          <div class="btn fabu" @click="$router.push('/release')">发布需求</div>
          <div class="btn zhaomu">招募人才</div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="form-wrapper">
        <el-form :inline="true" :model="form" class="demo-form-inline" @submit.native.prevent>
          <el-form-item class="item" label="技术类型：">
            <el-radio-group v-model="form.type" @change="changeConditions">
              <el-radio size="mini" label="" name="type" border>全部</el-radio>
              <el-radio size="mini" label="1" name="type" border>数据采集</el-radio>
              <el-radio size="mini" class="pc-radio" label="2" name="type" border>RPA脚本开发</el-radio>
              <el-radio size="mini" class="mobile-radio" label="2" name="type" border>RPA</el-radio>
              <el-radio size="mini" label="3" name="type" border>研究报告</el-radio>
              <el-radio size="mini" label="4" name="type" border>数据分析</el-radio>
              <el-radio size="mini" label="5" name="type" border>NLP</el-radio>
              <el-radio size="mini" label="6" name="type" border>AI</el-radio>
            </el-radio-group>
          </el-form-item>
          <br>
          <el-form-item class="item" label="工作周期：">
            <el-radio-group v-model="form.isFullTime" @change="changeConditions">
              <el-radio size="mini" label="" name="time" border>全部</el-radio>
              <el-radio size="mini" label="1" name="time" border>长期</el-radio>
              <el-radio size="mini" label="0" name="time" border>短期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="item status" label="工作方式：">
            <el-radio-group v-model="form.workWay" @change="changeConditions">
              <el-radio size="mini" value="" label="" name="mode" border>全部</el-radio>
              <el-radio size="mini" value="0" label="0" name="mode" border>远程</el-radio>
              <el-radio size="mini" value="1" label="1" name="mode" border>驻场</el-radio>
            </el-radio-group>
          </el-form-item>
          <br>
          <el-form-item class="item search">
            <el-input size="mini" v-model="form.keyword" placeholder="请输入您要查询的内容" @change="changeConditions"></el-input>
            <i class="el-icon-search"></i>
          </el-form-item>
        </el-form>
      </div>
      <div class="sort-wrapper">
        <div class="filter-item">
          <span>综合</span>
          <div class="icons">
            <i :style="form.orderName === 0 && form.orderType === 1 ? 'color: #A068FF' : ''" class="iconfont icon-xiangshang1" @click="changeOrder(0,1)"></i>
            <i :style="form.orderName === 0 && form.orderType === 0 ? 'color: #A068FF' : ''" class="iconfont icon-xiangxia1" @click="changeOrder(0,0)"></i>
          </div>
        </div>
        <div class="filter-item">
          <span>工作经验</span>
          <div class="icons">
            <i :style="form.orderName === 1 && form.orderType === 1 ? 'color: #A068FF' : ''" class="iconfont icon-xiangshang1" @click="changeOrder(1,1)"></i>
            <i :style="form.orderName === 1 && form.orderType === 0 ? 'color: #A068FF' : ''" class="iconfont icon-xiangxia1" @click="changeOrder(1,0)"></i>
          </div>
        </div>
        <div class="filter-item">
          <span>日薪</span>
          <div class="icons">
            <i :style="form.orderName === 2 && form.orderType === 1 ? 'color: #A068FF' : ''" class="iconfont icon-xiangshang1" @click="changeOrder(2,1)"></i>
            <i :style="form.orderName === 2 && form.orderType === 0 ? 'color: #A068FF' : ''" class="iconfont icon-xiangxia1" @click="changeOrder(2,0)"></i>
          </div>
        </div>
      </div>
      <ul class="personnnel-list" v-loading="loading" style="min-height: 200px">
        <li v-show="!dataList || dataList.length === 0" style="width: 100%; height: 200px; line-height: 200px; font-size: 20px; text-align: center; color: gray; background-color: #FFFFFF">
          暂无数据
        </li>
      	<li v-show="dataList.length > 0" class="personnnel-item" v-for="item in dataList">
          <div class="personnel">
            <div class="person-img">
              <img style="cursor: pointer" :src="item.headUrl" @click="openDetail(item.id)">
            </div>
            <div class="personnnel-content">
              <div class="personnnel-title">
                <span class="name" style="cursor: pointer"  @click="openDetail(item.id)">{{ item.nickname }}</span>
                <span class="job" style="cursor: pointer"  @click="openDetail(item.id)">{{ item.position }}</span>
<!--                <img src="@/assets/images/vips.png" >-->
                <span class="renzheng" v-if="item.personalAuthentication === 1 && item.isTeam === 0">已认证</span>
                <span class="renzheng" v-if="item.isTeam === 1">团队</span>
              </div>
              <div class="exprience">
                <span class="price"><span>{{ item.dailySalary }}</span> 元/天</span>
                <span >{{ item.demandStr }}</span> |
                <span >{{ item.workExperience }}年经验</span>
<!--                <span >工作性质：兼职</span>-->
              </div>
             </div>

          </div>
          <div class="demand" style="line-height: 35px">
            个人简介：{{ item.describe }}
          </div>
          <div class="tags">
            <div class="tag" v-for="tag in item.skill.split('、')">{{ tag }}</div>
          </div>
        </li>
      </ul>
      <div style="background-color: #FFFFFF; margin-top: 5px;padding: 20px 0px">
        <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      </div>
      <div class="banner">
        <img src="@/assets/images/bg.png" alt="">
        <div class="desc-text">
          <div class="title">
            <img src="../../assets/images/line.png" >
            <span>赚钱小手册</span>
            <img src="../../assets/images/line.png" >
          </div>
          <div class="text">
            <span>赚钱选52</span>
            <span>轻松赚大钱</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchTask} from "@/api/personnel";
import {apiBaseURL} from "@/utils/request";
  export default{
    data(){
      return {
        form:{
          type: '',
          workWay: '',
          isFullTime: '',
          keyword: '',
          orderName: 0,
          orderType: 0,
          currentPage: 1
        },
        total: 0,
        dataList: [],
        loading: false
      }
    },
    methods: {
      async initList () {
        this.loading = true
        this.dataList = []
        const res = await fetchTask(this.form)
        if (res.code === 0) {
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].headUrl = res.data[i].headUrl ? apiBaseURL + res.data[i].headUrl : apiBaseURL + '/avator/company.png'
            res.data[i].demandStr = res.data[i].demandList.join(' ')
          }
          this.dataList = res.data
          this.total = res.count
        } else {
          this.$message.error('数据获取异常')
        }
        this.loading = false
      },
      changeConditions () {
        this.form.currentPage = 1
        this.initList()
      },
      changeOrder (name, type) {
        this.form.orderName = name
        this.form.orderType = type
        // 初始化分页信息
        this.form.currentPage = 1
        this.initList()
      },
      handleCurrentChange (val) {
        this.form.currentPage = val
        this.initList()
      },
      openDetail (id) {
        this.$router.push('/personnel/detail/' + id)
      }
    },
    mounted() {
      this.initList()
    }
  }
</script>

<style lang="scss" scoped>
/deep/.el-pager li.active,/deep/.el-pager li:hover,
/deep/.el-pagination button:hover{
  color: #a068ff;
}
/deep/.el-pagination .btn-prev,
/deep/.el-pagination .btn-next,
/deep/.el-pager li{
  background-color: transparent;
  color: #6d6d6d;
}
/deep/.el-pagination{
  text-align: right;
}
/deep/.el-form-item__content,/deep/.el-form--inline .el-form-item__content{
	@media screen and (max-width: 530px) {
	  line-height: unset;
	  vertical-align: bottom;
	}
}
/deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
	@media screen and (max-width: 530px) {
	  font-size: 16px;
	  padding-right: 0;
    vertical-align: top;
	}
}

  .content{
    width: 80%;
    margin: 20px auto;
    @media screen and (max-width: 530px) {
      width: 100%;
      margin-top: 80px;
    }
    @media screen and (min-width: 945px) and (max-width: 1240px) {
      width: 90%;
    }
    @media screen and (min-width: 850px) and (max-width: 945px){
      width: 100%;
    }
    @media screen and (max-width: 849px){
      width: 100%;
    }
    .form-wrapper{
      background-color: #fff;
      border-radius: 3px;
      padding:20px 30px;
      height: 150px;
	  @media screen and (max-width: 530px) {
	    padding: 15px;
      height: auto;
	  }
	  .pc-radio{
	    @media screen and (max-width: 530px) {
	      display:none;
	    }
	  }
	  .mobile-radio{
	    display:none;
	    @media screen and (max-width: 530px) {
	      display:inline-block;
	    }
	  }
      /deep/.el-radio__input {
        display: none;
      }
      .item:first-child /deep/.el-form-item__content{
        @media screen and (max-width: 530px) {
          width: calc(100% - 85px);
        }
      }
      .search{
         display: block;
        /deep/.el-form-item__content{
          width: 100%;
          max-width: 420px;
        }
      }

      /* .left {
        float: left;
      }
      .right {
        text-align: right;
      } */
      .el-radio.is-bordered, .el-input__inner{
        border-radius: 40px;
      }
      .el-form-item{
        margin: 0;
        &.status{
          margin-left: 30px;
          float: right;
          @media screen and (max-width: 530px) {
            margin: 0;
            float: none;
          }
        }
        &.search{
          width: 420px;
          float: right;
          @media screen and (max-width: 530px) {
            margin-top: 10px;
            width: 100%;
            float: none;
          }
        }
        &.search .el-form-item__content{
          width: 100%;
          position: relative;
          .el-icon-search{
            color: #aaa;
            font-size: 16px;
            position: absolute;
            top: 14px;
            right: 15px;
            @media screen and (max-width: 530px) {
              top: 7px;
            }
          }
        }
      }

    }
    .sort-wrapper{
      background-color: #fff;
      border-radius: 3px;
      font-size: 16px;
      padding:20px 30px;
      margin-top: 15px;
      .filter-item{
        display: inline-block;
        margin: 0 20px;
		@media screen and (max-width: 530px) {
		  margin: 0;
		  margin-right: 15px;
		}
        span{
          margin-right: 10px;
        }
        .icons{
          display: inline-block;
          vertical-align: bottom;
          .iconfont{
            display:block;
            width:20px;
            height: 10px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

    }
    .personnnel-list{
      padding: 0;
      margin: 0;
      .personnnel-item{
        background-color: #fff;
        border-radius: 3px;
        font-size: 16px;
        padding: 30px 30px 20px;
        margin-top: 15px;
        width: calc(50% - 10px);
        display: inline-block;
        margin: 10px;
        @media screen and (max-width: 760px) {
          width: 100%;
          display: block;
          margin: 10px 0;
        }
        &:nth-child(odd){
          margin-left: 0;
        }
        &:nth-child(even){
          margin-right: 0;
        }
        .personnel{
          .person-img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            img{
              width: 100%;
              border-radius: 50%;
            }
          }
        }
        .personnnel-content{
          display: inline-block;
          width: calc(100% - 70px);
          div{
            margin-bottom: 10px;
          }
          .personnnel-title{
            .name,.job{
              font-size: 20px;
              font-weight: 700;
              margin-right: 5px;
            }
            img{
              width: 16px;
            }
          }
          .exprience{
            font-size: 14px;
            color: #8d8d8d;
            /* span{
              margin: 0 10px;
            } */
            @media screen and (max-width: 720px) {
              /* display: block;
              margin: 10px 0; */
              font-size: 16px;
            }
            span:nth-child(2){
              margin-left: 10px;
            }
          }
          .renzheng{
            font-size: 12px;
            padding: 2px 5px;
            border: 1px solid #7B62F7;
            color: #7B62F7;
            border-radius: 3px;
            float: right;
            margin-top: 2px;
          }
        }
        .demand{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 10px 0 20px;
          height: 70px;
        }
        .tags {
          border-top: 1px solid #dcdcdc;
          padding-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .tag{
            background-color: #f7f6ff;
            font-size: 14px;
            padding: 4px 12px;
            border-radius: 20px;
            margin-right: 10px;
            display: inline-block;
          }
        }

        .price{
          margin-bottom: 30px;
          color: #795FF6;
          span{
            font-size: 22px;
            font-weight: 700;
          }
        }
          /* .btn{
            background-color: #795FF6;
            color: #fff;
            width: 100px;
            height: 28px;
            line-height: 28px;
            border-radius: 28px;
            margin: 0 auto;
            &.recruited{
              background-color: #EFEFEF;
              color: #8d8d8d;
            }
            .iconfont{
              font-size: 20px;
              vertical-align: bottom;
            }
          } */
      }
    }

    .banner{
      margin: 15px 0;
      position: relative;
      @media screen and (max-width: 530px) {
        display: none;
      }
      img{
        width: 100%;
        height: 220px;
      }
      .desc-text{
        position: absolute;
        top: 50px;
        left: 150px;
        text-align: center;
        color: #fff;
        .title{
          font-size: 16px;
          letter-spacing: 3px;
          margin: 10px 0 40px;
          img{
            width: 40px;
            height: auto;
            margin: 0 10px;
            vertical-align: middle;
            &:last-child{
              transform: rotate(-180deg);
            }
          }
        }
        .text span{
          font-size: 28px;
          font-weight: 700;
          margin: 0 10px;
          letter-spacing: 1px;
        }
      }
    }
  }

</style>
