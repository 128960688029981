import request from '@/utils/request'
export function getUserInfo () {
  return request({
    url: '/fundingRecords/userInfo',
    method: 'POST'
  })
}
export function signIn () {
  return request({
    url: '/sys/user/signIn',
    method: 'POST'
  })
}
export function fetchList (data) {
  return request({
    url: '/fundingRecords/selectFunding',
    method: 'POST',
    data
  })
}
export function getIntegralRecord (data) {
  return request({
    url: '/personalUser/getIntegralRecord',
    method: 'POST',
    data
  })
}

