import request from '@/utils/request'

// 问题列表
export function getTopic (data) {
  return request({
    url: '/getTopic',
    method: 'POST',
    data
  })
}
// 提交答案
export function answerQuestions (data) {
  return request({
    url: '/answerQuestions',
    method: 'POST',
    data
  })
}
