<template xmlns="http://www.w3.org/1999/html">
  <section class="bottom-wrap">
    <div class="msg">
      <div class="tips">
        <span>
          Copyright © 2015-2024  数据银河  版权所有
          <span>
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="position: relative;top: 5px;display:inline-block;text-decoration:none;line-height:20px;">
              <p style="float:left;line-height:20px;margin: 0px 0px 0px 5px;">京ICP备16024387号-4</p>
            </a>
          </span>
          <span >
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402012383" style="position: relative;top: 5px;display:inline-block;text-decoration:none;line-height:20px;">
           <img src="../../assets/images/wangjing.png" style="float:left;width: 14px;margin-right: 8px;margin-top: 3px;"/>
           <p style="float:left;line-height:20px;margin: 0px 0px 0px 5px;">京公网安备 11011402012383号</p>
         </a>
          </span>
          <span >
          <a target="_blank" href="/icp-cert.png" style="position: relative;top: 5px;display:inline-block;text-decoration:none;line-height:20px;">
           <p style="float:left;line-height:20px;margin: 0px 0px 0px 5px;">京ICP证B2-20220892</p>
         </a>
          </span>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style lang="scss">
  .bottom-wrap{
    width: 100%;
    background: #3B384A;
    padding: 10px 0;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0px;
    z-index: 2001;
  }

  .msg{
    .tips span{
      margin: 0 5px;
      letter-spacing: 2px;
    }
  }

</style>
