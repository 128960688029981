import request from '@/utils/request'

// 人才列表
export function fetchTask (data) {
  return request({
    url: '/personalUser/fetch',
    method: 'POST',
    data
  })
}
// 人才详情
export function getUserInfo (data) {
  return request({
    url: '/personalUser/getInfo',
    method: 'POST',
    data
  })
}
// 个人信息
export function getSelfInfo (data) {
  return request({
    url: '/personalUser/getSelfInfo',
    method: 'POST',
    data
  })
}
export function login (data) {
  return request({
    url: '/login',
    method: 'POST',
    data
  })
}
// 修改密码
export function password (data) {
  return request({
    url: '/personalUser/updatePassword',
    method: 'POST',
    data
  })
}
// 忘记密码
export function forgotPassword (data) {
  return request({
    url: '/personalUser/forgotPassword',
    method: 'POST',
    data
  })
}
// 注册
export function resign (data) {
  return request({
    url: '/personalUser/add',
    method: 'POST',
    data
  })
}
// 获取验证码
export function getVerificationCode (data) {
  return request({
    url: '/personalUser/getVerificationCode',
    method: 'POST',
    data
  })
}
export function getCertificationInformation () {
  return request({
    url: '/personalUser/getCertificationInformation',
    method: 'POST'
  })
}
// 身份认证
export function personalAuthentication (data) {
  return request({
    url: '/personalUser/personalAuthentication',
    method: 'POST',
    data
  })
}

export function getAvator () {
  return request({
    url: '/personalUser/getAvator',
    method: 'POST'
  })
}
export function getAllComment (data) {
  return request({
    url: '/personalUser/getAllComment',
    method: 'POST',
    data
  })
}
export function fetchWorksById (data) {
  return request({
    url: '/works/fetchWorksById',
    method: 'POST',
    data
  })
}
export function deleteWorksById (data) {
  return request({
    url: '/works/delete',
    method: 'POST',
    data
  })
}
export function update (data) {
  return request({
    url: '/works/update',
    method: 'POST',
    data
  })
}
export function updateInfo (data) {
  return request({
    url: '/personalUser/update',
    method: 'POST',
    data
  })
}
