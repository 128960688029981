<template>
  <div class="container" v-loading="loading">
    <div class="content">
      <div class="title">发布任务，<span>招募您所需的人才</span></div>
      <div class="desc">填写任务基本信息，系统将为您匹配合适的人才</div>
      <el-form :model="form" ref="form" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="任务标题" prop="title" required>
          <el-input size="medium" v-model="form.title" placeholder="请输入任务标题"></el-input>
        </el-form-item>
        <el-form-item label="项目类型" prop="demandTypeTaskList" required>
          <el-checkbox-group v-model="form.demandTypeTaskList" style="display: inline-block;">
            <el-checkbox label="1" name="type">数据采集</el-checkbox>
            <el-checkbox label="2" name="type">RPA脚本开发</el-checkbox>
            <el-checkbox label="3" name="type">研究报告</el-checkbox>
            <el-checkbox label="4" name="type">数据分析</el-checkbox>
            <el-checkbox label="5" name="type">NLP</el-checkbox>
            <el-checkbox label="6" name="type">AI</el-checkbox>
          </el-checkbox-group>
          <span style="margin-left: 10px;color: #adadad;">（可多选）</span>
        </el-form-item>
      </el-form>
      <div class="desc">详细选择</div>
      <el-form :model="form" :rules="rules" :inline="true" ref="ruleForm" label-width="140px" class="radioForm">
        <el-form-item label="是否长期项目" prop="isLong" required>
          <el-radio-group v-model="form.isLong">
            <el-radio size="mini" border label="1" name="project">是</el-radio>
            <el-radio size="mini" border label="0" name="project">否</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item label="是否一次性交付" prop="type" required>-->
<!--          <el-radio-group v-model="form.type">-->
<!--            <el-radio size="mini" border label="1" name="project">是</el-radio>-->
<!--            <el-radio size="mini" border label="0" name="project">否</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item label="是否需要发票" prop="isInvoice" required>
          <el-radio-group v-model="form.isInvoice">
            <el-radio size="mini" border label="1" name="project">是</el-radio>
            <el-radio size="mini" border label="0" name="project">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="工作方式" prop="useFullTime" required>
          <el-radio-group v-model="form.useFullTime">
            <el-radio size="mini" border label="0" name="project">远程</el-radio>
            <el-radio size="mini" border label="1" name="project">驻场</el-radio>
          </el-radio-group>
        </el-form-item>
<!--        <el-form-item label="是否平台托管" prop="type" required>-->
<!--          <el-radio-group v-model="form.type">-->
<!--            <el-radio size="mini" border label="1" name="project">是</el-radio>-->
<!--            <el-radio size="mini" border label="0" name="project">否</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
      </el-form>
      <div class="desc">请填写项目周期</div>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <el-form-item label="项目周期" prop="date" required>
          <el-date-picker size="medium" v-model="form.date" type="daterange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy年MM月dd日"></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="desc" style="margin-top: 40px">请填写项目预算(元)</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="overflow: auto">
        <el-form-item style="float: left" label="项目预算" prop="paymentAmount" required>
          <el-input size="medium" style="width: 120px" v-model.number="form.revenueAmount" placeholder="最低预算(元)"></el-input>
        </el-form-item>
        <el-form-item style="float: left; margin-left: -90px" label="" prop="revenueAmount" required>
          <span style="margin-right: 5px"> ~ </span>
          <el-input size="medium" style="width: 120px" v-model.number="form.paymentAmount" placeholder="最高预算(元)"></el-input>
        </el-form-item>
      </el-form>
      <div class="desc">需求文档</div>
      <el-form style="margin-left: 30px">
        <el-form-item label="">
          <el-upload
            class="upload-demo"
            drag
            :action=uploadUrl
            :headers="headerObj"
            accept=".doc,.pdf,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.zip,.7z,.rar"
            :on-success="uploadSuccess"
            :data="{type: 0}"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" v-html="uploadMilDocText"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="desc">需求概述</div>
      <el-input style="margin: 0px 25px; width: calc(100% - 25px)" type="textarea" v-model="form.overview" :rows="2" placeholder="请详细将您的需求写下来..."></el-input>
      <div class="desc">需求详情</div>
      <el-input style="margin: 0px 25px; width: calc(100% - 25px)" type="textarea" v-model="form.details" :rows="5" placeholder="请详细将您的需求写下来..."></el-input>
       <el-checkbox style="margin: 20px 25px; width: calc(100% - 25px)" v-model="checked">我已阅读并同意《52数据项目发布协议》</el-checkbox>
      <div class="btn-group" style="margin: 0px 25px; width: calc(100% - 25px)">
        <el-button type="primary" @click="commit">发布需求</el-button>
        <el-button @click="$router.back()">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {commitTask} from "@/api/task/task";
import {apiBaseURL} from "@/utils/request";
import {getToken} from "@/utils/auth";
export default {
  data () {
    return {
      loading: false,
      form: {
        title: '',
        demandTypeTaskList: [],
        isLong: '0',
        isInvoice: '0',
        useFullTime: '0',
        date: [],
        cycle: '',
        paymentAmount: 100,
        revenueAmount: 1,
        overview: '',
        details: '',
        appendixUrl: ''
      },
      uploadMilDocText: '将文件拖到此处，或<em>点击上传</em>',
      rules: {
        title: [
          {required: true, message: '请输入任务标题', trigger: 'blur'}
        ],
        demandTypeTaskList: [
          {type: 'array', required: true, message: '请至少选择一个类型', trigger: 'change'}
        ],
        isLong: [
          {required: true, message: '请选择是否为长期项目', trigger: 'change'}
        ],
        isInvoice: [
          {required: true, message: '请选择是否需要发票', trigger: 'change'}
        ],
        useFullTime: [
          {required: true, message: '请选择工作方式', trigger: 'change'}
        ],
        date: [
          { required: true, message: '请选择项目周期', trigger: 'blur' }
        ],
        revenueAmount: [
          { required: true, message: '请输入项目预算', trigger: 'blur' },
          { type: 'number', message: '预算必须为数字值' }
        ],
        paymentAmount: [
          { required: true, message: '请输入项目预算', trigger: 'blur' },
          { type: 'number', message: '预算必须为数字值' }
        ]
      },
      checked: false,
      uploadUrl: apiBaseURL + '/file/upload',
      headerObj: { token: getToken() }
    }
  },
  methods: {
    async commit () {
      if (!this.checked) {
        this.$message.warning('请阅读并同意《52数据项目发布协议》')
        return
      }
      if (!(this.form.title && this.form.demandTypeTaskList.length > 0 && this.form.date && this.form.date.length === 2 && this.form.paymentAmount && this.form.revenueAmount)) {
        this.$message.error('必填项不可为空！')
        return
      }
      this.form.cycle = this.form.date[0] + ' - ' + this.form.date[1]
      console.log(this.form)
      this.loading = true
      const res = await commitTask(this.form)
      if (res.code === 0) {
        this.$message.success('任务已提交审核，请等耐心待并留意工作人员与您联系')
        this.$router.back()
      }else if (res.code === 101) {
        this.$message.error(res.msg)
        this.$router.push('/perCenter/perCert')
      } else {
        this.$message.error(res.msg)
      }
      this.loading = false
    },
    uploadSuccess (response, file, fileList) {
      if (response.code === 0) {
        this.$message.success('上传完成')
        this.uploadMilDocText = '上传成功,点击<em>重新选择上传</em>'
        this.form.appendixUrl = '/file/' + response.msg.split('/file/')[1]
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  .content{
    background-color: #fff;
    border-radius: 3px;
    margin: 90px auto 20px;
    width: 80%;
    padding: 20px;
    @media screen and (max-width: 530px) {
      width: 100%;
      min-height: calc(100vh - 70px);
      padding: 10px;
    }
    .title{
      font-size: 24px;
      font-weight: 700;
      margin: 20px 25px 30px;
    }
    .desc{
      font-size: 16px;
      font-weight: 700;
      margin: 20px 25px;
    }
    .el-checkbox{
      margin-right: 20px;
    }

    .radioForm{
      .el-radio{
        width: 80px;
        text-align: center;
        border: none;
        background-color: #f8f8f8;
        &.is-checked{
          border: none;
          background-image: url(../../../assets/images/selectBg.png);
          background-size: cover;
        }
        /deep/.el-radio__label{
          padding-left: 5px;
        }
        /deep/.el-radio__input {
        	display: none;
        }
      }
    }

  }
</style>
