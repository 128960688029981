<template>
  <div class="box" v-loading="sendLoading">
    <div style="background-color: #FFFFFF; padding: 20px; padding-bottom: 0px">
      <div class="title">话题类型</div>
      <el-radio-group v-model="form.typeId" style="margin-bottom: 10px">
        <el-radio v-for="item in topicTypes" size="mini" :label="item.id" name="type" border>{{ item.typeName }}</el-radio>
      </el-radio-group>
      <div class="title">话题标题</div>
      <el-input
        style="border-radius: 0"
        type="textarea"
        :rows="2"
        placeholder="请输入话题标题"
        v-model="form.title">
      </el-input>
    </div>
    <div style="background-color: #FFFFFF; padding: 20px">
      <div class="title">话题内容</div>
      <div id="toolbar-container" style="z-index: 100 !important;"></div>
      <div id="editor-container" style="z-index: 100 !important;" v-loading="postLoading"></div>
    </div>
    <div style="overflow: auto;background-color: #FFFFFF;padding: 20px;text-align: right">
      <el-button type="info" @click="$router.back()">取消</el-button>
      <el-button type="primary" icon="el-icon-s-promotion" @click="postCommunity">发布话题</el-button>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor'
import {apiBaseURL} from "@/utils/request";
import {getToken} from "@/utils/auth";
import {fetchAllTopicType, postTopic} from "@/api/community/community";
var editor = {}
export default {
  data () {
    return {
      topicTypes: [],
      postLoading: false,
      sendLoading: false,
      form: {
        title: '',
        desc: '',
        body: '',
        typeId: 1
      }
    }
  },
  mounted () {
    this.fetchAllTopicType()
    editor = new E('#toolbar-container', '#editor-container')
    editor.config.zIndex = 100
    editor.config.placeholder = '请输入话题内容'
    editor.config.menus = [
      'bold',
      'italic',
      'underline',
      'foreColor',
      'link',
      'image'
    ]
    editor.config.showLinkImg = false
    editor.config.uploadImgServer = apiBaseURL + '/community/upload-img'
    editor.config.uploadImgMaxLength = 1
    editor.config.uploadFileName = 'file'
    editor.config.uploadImgMaxSize = 10 * 1024 * 1024
    editor.config.uploadImgTimeout = 20 * 1000
    editor.config.uploadImgHeaders = {
      token: getToken()
    }
    var _this = this
    editor.config.uploadImgHooks = {
      before: function (xhr) {
        _this.postLoading = true
      },
      success: function (xhr) {
        _this.postLoading = false
      },
      // 图片上传并返回了结果，但图片插入时出错了
      fail: function (xhr, editor, resData) {
        _this.postLoading = false
      },
      // 上传图片出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.postLoading = false
      },
      // 上传图片超时
      timeout: function (xhr) {
        _this.postLoading = false
      }
    }
    editor.config.pasteFilterStyle = false
    editor.config.pasteIgnoreImg = true
    editor.create()
  },
  methods: {
    async fetchAllTopicType () {
      const res = await fetchAllTopicType()
      this.topicTypes = res.data
    },
    async postCommunity () {
      this.sendLoading = true
      var htmlText = editor.txt.html()
      if (!this.form.title) {
        this.$message({
          message: '话题标题不可为空',
          type: 'warning',
          offset: 80
        })
        this.sendLoading = false
        return
      }
      if (!htmlText) {
        this.$message({
          message: '话题内容不可为空',
          type: 'warning',
          offset: 80
        })
        this.sendLoading = false
        return
      }
      var clearText = editor.txt.text()
      if (clearText.length > 200) {
        clearText = clearText.substring(0, 200)
      }
      this.form.desc = clearText
      this.form.body = htmlText
      const res = await postTopic(this.form)
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: 'success',
          offset: 80
        })
        this.form.title = ''
        editor.txt.clear()
        this.sendLoading = false
        this.$router.back()
      } else {
        this.$message({
          message: res.msg,
          type: 'error',
          offset: 80
        })
        this.sendLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
  @media screen and (max-width: 530px) {
    font-size: 16px;
    padding-right: 0;
    vertical-align: top;
  }
}
/deep/.el-radio__input {
  display: none;
}
/deep/.el-radio.is-bordered.is-checked:not(.is-disabled), .el-checkbox.is-bordered.is-checked:not(.is-disabled) {
  background-color: #795FF6;
  @media screen and (max-width: 530px) {
    background-color: transparent;
  }
}
/deep/.el-radio__input.is-checked:not(.is-disabled) + .el-radio__label, .el-checkbox__input.is-checked:not(.is-disabled) + .el-checkbox__label {
  color: #FFF !important;
  @media screen and (max-width: 530px) {
    color: #795FF6 !important;
  }
}
.box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
#toolbar-container {
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
#editor-container {
  height: 800px;
  border: 1px solid lightgray;
}
</style>
<style scoped>
.title {
  font-size: 20px;font-weight: 400;color: #192544;margin-bottom: 20px;
}
</style>
