<template>
  <div class="container" v-loading="loading">
    <div class="form-wrapper">
      <div class="title">个人信息</div>
      <el-button type="text" class="edit" @click="toEdit" v-if="isEdit"><i class="el-icon-edit-outline"></i>编辑</el-button>
      <div class="avater" v-if="!isMobile">
        <el-upload v-if="!isEdit" class="avatar-uploader" :action="uploadAvatorUrl" :headers="head"
          :show-file-list="false" :on-success="uploadSuccess">
          <img v-if="form.headUrl2" :src="form.headUrl2" class="avatar-img">
        </el-upload>
        <img v-if="isEdit && form.headUrl2" :src="form.headUrl2" class="avatar-img">
        <div class="tip" v-show="!isEdit"><span class="required">*</span>图片大小180 * 180像素，支持JPG、PNG等格式，图片需小于2MB</div>
      </div>
      <el-form ref="form" :model="form" label-width="85px">
        <el-form-item label="头像:" v-if="isMobile">
          <div class="avater">
            <el-upload v-if="!isEdit" class="avatar-uploader" :action="uploadAvatorUrl" :headers="head"
              :show-file-list="false" :on-success="uploadSuccess">
              <img v-if="form.headUrl2" :src="form.headUrl2" class="avatar-img">
            </el-upload>
            <img v-if="isEdit && form.headUrl2" :src="form.headUrl2" class="avatar-img">
            <div class="tip" v-show="!isEdit"><span class="required">*</span>图片大小180 * 180像素，图片需小于10MB</div>
          </div>
        </el-form-item>
        <el-form-item label="昵称:">
          <el-input v-model="form.nickname" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="职业:">
          <el-input v-model="form.position" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="工作年限:">
          <el-input v-model="form.workExperience" size="mini" :readonly="isEdit" style="width: 50px"></el-input><span style="color: #606266; margin-left: 10px">年</span>
        </el-form-item>
        <el-form-item label="工作周期:">
          <el-radio-group v-model="form.isFullTime" v-if="!isEdit">
            <el-radio size="mini" :label=wl.value border v-for="wl in workLongArr">{{ wl.label }}</el-radio>
          </el-radio-group>
          <label class="el-checkbox el-checkbox--mini is-bordered" style="padding-right: 20px" v-if="form.isFullTime === 0 && isEdit"><span class="el-checkbox__label">短期</span></label>
          <label class="el-checkbox el-checkbox--mini is-bordered" style="padding-right: 20px" v-if="form.isFullTime === 1 && isEdit"><span class="el-checkbox__label">长期</span></label>
        </el-form-item>
        <el-form-item label="工作倾向:">
          <el-radio-group v-model="form.workWay" v-if="!isEdit">
            <el-radio size="mini" :label=ww.value border v-for="ww in workWayArr">{{ ww.label }}</el-radio>
          </el-radio-group>
          <label class="el-checkbox el-checkbox--mini is-bordered" style="padding-right: 20px" v-if="form.workWay === 0 && isEdit"><span class="el-checkbox__label">远程</span></label>
          <label class="el-checkbox el-checkbox--mini is-bordered" style="padding-right: 20px" v-if="form.workWay === 1 && isEdit"><span class="el-checkbox__label">驻场</span></label>
        </el-form-item>
        <el-form-item label="日薪:">
          <el-input v-model="form.dailySalary" size="mini" :readonly="isEdit" style="width: 60px"></el-input><span style="color: #606266; margin-left: 10px">元</span>
        </el-form-item>
        <el-form-item label="邮箱:">
          <el-input v-model="form.email" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="联系方式:">
          <el-input v-model="form.mobile" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="技术类型:" prop="type">
          <el-checkbox-group v-model="form.demandIdList" style="display: inline-block;margin-right: 20px;" v-if="!isEdit">
            <el-checkbox border size="mini" label="1" name="type">数据采集</el-checkbox>
            <el-checkbox border size="mini" label="2" name="type">RPA脚本开发</el-checkbox>
            <el-checkbox border size="mini" label="3" name="type">研究报告</el-checkbox>
            <el-checkbox border size="mini" label="4" name="type">数据分析</el-checkbox>
            <el-checkbox border size="mini" label="5" name="type">NLP</el-checkbox>
            <el-checkbox border size="mini" label="6" name="type">AI</el-checkbox>
          </el-checkbox-group>
          <div v-else>
            <div v-for="id in form.demandIdList">
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '1'"><span class="el-checkbox__label">数据采集</span></label>
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '2'"><span class="el-checkbox__label">RPA脚本开发</span></label>
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '3'"><span class="el-checkbox__label">研究报告</span></label>
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '4'"><span class="el-checkbox__label">数据分析</span></label>
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '5'"><span class="el-checkbox__label">NLP</span></label>
              <label class="el-checkbox el-checkbox--mini is-bordered" style="float: left; margin-right: 10px;padding-right: 20px; margin-top: 7px" v-if="id === '6'"><span class="el-checkbox__label">AI</span></label>
            </div>
          </div>
<!--          <div class="tips" v-if="canAnswer"><span class="required">*</span>点击<el-button type="text" class="answer" @click="$router.push({path: '/questions', query: {ids: JSON.stringify(form.demandIdList)}})">立即答题</el-button>赢红包</div>-->
          <div class="tips" v-if="form.isAnswerQuestions !== 1 && isEdit && form.demandIdList && form.demandIdList.length > 0"><span class="required">*</span>点击<el-button type="text" class="answer" @click="$router.push({path: '/questions', query: {ids: JSON.stringify(form.demandIdList)}})">立即答题</el-button>赢红包</div>
        </el-form-item>
        <el-form-item label="技能标签:">
          <div class="tags-wrapper" style="margin-left: 0px">
            <div class="tag" v-if="tagArr.length > 0" v-for="(item,index) in tagArr">{{item}}
              <i class="iconfont icon-guanbi1" @click="tagDelete(index)"></i>
            </div>
            <el-input v-model="form.tagName" v-if="tagArr.length < 10" size="mini" :readonly="isEdit" style="width: 80px;"></el-input>
          </div>
          <div class="tips"><span class="required">*</span>按回车Enter创建标签 最多10个</div>
        </el-form-item>
        <el-form-item label="支付宝:">
          <el-input v-model="form.alipayAccount" size="mini" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="个人简介:">
          <el-input type="textarea" v-model="form.describe" :rows="5" :readonly="isEdit"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit" v-if="!isEdit">保存</el-button>
          <el-button size="mini" class="submit" @click="cancel" v-if="!isEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="work-wrapper">
      <div class="title">个人作品</div>
      <el-button size="mini" plain class="uploadWorks" @click="addWorks(null)"><i class="iconfont icon-shangchuan"></i>上传作品</el-button>
      <div class="works" v-for="item in workList">
        <div class="shade">
          <i style="font-size: 26px" class="iconfont icon-chakan" @click="viewFetchWorksById(item.id)"></i>
          <i class="iconfont icon-bianji" @click="addWorks(item.id)"></i>
          <i class="iconfont icon-shanchu" @click="viewDeleteWorksById(item.id)"></i>
        </div>
        <div class="img-wrapper">
          <img :src="item.imageList[0]" >
        </div>
        <div class="workMsg">
          <div class="work-title">{{item.title}}</div>
          <div class="desc" v-if="isMobile">{{ item.content }}</div>
          <div class="tags">{{ item.skills }}</div>
        </div>
      </div>
    </div>
    <work-preview :item="workItem" v-if="showWork" @close="showWork = false"></work-preview>
  </div>
</template>

<script>
  import { _isMobile } from '@/utils/index';
  import WorkPreview from '@/components/workPreview'
  import {apiBaseURL} from "@/utils/request";
  import {getSelfInfo,fetchWorksById,deleteWorksById,updateInfo} from "@/api/personnel";
  import {getToken} from "@/utils/auth";
  export default {
    components:{ WorkPreview },
    data () {
      return {
        loading: false,
        isMobile: false,
        form: {
        },
        showWork: false,
        isEdit: true,
        tagArr: [],
        workList: [],
        workItem: {},
        canAnswer: false,
        workLongArr: [
          { value: 0, label: '短期' },
          { value: 1, label: '长期' }
        ],
        workWayArr: [
          { value: 0, label: '远程' },
          { value: 1, label: '驻场' }
        ],
        uploadAvatorUrl: apiBaseURL + '/company/reportTemplateUploadImages',
        head: { token: getToken() }
      }
    },
    mounted() {
      if (_isMobile()) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      window.addEventListener('keydown',this.keyDown)
      this.getInfo()
    },
    destroyed(){
      window.removeEventListener('keydown',this.keyDown,false)
    },
    methods: {
      toEdit(){
        if(this.isMobile){
          this.$router.push('/error')
        }else{
          this.isEdit = false
        }
      },
      addWorks(id){
        if(this.isMobile){
          this.$router.push('/error')
        }else{
          if(id){
            this.$router.push('/perCenter/editWorks/'+id)
          }else{
            this.$router.push('/perCenter/addWorks')
          }
        }
      },
      async getInfo () {
        this.loading = true
        const res = await getSelfInfo({})
        if (res.code === 0) {
          for (var i = 0; i < res.data.workList.length; i++) {
            for (var j = 0; j < res.data.workList[i].imageList.length; j++) {
              res.data.workList[i].imageList[j] = apiBaseURL + res.data.workList[i].imageList[j]
              res.data.workList[i].skills = res.data.workList[i].skills.replaceAll('、',' / ')
            }
          }
          res.data.headUrl2 = res.data.headUrl ? apiBaseURL + res.data.headUrl : apiBaseURL + '/avator/company.png'
          this.form = res.data
          this.workList = res.data.workList
          this.tagArr = res.data.skill ? res.data.skill.split('、') : []
        }
        this.loading = false
      },async viewFetchWorksById (id) {
        if(this.isMobile){
          this.$router.push('/workDetail/' + id)
        }else{
          const res = await fetchWorksById({id:id})
          this.workItem = res.data
          this.showWork = true
        }
      },async viewDeleteWorksById (id) {
        const res = await deleteWorksById({id:id})
        if (res.code === 0) {
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
        this.getInfo()
      },
      async onSubmit () {
        this.form.skill = ''
        for (var i = 0; i < this.tagArr.length; i++) {
          this.form.skill = (i === 0) ? this.form.skill + this.tagArr[i] : this.form.skill + '、' + this.tagArr[i]
        }
        console.log(this.form)
        const res = await updateInfo(this.form)
        if (res.code === 0) {
          this.$message.success('保存成功')
          this.isEdit = true
          this.getInfo()
        } else {
          this.$message.error(res.msg)
        }
      },
      keyDown(e){
        //如果是回车则执行登录方法
        if(e.keyCode == 13){
          this.tagArr.push(this.form.tagName)
          this.form.tagName = ''
        }
      },
      tagDelete(index) {
        if (this.isEdit) {
          this.$message.warning('未开启编辑模式')
          return
        }
        this.tagArr.splice(index,1)
      },
      uploadSuccess (response, file, fileList) {
        this.form.headUrl2 = apiBaseURL + '/avator/' + response.msg
        this.form.headUrl = '/avator/' + response.msg
      },
      cancel () {
        this.isEdit = true
        this.form.headUrl2 = this.form.headUrl ? apiBaseURL + this.form.headUrl : apiBaseURL + '/avator/company.png'
        this.getInfo()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-form-item__label,/deep/.el-input--mini{
  	@media screen and (max-width: 530px) {
  	  font-size: 16px;
  	  padding-right: 10px;
      vertical-align: top;
  	}
  }
  .container{
    .form-wrapper,.work-wrapper{
      background-color: #fff;
      border-radius: 3px;
      padding: 30px;
      margin-bottom: 20px;
      position: relative;
      .title{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      .edit{
        color: #795FF6;
        position: absolute;
        right: 80px;
        top: 20px;
        @media screen and (max-width: 530px) {
          right: 30px;
        }
        &:hover{
          opacity: .78;
        }
        .el-icon-edit-outline{
          font-size: 20px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .avater{
        width: 150px;
        position: absolute;
        left: 450px;
        text-align: center;
        @media screen and (max-width: 530px) {
          width: 100%;
          position: unset;
          text-align: left;
        }
        img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: inline-block;
          margin: 0 auto 10px;
        }
        .tip{
          font-size: 12px;
          color: #bfbfbf;
          @media screen and (max-width: 530px) {
            width: calc(100% - 110px);
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
          }
          .required{
            font-size: 14px;
            color: #F56C6C;
            margin-right: 4px;
          }
        }
      }
      .uploadWorks{
        position: absolute;
        right: 50px;
        top: 20px;
        .iconfont{
          margin-right: 5px;
        }
      }
      .el-button.is-plain:hover,
      .el-button.is-plain:focus {
          border-color: #DCDFE6;
          color: #606266;
      }
      .tips{
        font-size: 12px;
        color: #bfbfbf;
        display: inline-block;
        .required{
          font-size: 14px;
          color: #F56C6C;
          margin-right: 4px;
        }
        .answer{
          color: #795FF6;
          margin: 0 2px;
          &:hover{
            opacity: .78;
          }
        }
      }
      .el-input.el-input--mini{
        width: 220px;
      }
      .tags-wrapper{
        display: inline-block;
        width: calc(100% - 200px);
        margin-left: 10px;
        @media screen and (max-width: 530px) {
          width: 100%;
        }
      }
      .tag{
        border: 1px solid #DCDFE6;
        color: #606266;
        line-height: 1;
        border-radius: 5px;
        padding: 6px 5px 6px 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        display: inline-block;

        .iconfont{
          font-size: 14px;
          cursor: pointer;
          color: #bec1c5;
        }
      }

      .works{
        position: relative;
        width: 220px;
        height: 200px;
        margin: 10px 15px 0 0;
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 3px 3px rgba(172,172,172,.08),
        0 -3px 3px rgba(172,172,172,.08),
        3px 0 3px rgba(172,172,172,.08),
        -3px 0 3px rgba(172,172,172,.08);
        @media screen and (max-width: 530px) {
          width: 100%;
          height: auto;
          box-shadow: none;
          border-radius: unset;
          border-bottom: 1px solid #dcdcdc;
          padding: 10px 0;
        }
        &:last-child{
          border: none;
        }
        &:hover .shade{
          display: flex;
        }
        .shade{
          display: none;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          background-color: rgba(0,0,0,.5);
          position: absolute;
          top: 0;
          // display: flex;
          justify-content: center;
          align-items: center;
          .iconfont{
            color: #fff;
            font-size: 20px;
            margin: 0 10px;
            cursor: pointer;
          }
          .iconfont:hover{
            text-shadow: 2px 2px 3px rgba(0,0,0,.8);
          }
        }
        .img-wrapper{
          width: 100%;
          height: 130px;
          overflow: hidden;
          border-radius: 10px;
          @media screen and (max-width: 530px) {
            width: 160px;
            height: 120px;
            margin-right: 15px;
            display: inline-block;
            border-radius: unset;
          }
          img{
            width: 100%;
            height: auto;
          }
        }
        .workMsg{
          @media screen and (max-width: 530px) {
            width: calc(100% - 175px);
            max-height: 130px;
            display: inline-block;
            vertical-align: top;
          }
          .desc{
            font-size: 14px;
            line-height: 1.6;
            color: #4d4d4d;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .work-title{
          font-size: 14px;
          font-weight: 700;
          margin: 12px 15px;
          @media screen and (max-width: 530px) {
           margin: 10px 0;
          }
        }
        .tags{
          font-size: 12px;
          color: #adadad;
          margin: 0 15px;
        }
      }
    }
    /deep/.el-radio__input,/deep/.el-checkbox__input {
    	display: none;
    }
  }
</style>
