<template>
  <div class="container">
    <div class="title">修改密码</div>
    <div class="form-wrapper">
      <el-form ref="form" :model="form" :rules="rules" label-width="85px">
        <el-form-item label="原密码:" prop="password">
          <el-input v-model="form.password" size="mini" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPassword">
          <el-input v-model="form.newPassword" size="mini" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirm">
          <el-input v-model="form.confirm" size="mini" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" class="submit" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {password} from '@/api/personnel'
  export default {
    data () {
      var validateNewPass = (rule, value, callback) => {
        if (value !== this.form.newPassword) {
          callback(new Error('两次密码输入不一致!'));
        } else {
          callback()
        }
      }
      return {
        form: {},
        isEdit: true,
        rules: {
          password: [
            { required:true, message: '请输入原始密码', trigger: 'blur' }
          ],
          newPassword: [
            { required:true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, message: '密码长度不低于6位', trigger: 'blur' }
          ],
          confirm: [
            { required:true, message: '请再次填写确认', trigger: 'blur' },
            { validator: validateNewPass, trigger: 'blur' }
          ]
        },
      }
    },
    mounted() {

    },
    methods: {
      async onSubmit(){
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const res = await password(this.form)
            if ( res.code === 0 ) {
              this.$refs['form'].resetFields()
              this.$alert('密码修改成功，请重新登录', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$router.push('/login')
                }
              });
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    position: relative;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .el-input.el-input--mini{
      width: 220px;
    }
  }
</style>
