<template>
  <div class="container" v-loading="loading">
    <div class="wrapper">
      <div class="title">积分兑换</div>
      <div class="jifenNum">
        <i class="iconfont icon-jifen"></i>
        <span>我的积分</span>
        <span>{{jifenNum}}</span>
      </div>
      <div class="gifts">
        <div class="item" style="overflow: auto"  v-for="item in dataList">
          <div style="width: 20%; box-shadow: 0 1px 10px 0 lightgray; float: left">
            <img :src="item.img" >
          </div>
          <div class="name" style="float: left;width: 65%; text-align: left; color: #969696; padding: 0 10px">
            <div style="font-size: 25px; font-weight: 600; margin-left: 20px">{{item.productName}}</div>
            <div style="font-size: 20px; font-weight: 500; margin-top: 20px; margin-left: 20px">{{item.describe}}</div>
            <div style="color: #CFCFCF; padding: 0 20px; overflow: auto; margin-top: 20px; font-size: 18px">
              <div style="float: left">包邮</div>
              <div style="float: right">库存：{{item.stock}}件</div>
            </div>
          </div>
          <div style="width: 15%; float: left; text-align: center;margin-top: 20px">
            <div style="color: #7B62F6; font-size: 20px; font-weight: 500">{{parseInt(item.price)}}<span style="color: #CFCFCF; font-weight: 400; margin-left: 10px">积分</span></div>
            <div style="background-color: #7B62F6; color: #FFFFFF; width: 150px; height: 40px; line-height: 40px; margin: 0 auto; text-align: center; font-size: 17px; border-radius: 40px;margin-top: 20px; cursor: pointer" @click="exchangeGoods(item)">
              立即兑换
            </div>
          </div>
<!--          <div class="duihuan">-->
<!--            <div class="jifen">-->
<!--              <span class="num">{{item.price}}</span>积分-->
<!--              <span class="stock">库存：{{item.stock}}件</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="exchange-btn" @click="exchangeGoods(item)">立即兑换</div>-->
        </div>

      </div>
      <el-pagination  @current-change="handleCurrentChange"  layout="total,prev, pager, next" :total="form.total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
    </div>
  </div>
</template>

<script>
  import WorkPreview from '@/components/workPreview'
  import {selectProductList,getUserIntegral} from '@/api/integral/integral'
  import {apiBaseURL} from "@/utils/request";
  import {getSelfInfo} from "@/api/personnel";
  export default {
    components:{ WorkPreview },
    data () {
      return {
        loading: false,
        dataList:[],
        currentPage:1,
        jifenNum: 0,
        form:{
          total: 0
        }
      }
    },
    mounted() {
      this.viewSelectProductList()
      this.getUserIntegral()
    },
    methods: {
      async viewSelectProductList(){
        this.loading = true
        const res = await selectProductList({ 'currentPage': this.currentPage})
        if (res.code === 0) {
          for (var i=0;i<res.data.length;i++){
            res.data[i].img =apiBaseURL + res.data[i].img
          }
          this.dataList=res.data
          this.form.total=res.count
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      },
      exchangeGoods(item){
        this.loading = true
        if(item.stock == 0){
          this.$message.error('该商品暂无库存，请您选择其他商品进行兑换')
          return;
        }
        this.loading = false
        this.$router.push({path: '/order', query: {goods: JSON.stringify(item),jifen:this.jifenNum}})
      },
      async getUserIntegral(){
        const res = await getUserIntegral()
        if (res.code === 0) {
          this.jifenNum = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      handleCurrentChange (val) {
        this.currentPage = val
        this.fetchTask()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    text-align: center;
  }
  .container{
    .wrapper{
      background-color: #fff;
      border-radius: 3px;
      padding: 30px;
      margin-bottom: 20px;
      position: relative;
      .title{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      .jifenNum{
        position: absolute;
        right: 40px;
        top: 20px;
        span{
          margin-left: 8px;
        }
        .icon-jifen{
          color: #ffb500;
          font-size: 24px;
          vertical-align: middle;
        }
      }
      .gifts{
        margin-bottom: 30px;
        .item{
          //box-shadow: 0 1px 10px 0px lightgrey;
          padding: 15px;
          width: 100%;
          border-radius: 2px;
          display: inline-block;
          margin: 10px 5px;
          border-bottom: 2px solid #F2F2F2;
          padding-bottom: 40px;
          img{
            width: 100%;
            display: block;
            margin: 10px auto;
          }
          .name{
            margin: 20px 0;
          }
          .duihuan{
            color: #8d8d8d;
            .num{
              color: #795ffb;
              font-size: 22px;
            }
            .stock{
              float: right;
              color: #8d8d8d;
              margin-top: 10px;
            }
          }
          .exchange-btn{
            background-color: #795ffb;
            color: #fff;
            width: 80px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            float: right;
            border-radius: 22px;
            margin-top: 15px;
            cursor: pointer;
          }
        }
      }
    }

  }
</style>
