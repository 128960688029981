import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    meta: { name: '首页', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/main'),
        meta: {
          title: '52数据 - 首页'
        }
      },
      {
        path: 'release',
        component: () => import('@/views/task/components/release'),
        meta: {
          type: 'add', requireAuth: true, title: '52数据 - 发布需求'
        }
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/task/components/detail'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 任务详情' }
      }
    ]
  },
  {
    path: '/task',
    component: Layout,
    meta: { name: '任务大厅', requireAuth: true,title: '52数据 - 任务大厅' },
    children: [
      {
        path: '',
        component: () => import('@/views/task'),
        meta: {
          title: '52数据 - 任务大厅',
          requireAuth: true // 标识该路由是否需要登录
        }
      }
      /* {
        path: 'release',
        component: () => import('@/views/task/components/release'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/task/components/detail'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      } */
    ]
  },
  {
    path: '/personnel',
    component: Layout,
    meta: { name: '人才大厅', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/personnel'),
        meta: {
          title: '52数据 - 人才大厅',
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      /* {
        path: '/workDetail:id',
        component: () => import('@/views/personnel/components/workDetail'),
        meta: { name: '作品详情', requireAuth: true }
      }, */
      {
        path: 'detail/:id',
        component: () => import('@/views/personnel/components/detail'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 人才详情' }
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    meta: { name: '订单详情', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/order'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 订单' }
      }
    ]
  },
  {
    path: '/anli',
    component: Layout,
    meta: { name: '成功案例', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/anli'),
        meta: {
          title: '52数据 - 成功案例',
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/anli/components/detail'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 成功案例详情' }
      }
    ]
  },
  /* {
    path: '/data',
    component: Layout,
    meta: { name: '数据圈', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/order'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  }, */
  {
    path: '/workDetail',
    component: Layout,
    meta: { name: '作品详情', requireAuth: true },
    children: [
      {
        path: ':id',
        component: () => import('@/views/personnel/components/workDetail'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 作品详情' }
      }
    ]
  },
  {
    path: '/questions',
    component: Layout,
    meta: { name: '答题', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/questions'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 答题赢积分' }
      }
    ]
  },
  {
    path: '/perCenter',
    component: Layout,
    meta: { name: '个人中心', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/userCenter'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/userCenter/components/comForm'),
            meta: { name: '企业信息', requireAuth: true, title: '52数据 - 企业信息' }
          },
          {
            path: 'comCert',
            component: () => import('@/views/userCenter/components/comCert'),
            meta: { name: '企业认证', requireAuth: true, title: '52数据 - 企业认证' }
          },
          {
            path: 'perForm',
            component: () => import('@/views/userCenter/components/perForm'),
            meta: { name: '个人信息', requireAuth: true, title: '52数据 - 个人信息' }
          },
          {
            path: 'perCert',
            component: () => import('@/views/userCenter/components/perCert'),
            meta: { name: '个人认证', requireAuth: true,title: '52数据 - 个人认证' }
          },
          {
            path: 'resetPass',
            component: () => import('@/views/userCenter/components/resetPass'),
            meta: { name: '修改密码', requireAuth: true,title: '52数据 - 修改密码' }
          },
          {
            path: 'invitation',
            component: () => import('@/views/userCenter/components/invitation'),
            meta: { name: '推广邀请', requireAuth: true,title: '52数据 - 推广邀请' }
          },
          {
            path: 'notification',
            component: () => import('@/views/userCenter/components/notification'),
            meta: { name: '消息通知', requireAuth: true,title: '52数据 - 消息通知' }
          },
          {
            path: 'notification/:id',
            component: () => import('@/views/userCenter/components/notiDetail'),
            meta: { name: '消息详情', requireAuth: true,title: '52数据 - 通知详情' }
          },
          {
            path: 'integral',
            component: () => import('@/views/userCenter/components/integral'),
            meta: { name: '积分兑换', requireAuth: true,title: '52数据 - 积分兑换' }
          },
          {
            path: 'exchange',
            component: () => import('@/views/userCenter/components/exchangeRecord'),
            meta: { name: '兑换记录', requireAuth: true,title: '52数据 - 兑换记录' }
          }
        ]
      },
      {
        path: 'addWorks',
        component: () => import('@/views/userCenter/components/addWorks'),
        meta: { name: '发布作品', type: 'add', requireAuth: true,title: '52数据 - 发布作品' }
      },
      {
        path: 'editWorks/:id',
        component: () => import('@/views/userCenter/components/addWorks'),
        meta: { name: '修改作品', type: 'edit', requireAuth: true,title: '52数据 - 修改作品' }
      }
    ]
  },
  {
    path: '/mission',
    component: Layout,
    meta: { name: '我的任务', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/mission'),
        meta: {
          title: '52数据 - 我的任务',
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  },
  {
    path: '/community',
    component: Layout,
    meta: { name: '交流社区', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/community'),
        meta: {
          requireAuth: true,
          title: '52数据 - 社区大厅'
        }
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/community/detail'),
        meta: {
          requireAuth: true,
          title: '52数据 - 话题详情'
        }
      },
      {
        path: 'post',
        component: () => import('@/views/community/post'),
        meta: {
          requireAuth: true,
          title: '52数据 - 发布话题'
        }
      }
    ]
  },
  {
    path: '/workOrder',
    component: Layout,
    meta: { name: '工单管理', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/workOrder'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/workOrder/components/list'),
            meta: {
              title: '52数据 - 工单列表',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add',
            component: () => import('@/views/workOrder/components/form'),
            meta: { type: 'add', requireAuth: true,title: '52数据 - 创建工单' }
          },
          {
            path: 'detail/:id',
            component: () => import('@/views/workOrder/components/detail'),
            meta: { name: '工单详情', requireAuth: true,title: '52数据 - 工单详情' },
          }
        ]
      }
    ]
  },
  {
    path: '/flowRecord',
    component: Layout,
    meta: { name: '流水记录', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/flowRecord'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/flowRecord/components/capital'),
            meta: { name: '资金记录', requireAuth: true, title: '52数据 - 资金记录' }
          },
          {
            path: 'integral',
            component: () => import('@/views/flowRecord/components/integral'),
            meta: { name: '积分记录', requireAuth: true, title: '52数据 - 积分记录' }
          },
          {
            path: 'withdrawal',
            component: () => import('@/views/flowRecord/components/withdrawal'),
            meta: { name: '提现', requireAuth: true, title: '52数据 - 提现' }
          }
        ]
      }
    ]
  },
  {
    path: '/error',
    component: Layout,
    meta: { name: '页面提示'},
    children: [
      {
        path: '',
        component: () => import('@/views/login/error')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      title: '52数据 - 欢迎登录'
    }
  },
  {
    path: '/register',
    component: () => import('@/views/login/register'),
    meta: {
      title: '52数据 - 注册账号'
    }
  },
  {
    path: '/register/:inviter',
    component: () => import('@/views/login/register'),
    meta: {
      title: '52数据 - 注册账号'
    }
  },
  {
    path: '/forgetPass',
    component: () => import('@/views/login/forgetPass'),
    meta: {
      title: '52数据 - 忘记密码'
    }
  },
  {
    path: '/workBench',
    component: Layout,
    meta: { name: '工作台', requireAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/workBench'),
        meta: {
          title: '52数据 - 工作台',
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'detail/:id',
        component: () => import('@/views/workBench/components/detail'),
        meta: {type: 'detail',requireAuth: true,title: '52数据 - 工作台详情' }
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
