<template>
  <div class="container" v-loading="loading">
    <div class="wrapper">
      <div class="title">消息详情</div>
      <div class="notice">
        <div class="msg">{{data.title}}</div>
        <div class="date">{{data.createDate}}</div>
      </div>
      <div class="content" v-html="data.msg"></div>
    </div>
  </div>
</template>

<script>
import {getDetails} from "@/api/notification/notification";
  export default {
    data () {
      return {
        loading: false,
        data:{}
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList(){
        const res = await getDetails({id: this.$route.params.id})
        if (res.code === 0) {
          this.data = res.data
        } else {
          this.$message.error(res.msg)
        }
      }

    }
  }
</script>

<style lang="scss" scoped>
  .container{
    .wrapper{
      background-color: #fff;
      border-radius: 3px;
      padding: 30px;
      margin-bottom: 20px;
      position: relative;
      @media screen and (max-width: 530px) {
        min-height: calc(100vh - 120px);
      }
      .title{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
      }

      .notice{
        padding:10px 0;
        font-size: 14px;
        color: #3d3d3d;
        border-bottom: 1px solid #EDEDED;
        cursor: pointer;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        .msg{
          font-size: 16px;
        }
        .date{
          margin: 10px 0;
          color: #8d8d8d;
          font-size: 14px;
        }

      }
      .content{
        margin: 25px 0;
        color: #6d6d6d;
        font-size: 14px;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }
</style>
