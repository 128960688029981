<template>
  <div class="container" v-loading="loading">
    <div class="title">工单详情</div>
    <div class="question-msg">
      <div class="msg">
        <el-row>
          <el-col :span="8">
            <span>工单编号：{{this.data.id}}</span>
          </el-col>
          <el-col :span="8">
            <span v-if="this.data.state==0">工单状态：未处理</span>
            <span v-if="this.data.state==1">工单状态：处理中</span>
            <span v-if="this.data.state==2">工单状态：已完成</span>
          </el-col>
          <el-col :span="8">
            <span>创建时间：{{this.data.createDate}}</span>
          </el-col>
        </el-row>

      </div>
      <div class="desc">概述：{{this.data.summary}}</div>
      <div class="desc">问题描述：{{this.data.description}}</div>
    </div>
    <div class="dialogue" v-if="list.length > 0">
      <div class="item" v-for="item in list">
        <div class="kefu" v-if="item.type==1">
          <div class="avater">
            <img src="../../../assets/images/kefu.png" >
          </div>
          <div class="duihua">
            <div class="msg">
              <span class="name">52客服</span>
              <span class="sendDate">{{item.sendDate}}</span>
            </div>
            <div class="liuyan" style="background-color: #FBFDFF; border: 1px solid #F0F2F5; color: #99A2B1; font-weight: 400">
              {{item.msg}}
            </div>
          </div>

        </div>
        <div class="user" v-else>
          <div class="duihua">
            <div class="msg">
              <span class="name">{{data.userName}}</span>
              <span class="sendDate">{{item.sendDate}}</span>
            </div>
            <div class="liuyan" style="background-color: #FBFDFF; border: 1px solid #F0F2F5; color: #99A2B1; font-weight: 400">
              {{item.msg}}
            </div>
          </div>
          <div class="avater">
            <img :src="avator" >
          </div>
        </div>
      </div>
    </div>
    <div class="reply" v-if="this.data.state !=2">
      <el-input type="textarea" v-model="replyMsg" :rows="6" placeholder="回复:"></el-input>
      <el-button type="primary" size="small" style="margin: 10px 0;float:right;width: 80px;" @click="sendMsg">发送</el-button>
    </div>
  </div>
</template>

<script>
import {commit,fetchWorksById,reply} from "@/api/workorder/workorder";
import {getAvator} from "../../../api/personnel";
import {apiBaseURL} from "../../../utils/request";
export default {
  data () {
    return {
      loading: false,
      list: [],
      type: 1,
      id:'',
      data:{},
      replyMsg: '',
      avator: ''
    }
  },
  mounted() {
    this.id =this.$route.params.id
    this.viewFetchWorksById()
  },
  methods: {
    async viewFetchWorksById () {
      this.loading = true
      const res =await fetchWorksById({id:this.id});
      this.data =res.data
      this.list=res.data.replyList
      this.getAvator()
      this.loading = false
    },
    async sendMsg(){
      this.loading = true
      const res = await reply({workOrderId:this.id,msg:this.replyMsg});
      if(res.code == 0){
        this.replyMsg = ""
        this.viewFetchWorksById()
      }else{
        this.$message.error(res.msg)
        this.loading = false
      }
    },
    async getAvator () {
      const res = await getAvator()
      this.avator = res.msg ? apiBaseURL + res.msg : apiBaseURL + '/avator/company.png'
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  border-radius: 3px;
  padding: 30px;
  overflow: auto;
  .title{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .question-msg{
    font-size: 16px;
    line-height: 1.6;
    margin: 20px 0;
    color: #6A788D;
    border: 1px solid #DDDFE6;
    border-radius: 8px;
    padding: 15px;
    .msg{
      margin: 10px 0;
      span{
        margin-right: 40px;
      }
    }
    .desc{
      margin: 10px 0;
    }
  }
  .dialogue{
    margin: 20px 0;
    border: 1px solid #DDDFE6;
    border-radius: 8px;
    padding:20px;
    .item{
      margin: 20px 0;
    }
  }
  .duihua{
    display: inline-block;
    text-align: left;
    max-width: 80%;
    .msg{
      color: #6d6d6d;
      margin-bottom: 8px;
      .sendDate{
        margin-left: 8px;
      }
    }
    .liuyan{
      width: -webkit-fit-content;
      min-height: 38px;
      position: relative;
      font-size: 14px;
      padding: 10px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #cdcdcd;
    }
  }

  .kefu{
    .avater{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .liuyan{
      border-top-left-radius: 0;
    }
  }
  .user{
    text-align: right;
    .avater{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-left: 15px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .duihua .msg{
      text-align: right;
    }
    .liuyan{
      float: right;
      border-top-right-radius: 0;
    }
  }
}
</style>
