<template>
  <div class="container" v-loading="loading">
    <div class="renzheng-wrapper" v-if="!disabled">
      <div class="title">个人认证</div>
      <div class="form-wrapper">
        <el-form ref="form" :model="form" label-width="85px">
          <el-form-item label="姓名:">
            <el-input v-model="form.name" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="身份证号:">
            <el-input v-model="form.idCard" size="mini"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="explain">
        <div class="title">认证说明</div>

        <div class="explain-content">
          <div class="">尊敬的52用户:</div>
          <p>您好！欢迎您使用52平台！</p>
          <p>基于保障用户资金安全的原则，52平台需要对涉及资金往来的用户进行身份认证。为使您更好地使用52平台，请您认真阅读并遵守《52平台身份认证说明》（以下简称“本说明”）。</p>
          <p>用户应保证其提供给52平台的所有资料和信息的真实性、合法性、准确性和有效性；否则，52平台有权终止和取消用户通过52平台获取的服务和酬金。因用户提供的资料偏差给52平台或第三方
          造成损害的，该用户应依法承担相应的责任。</p>
          <p>保护用户隐私信息是52平台的一项基本原则，52平台会采取合理有效的措施确保用户信息的安全性，请您放心！除法律法规规定的情形外，未经用户许可52平台绝不会向任何第三方泄漏用户的资料和信息。</p>
          <p>用户理解并同意：授权52平台对其提交的资料和信息进行甄别核实；52平台基于法律法规对已授权的用户提供的资料和信息进行身份审核和认证。
          感谢您的配合，祝您顺利通过认证！</p>
          <div style="margin: 30px 0;">52团队</div>

        </div>
        <el-checkbox v-model="checked">请确认您已仔细阅读上述认证说明并同意遵守<el-link type="primary" :underline="false" style="color:#a068ff;">《身份认证授权与承诺书》</el-link></el-checkbox>
        <el-button type="primary" size="mini" class="submit" @click="onSubmit" :disabled="disabled">提交</el-button>
      </div>
    </div>
    <div class="userMsg" v-else>
      <div class="title">实名认证</div>
      <div class="tips">验证通过后无法自行修改。如果需要修改认证信息请<el-button type="text" class="service" @click="">联系客服</el-button></div>
      <div class="msg">
        <div>姓名：{{form.name}}</div>
        <div>证件类型：身份证</div>
        <div>身份证号：{{form.idCard}}</div>
      </div>
    </div>
    <div class="dialog" v-if="showDialog"  @click=" showDialog = false">
      <div class="content">
        <i class="iconfont icon-guanbi2fill" @click=" showDialog = false"></i>
        <div class="img-wrapper"><img src="../../../assets/images/failed.png" alt=""></div>
        <div class="state">认证失败</div>
        <div style="font-size: 14px;">姓名或身份证号输入错误，请重新核对</div>
        <div style="color: #8d8d8d;">您可以关闭窗口重新认证</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCertificationInformation,personalAuthentication} from "@/api/personnel";
import form from "../../workOrder/components/form";
  export default {
    data () {
      return {
        loading: false,
        form: {},
        disabled: false,
        checked: true,
        showDialog: false
      }
    },
    mounted() {
      this.getCertificationInformation()
    },
    methods: {
      onSubmit(){
        this.viewpersonalAuthentication()
      },
      // 字符串脱敏
     /* hideCode(str, frontLen, endLen) {
          var len = str.length - frontLen - endLen;
          var xing = '';
          for (var i = 0; i < len; i++) {
              xing += '*';
          }
          return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
      }, */
      async viewpersonalAuthentication(){
        const res = await personalAuthentication({ 'name': this.form.name, 'idCardNumber': this.form.idCard })
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getCertificationInformation()
        } else {
          // this.$message.error(res.msg)
          this.showDialog = true
        }
      },
      async getCertificationInformation () {
        this.loading = true
        const res = await getCertificationInformation()
        if (res.code === 0) {
          this.form = res.data
          if (res.data.idCard) {
            this.disabled = true
          }
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-form-item__label,/deep/.el-input--mini{
  	@media screen and (max-width: 530px) {
  	  font-size: 16px;
  	  padding-right: 10px;
      vertical-align: top;
  	}
  }
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    min-height: 320px;
    @media screen and (max-width: 530px) {
      min-height: calc(100vh - 120px);
    }
    .dialog{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: rgba(0,0,0,.3);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .content{
        width: 22%;
        min-width: 240px;
        background-color: #fff;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        div{
          margin: 15px 0;
        }
        .img-wrapper{
          width: 80px;
          margin: 30px auto 0;
          img{
            width: 100%;
            margin-left: 6px;
          }
        }
        .iconfont{
          color: #c7c7c7;
          font-size: 20px;
          float: right;
          cursor: pointer;
        }
        .state{
          font-size: 16px;
          margin: 20px 0;
        }
      }
    }
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .el-input.el-input--mini{
      width: 220px;
    }
    .explain-content{
      line-height: 1.6;
      font-size: 14px;
      /* p{
        text-indent: 28px;
      } */
    }
    /deep/.el-checkbox .el-checkbox__input.is-checked:not(.is-disabled) + .el-checkbox__label{
      color: #606266 !important;
    }
    /deep/.el-checkbox__input.is-focus .el-checkbox__inner,
    /deep/.el-checkbox__inner:hover{
      border-color: #795FF6;
    }

    .submit{
      display: block;
      width: 80px;
      margin: 30px auto 0;
    }
    .userMsg{
      .tips{
        color: #8d8d8d;
        font-size: 14px;
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        .service{
          color: #a068ff;
          font-size: 14px;
          @media screen and (max-width: 530px) {
            font-size: 16px;
          }
        }
      }
      .msg{
        margin-top: 30px;
        div{
          font-size: 14px;
          margin: 10px 0;
          @media screen and (max-width: 530px) {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
