<template>
  <div class="container">
    <div class="title">工单列表</div>
    <div class="form-wrapper">
      <el-form ref="form" :inline="true" :model="form" label-width="80px">
        <el-form-item label="工单编号:">
          <el-input style="width: 200px;" v-model="form.id" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="选择时间:">
          <el-date-picker size="mini" v-model="form.date" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
<!--        <el-form-item label="工单类型:">-->
<!--          <el-radio-group v-model="form.resource">-->
<!--            <el-radio size="mini" label="全部" border></el-radio>-->
<!--            <el-radio size="mini" label="提现" border></el-radio>-->
<!--            <el-radio size="mini" label="任务" border></el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button size="mini" type="primary" class="submit" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column prop="id" label="工单编号" width="200"></el-table-column>
      <el-table-column prop="createDate" label="时间" width="170" align="center"></el-table-column>
      <el-table-column prop="summary" label="问题概述" align="center"></el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.state === 0" >未处理</span>
          <span v-if="scope.row.state === 1" >处理中</span>
          <span v-if="scope.row.state === 2" >已完成</span>
        </template>
      </el-table-column>
      <el-table-column label="工单详情" width="100" align="center">
        <template scope="{ row }">
          <span class="operate-btn" @click="checkDetail(row.id)">查看</span>
          <!-- <span v-else-if="row.status == 1" >支付</span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" layout="total,prev, pager, next" :total="this.form.total" :current-page.sync="this.form.current" prev-text="上一页" next-text="下一页"></el-pagination>
  </div>
</template>

<script>
import {fetchList} from "@/api/workorder/workorder";
  export default {
    data () {
      return {
        loading: false,
        form: {
          total: 0,
          current: 1
        },
        tableData:[{
          id: '123456',
          date: '2022/2/15',
          desc: '需要一位爬虫工程师帮我采集一些数据',
          status: '待审核'
        }]
      }
    },
    mounted() {
      this.fetchList()
    },
    methods: {
      checkDetail(id){
        this.$router.push("workOrder/detail/"+id)
      },
      async fetchList () {
        this.loading = true
        const res = await fetchList(this.form)
        if (res.code === 0) {
          this.tableData = res.data
          this.form.total = res.count
        }
        this.loading = false
      },
      async search () {
        // 分页信息初始化
        this.fetchList()
      },
      handleCurrentChange (val) {
        this.form.current = val
        this.fetchList()
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-pager li.active,/deep/.el-pager li:hover,
  /deep/.el-pagination button:hover{
    color: #a068ff;
  }
  /deep/.el-pagination .btn-prev,
  /deep/.el-pagination .btn-next,
  /deep/.el-pager li{
    background-color: transparent;
    color: #6d6d6d;
  }
  /deep/.el-pagination{
    margin-top: 20px;
    text-align: center;
  }
  .container{
    background-color: #fff;
    border-radius: 3px;
    padding: 30px;
    .title{
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
  .operate-btn{
    color:#795FF6;
    background-color: #f9f8ff;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
  }
</style>
