<template>
  <div class="container" v-loading="loading">
    <div class="content">
      <div class="title">上传作品</div>
      <el-form :model="form" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="作品名称" prop="title">
          <el-input size="medium" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="作品描述" prop="content">
          <el-input type="textarea" v-model="form.content" :rows="5"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <div class="item-title">
        <span class="required">*</span>
        <span class="label">上传图片</span>
        <span class="tips">注：默认第一张图片为封面图片，最多上传5张图片</span>
      </div>
      <div class="images" v-if="!isEdit">
        <el-upload :action="uploadURL" :on-success="uploadSuccess" :on-remove="deleteFile" :data="{ type: 1 }"
                   :auto-upload="true" :headers="head" list-type="picture-card" :limit="5" :on-exceed="exceed">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="images" v-else>
        <el-upload :action="uploadURL" :on-success="editUploadSuccess" :on-remove="editRemoveSuccess" :data="{ type: 1 }" :file-list="fileList"
                   :auto-upload="true" :headers="head" list-type="picture-card" :limit="5" :on-exceed="exceed">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
    </div>
    <div class="content">
      <div class="item-title">
        <span class="required">*</span>
        <span class="label">技能标签</span>
        <span class="tips">按回车Enter创建标签 最多10个</span>
      </div>
      <div class="tags">
        <div class="tag" v-if="tagArr.length > 0" v-for="(item,index) in tagArr">{{ item }}
          <i class="iconfont icon-guanbi1" @click="tagDelete(index)"></i>
        </div>
        <el-input v-if="tagArr.length < 10" size="mini" v-model="form.tagName" style="width: 80px;"></el-input>
      </div>
      <div class="btns-group">
        <el-button v-if="!isEdit" size="mini" type="primary" @click="addWork">发布作品</el-button>
        <el-button v-if="isEdit" size="mini" type="primary" @click="saveUpdate">保存修改</el-button>
        <el-button size="mini" @click="$router.back()">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {apiBaseURL} from "@/utils/request";
import {getToken} from "@/utils/auth";
import {addWork} from "@/api/works/works";
import {fetchWorksById,update} from "@/api/personnel";

export default {
  data() {
    return {
      loading: false,
      uploadURL: apiBaseURL + '/file/upload',
      head: {token: getToken()},
      form: {
        tagName: '',
        imageList: [],
        skills: ''
      },
      tagArr: [],
      upShow: true,
      isEdit: false,
      fileList: [],
      rules: {
        title: [
          { required:true, message: '请输入作品名称', trigger: 'blur' }
        ],
        content: [
          { required:true, message: '请输入作品描述', trigger: 'blur' }
        ]
      },
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    if (this.$route.meta.type === 'edit') {
      console.log('编辑')
      this.isEdit = true
      this.viewFetchWorksById()
    } else {
      console.log('新建')
      this.isEdit = false
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    exceed(files, fileList) {
      this.$message.error('最多上传5张图片')
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.tagArr.push(this.form.tagName)
        this.form.tagName = ''
      }
    },
    tagDelete(index) {
      this.tagArr.splice(index, 1)
    },
    uploadSuccess(response, file, fileList) {
      this.form.imageList = []
      for (var i = 0; i < fileList.length; i++) {
        this.form.imageList.push(fileList[i].response.msg)
      }
      console.log(this.form.imageList)
    },
    deleteFile(file, fileList) {
      this.form.imageList = []
      for (var i = 0; i < fileList.length; i++) {
        this.form.imageList.push(fileList[i].response.msg)
      }
      console.log(this.form.imageList)
    },
    async addWork() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if(this.tagArr.length == 0){
            this.$message.error('请至少输入一个技能标签')
            return
          }
          this.loading = true
          this.form.skills = ''
          for (var i = 0; i < this.tagArr.length; i++) {
            this.form.skills = (i === 0) ? this.form.skills + this.tagArr[i] : this.form.skills + '、' + this.tagArr[i]
          }
          const res = await addWork(this.form)
          if (res.code === 0) {
            this.$message.success('添加成功')
            this.$router.back()
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false;
        }
      })

      this.loading = false
    },
    async viewFetchWorksById () {
      this.loading = true
      this.fileList = []
      const res = await fetchWorksById({ id: this.$route.params.id })
      this.form = res.data
      for (var i = 0; i < this.form.imageList.length; i++) {
        this.fileList.push({ url: apiBaseURL + this.form.imageList[i], name: this.form.imageList[i].split('out/')[1] })
      }
      console.log(this.fileList)
      this.tagArr = this.form.skills.split('、')
      this.loading = false
    },
    editUploadSuccess (response, file, fileList) {
      this.form.imageList = []
      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          this.form.imageList.push(fileList[i].response.msg)
        } else {
          this.form.imageList.push('/file/out/' + fileList[i].url.split('/file/out/')[1])
        }
      }
    },
    editRemoveSuccess (file, fileList) {
      this.form.imageList = []
      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          this.form.imageList.push(fileList[i].response.msg)
        } else {
          this.form.imageList.push('/file/out/' + fileList[i].url.split('/file/out/')[1])
        }
      }
    },
    async saveUpdate () {
      this.loading = true
      this.form.skills = ''
      for (var i = 0; i < this.tagArr.length; i++) {
        this.form.skills = (i === 0) ? this.form.skills + this.tagArr[i] : this.form.skills + '、' + this.tagArr[i]
      }
      console.log(this.form)
      const res = await update(this.form)
      if (res.code === 0) {
        this.$message.success('保存成功')
        this.viewFetchWorksById()
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  border-radius: 3px;
  margin: 20px auto;
  width: 80%;
  padding: 20px;
  padding-right: 40px;
  @media screen and (max-width: 530px) {
    width: 100%;
  }
  &:first-child {
    margin: 90px auto 20px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    margin: 20px 25px 30px;
  }

  .desc {
    font-size: 16px;
    font-weight: 700;
    margin: 20px 25px;
  }

  .images {
    padding-left: 25px;

    /deep/ .el-upload--picture-card {
      border: 1px solid #DCDFE6;
      border-radius: 3px;

    }

    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      border-radius: 3px;
    }
  }

  .item-title {
    padding-left: 20px;

    .required {
      font-size: 14px;
      color: #F56C6C;
      margin-right: 4px;
    }

    .label {
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      font-weight: 700;
    }

    .tips {
      color: #8d8d8d;
      margin-left: 30px;
      @media screen and (max-width: 530px) {
        width: 100%;
        display: block;
        margin: 0 0 18px;
      }
    }
  }

  .tags {
    padding-left: 28px;
    margin: 10px 0;

    .tag {
      border: 1px solid #DCDFE6;
      color: #606266;
      border-radius: 5px;
      padding: 6px 5px 6px 10px;
      margin-right: 10px;
      margin-bottom: 15px;
      display: inline-block;

      .iconfont {
        font-size: 14px;
        cursor: pointer;
        color: #bec1c5;
      }
    }
  }

  .btns-group {
    text-align: center;
    margin: 30px 0 20px;
  }
}
</style>
