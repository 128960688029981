<template>
  <div class="box">
    <div class="banner" style="width: 100vw;overflow-x: hidden">
      <img src="../../assets/images/bannerBg.png" style="width: 100%;height: 420px">
      <div class="wrapper">
        <div class="title">可以搜索你感兴趣的内容</div>
        <div>
          <el-input @change="changeSearch" v-model="form.keyword" style="width: 350px;height: 35px;border-radius: 35px;border: 0;padding-left: 20px;font-size: 14px;margin-left: -20px" placeholder="输入关键词进行搜索" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="type-select">
        <el-radio-group @change="changeSearch" v-model="form.typeId">
          <el-radio size="mini" :label="0" name="type" border>全部</el-radio>
          <el-radio v-for="item in topicTypes" size="mini" :label="item.id" name="type" border>{{ item.typeName }}</el-radio>
        </el-radio-group>
      </div>
      <div v-if="isLogin" style="text-align: right;margin-top: -20px;font-size: 14px; color: gray">
        <el-badge :hidden="!unReadFlag" is-dot class="item">
          <span @click="showUnReadDialog" style="cursor: pointer"><i class="el-icon-message" style="margin-right: 3px"></i>消息中心</span>
        </el-badge>
        <span @click="showMineSent" v-if="!form.mine" style="margin-left: 15px;cursor: pointer"><i class="el-icon-files" style="margin-right: 3px"></i>我的发布</span>
        <span @click="hideMineSent" v-if="form.mine" style="margin-left: 15px;cursor: pointer"><i class="el-icon-discover" style="margin-right: 3px"></i>返回大厅</span>
      </div>
      <div v-loading="loading">
        <div style="margin-top: 30px;overflow: hidden">
          <el-select @change="changeSearch" v-model="form.sort" placeholder="请选择" style="float: left;width: 100px" size="small">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button style="float: right" type="primary" size="small" icon="el-icon-s-promotion" @click="post()">发布话题</el-button>
        </div>
        <div v-if="dataList.length === 0" style="font-size: 20px; color: lightgray;text-align: center;padding: 50px 0">未查询到相关话题</div>
        <div class="" style="padding-top: 20px" v-if="dataList.length > 0">
          <div class="community-item" v-for="item in dataList">
            <div class="title" @click="!form.mine || item.isPass === 1 ? openNewTab('/#/community/detail/' + item.id) : $message.warning('该话题暂未通过审核')">
              <el-tag v-if="form.mine && item.isPass === 0" color="darkgray" style="color: #FFF;margin-right: 5px" size="small">待审核</el-tag>
              <el-tag v-if="form.mine && item.isPass === 2" color="#F56C6C" style="color: #FFF;margin-right: 5px" size="small">审核未通过</el-tag>
              <i style="color: #F8C44F" v-if="item.good" class="el-icon-s-opportunity"></i>{{ item.title }}
            </div>
            <span style="font-size: 15px;float: right;margin-top: -22px;color: #8e97ae"><i class="el-icon-chat-line-round"></i>{{ item.commentNum }}</span>
            <div class="desc" @click="!form.mine || item.isPass === 1 ? openNewTab('/#/community/detail/' + item.id) : $message.warning('该话题暂未通过审核')">
              {{ item.desc }}
            </div>
            <div class="imgBox" @click="!form.mine || item.isPass === 1 ? openNewTab('/#/community/detail/' + item.id) : $message.warning('该话题暂未通过审核')">
              <div v-for="image in item.images" style="width: 150px;height: 90px;float:left;margin-right:10px;overflow: hidden">
                <img style="width: 100%" :src="image">
              </div>
            </div>
            <div style="margin-top: 10px">
              <el-tag v-if="item.typeTag" :color="item.tagColor" style="color: #FFF" size="small">{{ item.typeTag }}</el-tag>
              <span style="font-size: 12px;float: right;color: #adb3c0">{{ item.createDate }}</span>
            </div>
            <div v-if="form.mine" style="color: rgb(142, 151, 174);font-size: 15px;text-align: right;margin-top: 10px">
              <span style="cursor:pointer;" @click="showDeleteDialog(item.id)"><i class="el-icon-delete" style="margin-right: 3px"></i>删除</span>
            </div>
          </div>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" style="margin: 20px 0;padding-bottom: 20px" layout="total,prev, pager, next" :total="total" :current-page.sync="form.currentPage" prev-text="上一页" next-text="下一页"></el-pagination>
      <el-dialog
        title="删除确认"
        :visible.sync="deleteDialog"
        width="30%">
        <span>您确定要删除该话题吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmDeleteTopic">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="消息中心"
      :visible.sync="dialogVisible"
      v-loading="messageLoading"
      width="40%"
      >
      <div>
        <div style="text-align: right;padding-right: 10px;color: #795FF6">
          <span style="cursor: pointer" @click="updateAllMessageToRead">全部已读</span>
        </div>
        <div @click="showMessageTopic(item.topicId, item.id)" style="padding: 10px;border-bottom: 1px solid lightgray;cursor: pointer" v-for="item in messageList">
          <div style="font-size: 16px;color: #192544;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
            <div v-if="item.isRead === 0" style="width: 5px;height: 5px;border-radius: 5px;background-color: #F56C6C;float: left;margin-top: 5px;margin-right: 5px"></div>
            话题：{{ item.title }}
          </div>
          <div v-if="item.isComment === 0" style="font-size: 14px;margin-top: 10px">{{ item.userName }} 回复 您：{{ item.sendContent }}</div>
          <div v-if="item.isComment === 1" style="font-size: 14px;margin-top: 10px">{{ item.userName }} 评论了您的话题</div>
          <div style="text-align: right">点击查看详情</div>
        </div>
        <el-pagination @current-change="handleMessageCurrentChange" style="margin: 20px 0;padding-bottom: 0px;margin-bottom: 0px" layout="total,prev, pager, next" :total="messageTotal" :current-page.sync="messageCurrentPage" :page-size="5" prev-text="上一页" next-text="下一页"></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";
import {fetchAllTopicType, fetchTopic, getCountOfUnread, fetchMessage, updateToReadById, updateAllToRead, deleteTopic} from "@/api/community/community";
export default {
  data () {
    return {
      loading: false,
      topicTypes: [],
      isLogin: false,
      dialogVisible: false,
      deleteDialog: false,
      options: [{
        value: '0',
        label: '综合排序'
      }, {
        value: '1',
        label: '时间排序'
      }, {
        value: '2',
        label: '热度排序'
      }],
      form: {
        sort: '0',
        typeId: 0,
        keyword: '',
        currentPage: 1,
        mine: false
      },
      total: 20,
      dataList: [],
      unReadFlag: false,
      messageList: [],
      messageCurrentPage: 1,
      messageTotal: 0,
      messageLoading: false,
      deleteTopicId: ''
    }
  },
  methods: {
    openNewTab (url) {
      window.open(url)
    },
    post () {
      if (getToken()) {
        this.$router.push('/community/post')
      } else {
        this.$router.push('/login')
      }
    },
    async fetchAllTopicType () {
      const res = await fetchAllTopicType()
      this.topicTypes = res.data
    },
    async fetchTopic () {
      this.loading = true
      const res = await fetchTopic(this.form)
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].descImages) {
          res.data[i].images = res.data[i].descImages.split('|')
        }
        if (res.data[i].isBoutique === 1) {
          res.data[i].good = true
        }
      }
      this.dataList = res.data
      this.total = res.count
      this.loading = false
    },
    async changeSearch () {
      this.form.currentPage = 1
      this.fetchTopic()
    },
    handleCurrentChange (val) {
      this.form.currentPage = val
      this.fetchTopic()
    },
    showMineSent () {
      this.form.mine = true
      this.form.sort = '1'
      this.changeSearch()
    },
    hideMineSent () {
      this.form.mine = false
      this.form.sort = '0'
      this.changeSearch()
    },
    async getCountOfUnread () {
      const res = await getCountOfUnread()
      if (res.data > 0) {
        this.unReadFlag = true
      } else {
        this.unReadFlag = false
      }
    },
    async showUnReadDialog () {
      this.dialogVisible = true
      this.messageLoading = true
      const res = await fetchMessage({ currentPage: this.messageCurrentPage, pageSize: 5 })
      this.messageList = res.data
      this.messageTotal = res.count
      this.messageLoading = false
    },
    async handleMessageCurrentChange (val) {
      this.messageCurrentPage = val
      this.showUnReadDialog()
    },
    async showMessageTopic (topicId, id) {
      await updateToReadById({ id: id })
      this.showUnReadDialog()
      this.getCountOfUnread()
      window.open('/#/community/detail/' + topicId)
    },
    async updateAllMessageToRead () {
      await updateAllToRead()
      this.showUnReadDialog()
      this.getCountOfUnread()
    },
    showDeleteDialog (id) {
      this.deleteTopicId = id
      console.log(id)
      this.deleteDialog = true
    },
    async confirmDeleteTopic () {
      this.deleteDialog = false
      this.loading = true
      const res = await deleteTopic({ id: this.deleteTopicId })
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: 'success',
          offset: 80
        })
        this.fetchTopic()
      } else {
        this.$message({
          message: res.msg,
          type: 'error',
          offset: 80
        })
        this.loading = false
      }
    }
  },
  mounted () {
    if (getToken()) {
      this.isLogin = true
      this.getCountOfUnread()
      var _this = this
      setInterval(function () {
        _this.getCountOfUnread()
      }, 1000 * 60)
    }
    this.fetchAllTopicType()
    this.fetchTopic()
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-pager li.active,/deep/.el-pager li:hover,
/deep/.el-pagination button:hover{
  color: #a068ff;
}
/deep/.el-pagination .btn-prev,
/deep/.el-pagination .btn-next,
/deep/.el-pager li{
  background-color: transparent;
  color: #6d6d6d;
}
/deep/.el-pagination{
  text-align: right;
}
/deep/.el-form-item__content,/deep/.el-form--inline .el-form-item__content{
  @media screen and (max-width: 530px) {
    line-height: unset;
    vertical-align: bottom;
  }
}
/deep/.el-radio--mini.is-bordered .el-radio__label,/deep/.el-form-item__label,/deep/.el-input--mini{
  @media screen and (max-width: 530px) {
    font-size: 16px;
    padding-right: 0;
    vertical-align: top;
  }
}
/deep/.el-radio__input {
  display: none;
}
/deep/.el-radio.is-bordered.is-checked:not(.is-disabled), .el-checkbox.is-bordered.is-checked:not(.is-disabled) {
  background-color: #795FF6;
  @media screen and (max-width: 530px) {
    background-color: transparent;
  }
}
/deep/.el-radio__input.is-checked:not(.is-disabled) + .el-radio__label, .el-checkbox__input.is-checked:not(.is-disabled) + .el-checkbox__label {
  color: #FFF !important;
  @media screen and (max-width: 530px) {
    color: #795FF6 !important;
  }
}
/deep/.el-tag{
  border-color: transparent;
}
.banner {
  @media screen and (max-width: 530px) {
    display: none
  }
}
.box {
  background-color: #FFF !important;
  width: 100%;
  overflow-x: hidden;
  @media screen and (max-width: 530px) {
    margin-top: 80px;
  }
}
.content {
  @media screen and (max-width: 530px) {
    padding-top: 20px;
  }
}
.content {
  width: 1000px;
  margin: 20px auto;
  @media screen and (max-width: 530px) {
    width: 90%;
  }
}
</style>
<style scoped>

.wrapper {
  position: absolute;
  top: 250px;
  left: calc((100vw - 418px) / 2);
  margin-top: -100px;
  color: #fff;
  text-align: center;
}
.wrapper .title {
  font-size: 36px;
  letter-spacing: 2px;
  margin-bottom: 50px;
}
.wrapper input:focus-visible {
  outline-color: #1f2d3d;
  outline-width: 0px;
}
.type-select {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px;
  padding-top: 10px;
  overflow: auto;
  line-height: 40px;
}
.type-select .type-item {
  background: #eaeaea;
  padding: 10px 20px;
  color: #18293A;
  border-radius: 100px;
  margin-right: 10px;
}
.type-select .actived {
  background: #7B62F6;
  padding: 10px 20px;
  color: #FFFFFF;
  border-radius: 100px;
}
.community-item {
  padding: 25px 0 25px 0;
  border-bottom: 1px solid #EBEBEB;
  cursor: pointer;
}
.community-item .title {
  font-size: 20px;
  font-weight: 400;
  color: #192544;
  max-width: 90%;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.community-item .desc {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #adb3c0;
  max-width: 90%;
  line-height: 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.community-item .imgBox {
  margin-top: 10px;
  width: 100%;
  overflow: auto;
}
</style>
