

const state = {
  navList: [],
  navMap: [],
  project: {},
  urlPath: ''
}

const mutations = {
  INIT_NAV_LIST: (state, payload) => {
    state.navList = payload
  },
  SET_NAV_LIST: (state, payload) => {
    state.navList = payload
  },
  SET_NAV_MAP: (state, payload) => {
    state.navMap = payload
  },
  changeProject (state, value) {
    state.project = value
  },
  changeUrl (state, value) {
    state.urlPath = value
  }
}

const actions = {
  // user login
  async initNavList ({ commit }) {
    try {
      
    } catch (err) {
      console.log(err)
    }
  },
  setNavList ({ commit }, payload) {
    commit('SET_NAV_LIST', payload)
  },
  setNavMap ({ commit }, payload) {
    commit('SET_NAV_MAP', payload)
  },
  setProject (context, value) {
    context.commit('changeProject', value)
  },
  setUrl (context, value) {
    context.commit('changeUrl', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

export function listToTree (list) {
  var map = {}
  var node
  var tree = []
  var i
  for (i = 0; i < list.length; i++) {
    list[i].children = []
    map[list[i].id] = list[i]
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.pid !== '0') {
      map[node.pid].children.push(node)
    } else {
      tree.push(node)
    }
  }
  return [tree, map]
}
