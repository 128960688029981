<template>
  <div class="login-wrap" >
    <div class="content">
      <div class="login-box">
        <div class="title">重置密码</div>
        <div class="login-form">
<!--          <el-input v-model="form.username" placeholder="请输入账号"/>-->
          <el-input v-model="form.mobile" placeholder="请输入手机号"/>
          <div class="code">
            <el-input v-model="form.verificationCode" placeholder="请输入手机验证码"/>
            <el-button type="text" class="getcode" :disabled="getcode" @click="getCaptcha">{{btnTitle}}</el-button>
          </div>
          <el-input v-model="form.newPassword" placeholder="请输入新密码"/>
          <el-button class="submit" type="primary" @click="onSubmit">提&nbsp;&nbsp;交</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {forgotPassword,getVerificationCode} from "@/api/personnel";
import {setToken} from "@/utils/auth";
export default {
  data () {
    return ({
      getcode: false,
      btnTitle:'获取验证码',
      form:{
        username: '',
        newPassword: '',
        verificationCode: '',
        mobile:''
      },
      loginType: 0
    })
  },
  mounted () {
  },
  destroyed () {

  },
  methods: {
    async onSubmit(){
      console.log(this.form)
      // if(this.form.username == ''){
      //   this.$message.error('请输入账号名称')
      //   return
      // }
      if(this.form.mobile == ''){
        this.$message.error('请输入手机号')
        return
      }
      if(this.form.verificationCode == ''){
        this.$message.error('请输入验证码')
        return
      }
      if(this.form.newPassword == ''){
        this.$message.error('请输入密码')
        return
      }
      var res = await forgotPassword(this.form)
      if ( res.code === 0 ) {
        this.form={}
        this.$alert('密码设置成功，去登录', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push('/login')
          }
        });
      } else {
        this.$message.error(res.msg)
      }
    },
    async getCaptcha(){
      if(this.form.mobile == ''){
        this.$message.error('请输入手机号')
        return
      }
      this.getcode = true
      var num = 59
      this.btnTitle= '重新获取('+num+')'
      var index = setInterval(()=>{
        num--
        this.btnTitle= '重新获取('+num+')'
        if(num == 1){
          clearInterval(index)
          this.getcode = false
          this.btnTitle = '获取验证码'
        }
      },1000)
      var res = await getVerificationCode('mobile='+this.form.mobile)
      if(res.code !== 0){
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/login-bg.png") no-repeat;
  background-size: cover;
  .content {
    width: 485px;
    // height: 400px;
    padding-bottom: 60px;
    background: #fff;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 530px) {
      width: 80%;
    }
    .title {
      text-align: center;
      font-weight: bolder;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      height: 40px;
      line-height: 40px;
      background-color: #795FF6;
      color: #fff;
      margin-bottom: 40px;
    }
    .login-box{
      width: 100%;
      margin: 0 auto;
      .login-form{
        width: 340px;
        margin: 0 auto;
        @media screen and (max-width: 530px) {
          width: 80%;
        }
      }
      .code{
        position: relative;
        .getcode{
          position: absolute;
          right: 0;
          border-left: 1px solid #dcdcdc;
          width: 100px;
        }
        .el-button--text{
          color: #5d5d5d;
        }
        .el-button--text.is-disabled{
          color: #C0C4CC;
        }
      }
      .el-input {
        // margin: 0 auto;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        .el-input__inner {
          height: 50px;
          line-height: 50px;
        }
      }
      .submit {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
</style>
